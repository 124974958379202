import { createEvent, createStore } from 'effector';

export const $error = createStore('');
export const setError = createEvent<string>();
export const setSuccess = createEvent<string>();
export const $success = createStore('');

$success.on(setSuccess, (_, success) => success);

$error.on(setError, (_, err) => err);
