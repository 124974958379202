import { Statistic, Row, Col } from 'antd';

import styles from './AnalyticsTodayStatistics.module.less';

export const AnalyticsTodayStatistics = () => (
  <article className={styles.AnalyticsTodayStatistics}>
    <Row>
      <Col span={4}>
        <p className={styles.AnalyticsTodayStatisticsToday}>
          Сегодня,
        </p>
        <p className={styles.AnalyticsTodayStatisticsDate}>
          16 января
        </p>
      </Col>
      <Col span={4}>
        <Statistic
          title="выручка"
          prefix="₽"
          value={1560780.65}
          precision={2}
        />
      </Col>
      <Col span={4}>
        <Statistic
          title="прибыль"
          prefix="₽"
          value={1137320.72}
          precision={2}
        />
      </Col>
      <Col span={4}>
        <Statistic
          title="заказы"
          value={10527}
          precision={2}
        />
      </Col>
      <Col span={4}>
        <Statistic
          title="новых клиентов"
          value={2527}
          precision={2}
        />
      </Col>
      <Col span={4}>
        <Statistic
          title="средний чек"
          prefix="₽"
          value={5560.65}
          precision={2}
        />
      </Col>
    </Row>
  </article>
);