import { createEffect, createEvent, sample } from 'effector';
import { Task } from 'src/pages/orders';
import { tasksApi } from 'src/shared/api';
import { FormData } from './types';
import { DetailedTask } from 'src/pages/task/types';
import { setError } from 'src/features/notifications';
import { AxiosError } from 'axios';
import { possibleTransferStatuses } from './config';

export const submitCode = createEvent<FormData>();

const goToPageFx = createEffect((id: number | string) => {
  if (id) {
    window.location.assign(`/task/${id}`);
  } else {
    setError('Задание не найдено');
  }
});
export const transTaskFx = createEffect<tasksApi.TransTaskReqData, Task, AxiosError<{ message: string }>>(
  tasksApi.transferTask,
);
export const getTaskByCodeFx = createEffect<tasksApi.TaskByCodeReqData, DetailedTask, AxiosError<{ message: string }>>(
  tasksApi.getTaskByCode,
);

sample({
  clock: submitCode,
  target: getTaskByCodeFx,
});

sample({
  clock: getTaskByCodeFx.doneData,
  filter: (data: DetailedTask) => possibleTransferStatuses.includes(data?.task?.status),
  fn: data => ({ id: data?.task?.id, data: { product_count: 1 } }),
  target: transTaskFx,
});

sample({
  clock: getTaskByCodeFx.doneData,
  filter: (data: DetailedTask) => !possibleTransferStatuses.includes(data?.task?.status),
  fn: data => data.task.id,
  target: goToPageFx,
});

sample({
  clock: transTaskFx.doneData,
  fn: (data: Task) => data.id,
  target: goToPageFx,
});

sample({
  clock: [getTaskByCodeFx.failData, transTaskFx.failData],
  fn: data => data.response?.status === 404
      ? 'Нет задания с таким кодом'
      : data.response?.data?.message || 'Ошибка сканирования',
  target: setError,
});
