import React from 'react';
import { Space, Button, Form, Select } from 'antd';
import { MainModal } from 'src/shared/ui';
import styles from './styles.module.less';
import { LegalEntityDetailed } from '../../types';
import { submitIsInternalData } from '../../model';
import { IIsInternalRequest } from 'shared/api/queries/legalEntitiesApi';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  legalEntity: LegalEntityDetailed | null;
}

export const EditInternalModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  legalEntity,
}) => {
  const [form] = Form.useForm();

  const onSubmit = (value: IIsInternalRequest['data']) => {
    if (legalEntity?.id) {
      submitIsInternalData({
        id: legalEntity?.id,
        data: value
      });
      onClose();
    }
  }

  return (
    <MainModal isOpen={isOpen} closeHandler={onClose}>
      <div className={styles.container}>
        <h2 className={styles.title}>Тип Юридического лица</h2>
        <Form
          onFinish={onSubmit}
          name='basic'
          layout='vertical'
          form={form}
          initialValues={{
            ...legalEntity
          }}
        >
          <Form.Item
            name='is_internal'
            label='Тип ЮЛ'
          >
            <Select
              placeholder='Тип ЮЛ'
              options={[
                {
                  value: true,
                  label: 'Внутреннее',
                },
                {
                  value: false,
                  label: 'Внешнее',
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType='submit' className={styles.button} type='primary' autoFocus>
                Сохранить
              </Button>
              <Button className={styles.button} onClick={onClose}>
                Отмена
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </MainModal>
  )
}
