import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { ESupplierStatus, Supplier } from '../../types';
import styles from './NameProfilesCard.module.less';
import { Icons } from 'src/img/icons';

interface INameProfilesCardProps {
  data?: Supplier;
  show?: boolean;
}

export const NameProfilesCard = ({ data, show = true }: INameProfilesCardProps) => (
  <div className={styles.NameProfilesCard}>
    <Avatar shape="circle" size="large" icon={<PictureOutlined />} src={data?.avatar} />
    <p className={styles.NameProfilesCardName}>
      {data!.name}
      {data?.is_registered && <Icons.StatusActive />}
    </p>
  </div>
);
