import { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import { useStore } from 'effector-react';
import { Hooks } from 'src/shared/lib/hooks';
import { initSocket } from 'src/shared/api/model';
import InfiniteScroll from 'react-infinite-scroll-component';

import { $currentChatId, $oldMessages, getMessagesByScrollEvent, getOldMessagesFx } from './model';
import { Message } from './ui';
import styles from './ChatMessages.module.less';
import { PAGE_SIZE } from './config';

export const ChatMessages = () => {
  const chatId = useStore($currentChatId);
  const messageHistory = useStore($oldMessages);
  const isLoading = useStore(getOldMessagesFx.pending);

  const oldMessages = messageHistory?.items;

  const isMore = messageHistory?.pagination?.current_page
    ? messageHistory.pagination.current_page + 1 <= messageHistory?.pagination?.last_page
    : false;

  useEffect(() => {
    initSocket();
  }, []);

  Hooks.useEchoNewMessage({ chatId });

  const handleScrollLoadData = () => {
    if (!isLoading && isMore && messageHistory) {
      getMessagesByScrollEvent({
        chatId,
        data: {
          pagination: {
            per_page: PAGE_SIZE,
            page: messageHistory.pagination.current_page + 1,
          },
        },
      });
    }
  };

  return (
    <div className={styles.chatMessages}>
      <Card>
        <div className={styles.panel} />
        {isLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
          <div className={styles.wrapper}>
            {chatId ? (
              <div id="scrollableDiv" className={styles.contentWrapper}>
                <InfiniteScroll
                  dataLength={oldMessages?.length || 0}
                  next={handleScrollLoadData}
                  inverse
                  hasMore={isMore}
                  loader={false}
                  scrollableTarget="scrollableDiv">
                  {oldMessages?.map((el, idx) => (
                    <Message
                      key={el.id}
                      message={el}
                      prevMessage={oldMessages[idx - 1]}
                      nextMessage={oldMessages[idx + 1]}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              <div className={styles.noMessagesWrapper}>
                <ArrowLeftOutlined className={styles.noMessageIcon} />
                <span className={styles.noMessageTitle}>Выберите чат</span>
                <p className={styles.noMessageText}>
                  Для просмотра истории чата
                  <br /> и информации о собеседниках
                </p>
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};
