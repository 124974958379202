import { createEffect, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { vkTokensApi } from 'src/shared/api';

import { ITokens, IRedirectLink } from './Tokens';

export const $tokens = createStore<ITokens>({} as ITokens);
export const $redirectLink = createStore<IRedirectLink>({} as IRedirectLink);

export const getTokensFx = createEffect(vkTokensApi.getTokens);
export const addTokenFx = createEffect(vkTokensApi.addToken);
export const removeTokenFx = createEffect(vkTokensApi.removeToken);
export const getTokensLinkFx = createEffect(vkTokensApi.getTokensLink);

$tokens.on(getTokensFx.doneData, (_, data) => data);

$redirectLink.on(getTokensLinkFx.doneData, (_, data) => data);

sample({
  clock: [addTokenFx.doneData, removeTokenFx.doneData],
  source: {},
  target: getTokensFx,
});

getTokensFx.failData.watch(data => {
  setError(data.message);
});

addTokenFx.failData.watch(data => {
  setError(data.message);
});

removeTokenFx.failData.watch(data => {
  setError(data.message);
});

getTokensLinkFx.failData.watch(data => {
  setError(data.message);
});

addTokenFx.doneData.watch(() => {
  setSuccess('Токен успешно добавлен');
});

removeTokenFx.doneData.watch(() => {
  setSuccess('Токен успешно удален');
});
