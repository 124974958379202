import { useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export const useCheckActivity = (initial: string) => {
  const [value, setValue] = useState(initial);

  useEffect(() => {
    setValue(initial);
  }, [initial]);

  return {
    value,
    onChange: (e: CheckboxChangeEvent) => setValue(e.target.checked ? 'Активный' : 'Неактивный'),
  };
};
