import { useState } from 'react';
import { Form } from 'antd';
import MaskedInput from 'react-text-mask';
import { phoneMask } from 'src/pages/legalEntityCreation/config';
import { getNumbers } from 'src/shared/lib';
import styles from './PhoneInput.module.less';

interface PhoneInputProps {
  valStatus?: 'error' | undefined;
  valHelp?: string[] | undefined;
  initialValue?: string;
  label?: string;
}

export const PhoneInput = ({ valStatus, valHelp, initialValue, label = 'Телефон' }: PhoneInputProps) => {
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [isStartsWithError, setIsStartsWithError] = useState(false);
  const [isMinLengthError, setIsMinLengthError] = useState(false);

  return (
    <Form.Item
      initialValue={initialValue}
      validateStatus={valStatus}
      help={valHelp && valHelp.join(', ')}
      rules={[
        { required: true, message: '' },
        {
          validator(_, value: string) {
            if (value.length) {
              setIsEmptyError(false);
              return Promise.resolve();
            }
            setIsEmptyError(true);
            return Promise.reject();
          },
          message: 'Пожалуйста, введите телефон!',
        },
        {
          validator(_, value: string) {
            const regex = /(?=.*^7|\+7)/;
            if (regex.test(value)) {
              setIsStartsWithError(false);
              return Promise.resolve();
            }
            setIsStartsWithError(true);
            return Promise.reject();
          },
          message: 'Телефон должен начинаться с +7!',
        },
        {
          validator(_, value: string) {
            const phoneNumbers = value ? getNumbers(value) : '';
            if (phoneNumbers.length < 11) {
              setIsMinLengthError(true);
              return Promise.reject();
            }
            setIsMinLengthError(false);
            return Promise.resolve();
          },
          message: 'Длина телефона должна составлять не менее 11 символов',
        },
      ]}
      label={label}
      name="phone">
      <MaskedInput
        className={`${styles.input} ${(isEmptyError || isStartsWithError || isMinLengthError) && styles.inputError}`}
        mask={phoneMask}
      />
    </Form.Item>
  );
};
