import { Steps } from 'antd';

import styles from './AddProductSteps.module.less';

const { Step } = Steps;

interface IAddProductStepsProps {
  step: number;
}

export const AddProductSteps = ({ step }: IAddProductStepsProps) => (
  <article className={styles.AddProductSteps}>
    <Steps progressDot current={step} direction="vertical">
      <Step key="info" title="Информация о товаре" />
      <Step key="characteristics" title="Характеристики" />
      <Step key="options" title="Опции" />
    </Steps>
  </article>
);