import { useEffect, useState } from 'react';
import { Button, Space, DatePicker, Form } from 'antd';
import { Moment } from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnTitle } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { defaultDatePickerState, defaultDatePickerFormat, afterDatePickerState } from 'src/shared/config';
import styles from './useColumn.module.less';

function useColumnSearchDateTime<DataType>(
  searchHandler: (value: { date_after: string; date_to: string }, fieldType: string) => void,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  fieldType?: string,
) {
  const [form] = Form.useForm();
  const [dateAfterValue, setDateAfterValue] = useState('');
  const [dateAfterMomentValue, setDateAfterMomentValue] = useState<Moment | null>(null);
  const [dateToValue, setDateToValue] = useState('');
  const [dateToMomentValue, setDateToMomentValue] = useState<Moment | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setDateAfterValue('');
      setDateToValue('');
      setIsActive(false);
      setIsActiveFilter(false);
      setDateAfterMomentValue(null);
      setDateToMomentValue(null);
      form.resetFields();
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const handleSearch = () => {
    searchHandler({ date_after: dateAfterValue, date_to: dateToValue }, fieldType || '');
    setIsActive(true);
  };

  const handleReset = (clearFilters: () => void) => {
    searchHandler({ date_after: '', date_to: '' }, fieldType || '');
    setDateAfterValue('');
    setDateToValue('');
    clearFilters();
    setIsActive(false);
    setDateAfterMomentValue(null);
    setDateToMomentValue(null);
    form.resetFields();
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ clearFilters }) => (
      <div className={styles.useColumnDate}>
        <Form form={form} layout="horizontal" onFinish={handleSearch} autoComplete="off">
          <div className={styles.item}>
            <p>От</p>
            <Form.Item
              name="date_after"
              dependencies={['date_to']}
              rules={[
                ({ getFieldValue }) => ({
                  message: '',
                  validator(_, value) {
                    const dateAfter = dayjs(value.format(defaultDatePickerFormat));
                    const dateTo = dayjs(
                      getFieldValue('date_to') ? getFieldValue('date_to').format(defaultDatePickerFormat) : 0,
                    );
                    const datesDiff = dateAfter.diff(dateTo);
                    if (datesDiff <= 0 || !getFieldValue('date_to')) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}>
              <DatePicker
                format={defaultDatePickerFormat}
                onChange={(momentValue, value) => {
                  setDateAfterMomentValue(momentValue);
                  setDateAfterValue(value);
                }}
                showTime={{ defaultValue: defaultDatePickerState }}
                value={dateAfterMomentValue}
                locale={locale}
              />
            </Form.Item>
          </div>
          <div className={styles.item}>
            <p>До</p>
            <Form.Item
              name="date_to"
              dependencies={['date_after']}
              rules={[
                ({ getFieldValue }) => ({
                  message: '',
                  validator(_, value) {
                    const dateAfter = dayjs(
                      getFieldValue('date_after') ? getFieldValue('date_after').format(defaultDatePickerFormat) : 0,
                    );
                    const dateTo = dayjs(value.format(defaultDatePickerFormat));
                    const datesDiff = dateTo.diff(dateAfter);
                    if (datesDiff >= 0 || !getFieldValue('date_after')) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}>
              <DatePicker
                format={defaultDatePickerFormat}
                onChange={(momentValue, value) => {
                  setDateToMomentValue(momentValue);
                  setDateToValue(value);
                }}
                showTime={{ defaultValue: afterDatePickerState }}
                value={dateToMomentValue}
                locale={locale}
              />
            </Form.Item>
          </div>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} size="small">
              Поиск
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
              Сбросить
            </Button>
          </Space>
        </Form>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnSearchDateTime };
