export const getUserFieldName = (data: string) => {
  switch (data) {
    case 'name':
      return 'имя';
    case 'phone':
      return 'телефон';
    case 'role':
      return 'роль';
    default:
      return data;
  }
};

export const getConfirmText = (changedFields: string[]) => {
  const fieldsString = [...changedFields, 'пароль'].map(field => getUserFieldName(field)).join(', ');

  if (changedFields.length) {
    const newTitle = `Были внесены изменения в поля ${fieldsString}. Вы уверены, что хотите сохранить изменения?`;
    return newTitle;
  }

  const newTitle = `Были внесены изменения в поле ${fieldsString}. Вы уверены, что хотите сохранить изменения?`;
  return newTitle;
};
