import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { setError, setSuccess } from 'src/features/notifications';
import { addSourceFx } from './ui/suppParser/ui/addSource/model';
import { updateSourceFx } from 'src/entities/updateSource/model';
import { suppliersApi, legalEntitiesApi } from '../../../shared/api';
import { SupplierProfile, OptionType } from './types';
import { LegalEntity } from '../legalEntities/types';
import { Paginated } from 'src/shared/types';
import { disabledLegalOption, emptyLegalOption } from '../suppliers/config';
import { archiveErrorStatus, PER_PAGE_LEGAL } from 'src/shared/config';

export const $supplier = createStore<SupplierProfile | null>(null);
export const $suppliersId = createStore<string>('');
export const $isOpenModal = createStore(false);
export const $legalEntitiesOptions = createStore<OptionType[]>([]);
export const $isPrevPath = createStore(false);
export const $isArchiveError = createStore(false);

export const handleModal = createEvent<boolean>();
export const reloadSupplier = createEvent();
export const initSuppliersProfilePage = createEvent<string>();
export const submitChangeStatusForm = createEvent<suppliersApi.IUpdateSupplierStatusRequest>();
export const submitEditSupplier = createEvent<suppliersApi.EditSupplierParams>();
export const initSuppEditModal = createEvent<legalEntitiesApi.LegalEntitiesReqData>();
export const setIsPrevPath = createEvent<boolean>();
export const resetArchiveError = createEvent();

export const getSupplierFx = createEffect(suppliersApi.getSupplier);
export const editSupplierFx = createEffect<
  suppliersApi.EditSupplierParams,
  SupplierProfile,
  AxiosError<{ message: string }>
>(suppliersApi.editSupplier);

const successMessageFx = createEffect(() => {
  setSuccess('Данные успешно изменены');
});

export const changeStatusFx = createEffect<
  suppliersApi.IUpdateSupplierStatusRequest,
  SupplierProfile,
  AxiosError<{ message: string }>
>(suppliersApi.updateSupplierStatus);

export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);

$legalEntitiesOptions.on(getLegalEntitiesFx.doneData, (_, data: Paginated<LegalEntity>) => {
  const legalEntitiesOptions =
    data?.items
      .map(({ id, full_name: name }) => ({ key: id, label: name, value: name }))
      .slice(0, PER_PAGE_LEGAL - 1) || [];
  const intermedLegalOptions =
    data?.items?.length > PER_PAGE_LEGAL - 1 ? [...legalEntitiesOptions, disabledLegalOption] : legalEntitiesOptions;
  const resultLegalOptions = intermedLegalOptions?.length ? intermedLegalOptions : [emptyLegalOption];

  return resultLegalOptions;
});

$isPrevPath.on(setIsPrevPath, (_, data) => data);
$isArchiveError.reset(resetArchiveError);

sample({
  clock: initSuppliersProfilePage,
  target: getSupplierFx,
});

sample({
  clock: [addSourceFx.doneData, updateSourceFx.doneData, changeStatusFx.doneData, reloadSupplier],
  source: $suppliersId,
  target: getSupplierFx,
});

sample({
  clock: submitEditSupplier,
  target: editSupplierFx,
});

sample({
  clock: initSuppEditModal,
  target: getLegalEntitiesFx,
});

sample({
  clock: editSupplierFx.doneData,
  fn: data => data.id,
  target: [getSupplierFx, successMessageFx],
});

sample({
  source: editSupplierFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка',
  target: setError,
});

sample({
  clock: submitChangeStatusForm,
  target: changeStatusFx,
});

sample({
  source: changeStatusFx.failData,
  filter: data => data.response?.status !== archiveErrorStatus,
  fn: data => data.response?.data?.message || 'Ошибка при изменении статуса',
  target: setError,
});

sample({
  clock: changeStatusFx.failData,
  filter: data => data.response?.status === archiveErrorStatus,
  fn: () => true,
  target: $isArchiveError,
});

sample({
  clock: changeStatusFx.doneData,
  fn: () => 'Статус успешно изменен',
  target: setSuccess,
});

$supplier.on(getSupplierFx.doneData, (_, data) => ({
  ...data,
  pass_date: data.pass_date ? dayjs(data.pass_date).format('YYYY-MM-DD') : null,
  registration_date: data.registration_date ? dayjs(data.registration_date).format('YYYY-MM-DD') : null,
}));
$isOpenModal.on(handleModal, (_, data) => data);
$isOpenModal.on(editSupplierFx.done, () => false);
$suppliersId.on(initSuppliersProfilePage, (_, data) => data);

export const useSupplier = () => useStore($supplier);
