import { Pavilion } from '../suppliersProfile/ui/suppParser/SuppParser';

export interface Supplier {
  id: number;
  avatar: string;
  name: string;
  email: string;
  phone: string;
  category: string;
  link: string;
  pavilions: Pavilion[];
  sources?: SuppliersSource[];
  status?: ESupplierStatus;
  full_name?: string;
  disabled?: boolean;
  legalEntity: {
    id: number;
    full_name: string;
  };
  is_own_legal_entity: boolean;
  is_registered: boolean;
}

export interface SuppliersSource {
  id: number | string;
  name: string;
  url: string;
  supplier_id?: number;
}

export enum ESupplierStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
}

export enum ESupplierStatusName {
  ACTIVE = 'Активен',
  BANNED = 'Забанен',
  UNDER_CONSIDERATION = 'На рассмотрении',
  NOT_CONFIRMED = 'Не подтверждено',
  AWAITING_CONFIRMATION = 'Ожидает подтверждения',

}

