import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import {
  $orders,
  $ordersStatusOptions,
  $requestData,
  initPage,
  initLatestOrdersPage,
  submitChangeRequestData,
  getOrdersFx,
  $visibleColumns,
  changeVisibleColumns,
} from './model';
import { ordersApi } from 'src/shared/api';
import { Table, Spin, Tooltip, Empty } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import type { TableRowSelection } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { TableSettings } from 'src/entities/tableSettings';
import { Hooks } from 'src/shared/lib';
import { getFinalFilterPrice } from 'src/shared/lib';
import { Order } from './types';
import { NumberTypeEnum } from 'src/shared/types';
import { initState, PER_PAGE, columns, mainColumnName } from './config';
import styles from './LatestOrders.module.less';

interface LatestOrdersProps {
  id?: string;
}

export const LatestOrders = ({ id }: LatestOrdersProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const orders = useStore($orders);
  const statusOptions = useStore($ordersStatusOptions);
  const requestData = useStore($requestData);
  const isLoading = useStore(getOrdersFx.pending);

  useEffect(() => {
    if (id) {
      initLatestOrdersPage({ client_id: id, ...initState });
    }
    initPage();
  }, [id]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Order> = [];

    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: Order[]) => {};

  const handleSelect = (record: Order, selected: boolean, selectedRows: Order[]) => {};

  const handleSelectAll = (selected: boolean, selectedRows: Order[], changeRows: Order[]) => {};

  const rowSelection: TableRowSelection<Order> = {
    onChange: handleSelectChange,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  const searchFieldHandler = (value: string, type?: string) => {
    if (value && type) {
      const resultValue = type === 'price' ? getFinalFilterPrice(value) : value;
      submitChangeRequestData({ ...requestData, [type]: resultValue, client_id: id, ...initState });
    } else {
      const filteredReqData = Object.entries(requestData).reduce((acc, [key, value]) => {
        if (key !== type) {
          acc[key as keyof typeof requestData] = value;
        }
        return acc;
      }, {} as ordersApi.OrdersRequestData);
      submitChangeRequestData({ ...filteredReqData, client_id: id, ...initState });
    }
  };

  const handleSelectStatus = (value: string[]) => {
    submitChangeRequestData({ ...requestData, client_id: id, statuses: value || [], ...initState });
  };

  const handleCreateDateSearch = (obj: { [key: string]: string }) => {
    submitChangeRequestData({ ...requestData, client_id: id, ...obj, ...initState });
  };

  const searchCreatedDateHandler = (value: string) => {
    if (value) {
      handleCreateDateSearch?.({
        created_at: new Date(value).toISOString(),
      });
    } else {
      const { created_at: createdAt, ...filteredReqData } = requestData;
      submitChangeRequestData({ ...filteredReqData, client_id: id, ...initState });
    }
  };

  const handleChangePage = (value: number, size: number) => {
    submitChangeRequestData({ ...requestData, client_id: id, pagination: { page: value, per_page: size } });
  };

  const handleResetFilters = () => {
    submitChangeRequestData({ client_id: id, ...initState });
    setIsActiveFilter(true);
  };

  const getPriceSearch = Hooks.useColumnSearch<Order>(
    searchFieldHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
  );
  const getDateSearch = Hooks.useColumnSearch<Order>(
    searchCreatedDateHandler,
    'date',
    isActiveFilter,
    setIsActiveFilter,
  );
  const getStatusSearch = Hooks.useColumnMultiSelect<Order>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
  );

  const filteredColumns: ColumnsType<Order> = visibleColumns.map(column => {
    switch (column.key) {
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  return (
    <div className={styles.LatestOrders}>
      {isLoading ? (
        <div className={styles.center}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.options}>
            <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
              <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
            </Tooltip>
            <TableSettings
              disabledColumns={[mainColumnName]}
              columns={columns}
              visibleColumnsKeys={visibleColumnsKeys}
              setVisibleColumnsKeys={changeVisibleColumns}
            />
          </div>
          <Table
            className={styles.orders}
            rowKey={record => record.id}
            bordered
            columns={filteredColumns}
            rowSelection={{ ...rowSelection }}
            dataSource={orders?.items || []}
            pagination={{
              onChange: handleChangePage,
              current: orders?.pagination?.current_page || 1,
              pageSize: orders?.pagination?.per_page || PER_PAGE,
              total: orders?.pagination?.total || 0,
              showSizeChanger: true,
            }}
            scroll={{ x: 1200 }}
            locale={{ emptyText: <Empty description="Нет данных" /> }}
          />
        </div>
      )}
    </div>
  );
};
