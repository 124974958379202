import { Utils } from 'src/shared/lib';
import { Post } from 'src/pages/orders/order/types';
import styles from './PostInfo.module.less';

interface PostInfoProps {
  post: Post;
}

export const PostInfo = ({ post }: PostInfoProps) => (
  <div className={styles.postInfo}>
    <div className={styles.info}>
      <p className={styles.text}>{post?.post_text || '-'}</p>
      <div>
        <p>Выгружено {post?.created_at ? Utils.getFormatDate(post.created_at, 'DD.MM.YYYY HH:mm') : '-'}</p>
        <p>Обновлено {post?.updated_at ? Utils.getFormatDate(post.updated_at, 'DD.MM.YYYY HH:mm') : '-'}</p>
        <p>
          {post?.url ? (
            <a href={post.url} target="_blank" rel="noreferrer">{`Открыть пост в VK ${post.source.name || ''}`}</a>
          ) : (
            '-'
          )}
        </p>
      </div>
    </div>
  </div>
);
