import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';
import { getFinalPrice } from 'src/shared/lib';
import { renderTaskStatus } from 'src/shared/ui/renderTaskStatus';
import { Task } from './types';

export const mainColumnName = 'code';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const defaultData = {
  items: [],
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: PER_PAGE,
    total: 0,
  },
};

export const columns: ColumnsType<Task> = [
  {
    title: 'Задание',
    dataIndex: 'code',
    key: 'code',
    render: (_, data) =>
      data.code ? (
        <Link to={`/task/${data.id}`} state={{ prevPath: true }}>
          {data.code}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Цена',
    dataIndex: 'price',
    key: 'price',
    render: data => {
      const resultPrice = getFinalPrice(data);
      return data ? resultPrice : '-';
    },
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    render: data =>
      data ? (
        <Link to={`/profile/client/${data?.id}`} state={{ prevPath: true }}>
          {data?.name}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    render: data =>
      data ? (
        <Link to={`/profile/supplier/${data?.id}`} state={{ prevPath: true }}>
          {data?.name}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => Utils.getFormatDate(data, 'DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Количество товаров',
    dataIndex: 'count',
    key: 'count',
    render: (_, data) => `${data.received_count}/${data.count}`,
  },
  {
    title: 'Статус задания',
    dataIndex: 'status_name',
    key: 'status',
    render: data => renderTaskStatus(data),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
