import api from '../api';

export interface AddLineReqData {
  name: string;
  floor_id?: number;
}

export interface UpdateLineReqData {
  id: number;
  data: { name: string; floor_id?: number; building_id?: number };
}

export const addLine = (data: AddLineReqData) => api.post('/line', data).then(response => response.data);

export const updateLine = (data: UpdateLineReqData) =>
  api.put(`/line/${data.id}`, data.data).then(response => response.data);

export const removeLine = (id: number) => api.delete(`/line/${id}`).then(response => response.data);
