import { Menu } from 'antd';
import { useIsAdmin } from 'src/widgets/header';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { taskStatus } from 'src/shared/config';

import { OrdersList } from './ordersList';
import { TaskList } from './tasksList';
import { RefundsList } from './refundsList';
import styles from './Orders.module.less';

export const OrdersPage = () => {
  const location = useLocation();
  const isAdmin = useIsAdmin();

  return (
    <section className={styles.orders}>
      <h2 className={styles.title}>Заказы</h2>

      <Menu mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
        <Menu.Item key="/orders">
          <Link to="/orders">Заказы</Link>
        </Menu.Item>
        <Menu.Item key="/orders/tasksList">
          <Link to="/orders/tasksList">Задания</Link>
        </Menu.Item>
        {isAdmin ? (
          <Menu.Item key="/orders/refundsList">
            <Link to="/orders/refundsList">Возвраты</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key="/orders/notAvailable">
          <Link to="/orders/notAvailable">Нет в наличии</Link>
        </Menu.Item>
      </Menu>

      <div className={styles.body}>
        <Routes>
          <Route path="/" element={<OrdersList />} />
          <Route path="/tasksList" element={<TaskList />} />
          {isAdmin ? <Route path="/refundsList" element={<RefundsList />} /> : null}
          <Route path="/notAvailable" element={<TaskList status={taskStatus.notAvailable} />} />
        </Routes>
      </div>
    </section>
  );
};
