import { Button, Empty, Spin, Tabs } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { MainModal, Title } from 'src/shared/ui';
import { renderStatus } from '../../helpers';
import { $logDetails, getLogDetailsFx, openLogDetails, restartParser } from '../../model';

import styles from './LogDetailsModal.module.less';

interface PropsType {
  id?: string | null;
  onClose: () => void;
}

export const LogDetailsModal = ({ id, onClose }: PropsType) => {
  const logDetails = useStore($logDetails);
  const isLoading = useStore(getLogDetailsFx.pending);

  useEffect(() => {
    if (id) {
      openLogDetails(id);
    }
  }, [id]);

  return (
    <MainModal width="800px" isOpen={!!id} closeHandler={onClose}>
      <Title>Детали лога</Title>
      {isLoading ? (
        <div className={styles.center}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {logDetails ? (
            <div>
              <div className={styles.head}>
                <p className={styles.info}>
                  <span>Cтатус: </span>
                  {renderStatus(logDetails.status)}
                </p>
                <Button onClick={() => id && restartParser(id)}>Перезапустить парсер</Button>
              </div>
              {logDetails.error ? (
                <>
                  <p className={styles.info}>Ошибка:</p>
                  <p className={styles.error}>{logDetails.error}</p>
                </>
              ) : (
                ''
              )}
              <Tabs
                defaultActiveKey="post"
                items={[
                  {
                    label: `Пост`,
                    key: 'post',
                    children: <pre className={styles.json}>{JSON.stringify(logDetails.post, null, 2)}</pre>,
                  },
                  {
                    label: `Продукт`,
                    key: 'product',
                    children: <pre className={styles.json}>{JSON.stringify(logDetails.product, null, 2)}</pre>,
                  },
                ]}
              />
            </div>
          ) : (
            <Empty description="Лог не найден" />
          )}
        </div>
      )}
    </MainModal>
  );
};
