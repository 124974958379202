import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { intermedCard } from 'src/pages/task/config';
import { getFormattedPhone } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';
import { Utils } from 'src/shared/lib';
import styles from './InfoCard.module.less';

interface InfoCardType {
  data?: {
    id?: number;
    name?: string;
    phone?: string;
    pavilion?: string;
    created_at?: DateType;
  };
  title: string;
  type: string;
  comment?: string;
  source?: {
    name: string;
    url: string;
  };
  takenToWorkDate?: DateType;
  action?: JSX.Element | null;
}

export const InfoCard = ({ data, title, type, comment, source, takenToWorkDate, action }: InfoCardType) => (
  <Card className={styles.wrapper}>
    <h2 className={styles.title}>
      <span>{title}</span>
      <span>
        {type === intermedCard && takenToWorkDate ? Utils.getFormatDate(takenToWorkDate, 'DD.MM.YYYY HH:mm') : ''}
      </span>
    </h2>
    <div className={styles.infoBlock}>
      <p>
        <strong>
          {data?.id ? (
            <Link className={styles.name} to={`/profile/${type}/${data.id}`}>
              {data.name || '-'}
            </Link>
          ) : (
            '-'
          )}
        </strong>
      </p>
      <p>
        <strong>Телефон: </strong>
        <span className={styles.phone}>{data?.phone ? getFormattedPhone(data.phone) : '-'}</span>
      </p>
      {data?.pavilion ? (
        <p>
          <strong>Торговая точка: </strong>
          <span className={styles.pavilion}>{data?.pavilion || '-'}</span>
        </p>
      ) : null}
      {comment ? (
        <div className={styles.comment}>
          <strong>Комментарий: </strong>
          <p>
            <strong>{comment || '-'}</strong>
          </p>
        </div>
      ) : null}
      {source ? (
        <div className={styles.source}>
          <strong>Источник: </strong>
          <p>
            <span>
              {source?.url ? (
                <a href={source.url} target="_blank" rel="noreferrer">
                  {source.url}
                </a>
              ) : (
                '-'
              )}
            </span>
            <span>{source?.name || ''}</span>
          </p>
        </div>
      ) : null}
      {action}
    </div>
  </Card>
);
