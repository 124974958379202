import { Card, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import styles from './SuppParserList.module.less';

export interface Source {
  items: DetailedSource[];
  categories: Record<number, { name: string }>;
  supplier_pavilions: Record<number, { pavilion: { name: string; id: number } }>;
  pagination: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}
export interface DetailedSource {
  category_id: number;
  category_name?: string;
  id: string | number;
  is_active: boolean;
  name: string;
  url: string;
  supplier_id?: string | number;
  supplier_pavilion_id: number;
  full_name?: string;
  disabled?: boolean;
}

interface SuppParserCardProps {
  sources: Source;
  source: DetailedSource;
  categories: Record<number, { name: string }>;
  pavilions: Record<number, { pavilion: { name: string } }>;
  setModalsState: (data: { isAdd: boolean; isUpdate: boolean }) => void;
  setActiveSource: (data: DetailedSource) => void;
}

export const SuppParserList = ({
  source,
  sources,
  categories,
  pavilions,
  setModalsState,
  setActiveSource,
}: SuppParserCardProps) => {
  const handleDropdownMenu = (value: number | string): void => {
    const activeSource = sources && sources.items.find(({ id }) => id === value);
    if (activeSource) {
      setActiveSource(activeSource);
    }
    setModalsState({
      isAdd: false,
      isUpdate: true,
    });
  };

  return (
    <Card key={source.id} className={styles.card}>
      <div className={styles.body}>
        <div>
          <div className={styles.wrapper}>
            <h3 className={styles.title}>{pavilions[source.supplier_pavilion_id]?.pavilion?.name || '-'}</h3>
            <Dropdown
              overlayClassName={styles.menu}
              placement="bottom"
              menu={{ items: [{ key: 'edit', label: 'Редактировать' }], onClick: () => handleDropdownMenu(source.id) }}>
              <MoreOutlined />
            </Dropdown>
          </div>
          <div className={styles.info}>
            <p>{source?.url || '-'}</p>
            <p>
              Категория: <strong>{categories[source.category_id]?.name || '-'}</strong>
            </p>
            <p>
              Активность: <strong>{source?.is_active ? 'Активный' : 'Неактивный'}</strong>
            </p>
          </div>
        </div>
        {/* TODO: add when the parser will be fixed */}
        {/* <Button disabled={!source.is_active} onClick={() => handleLoadSourceData(source.id)}>
          Загрузить
        </Button> */}
      </div>
    </Card>
  );
};
