import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample } from 'effector';
import { commissionApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { Commission, CommissionSettingsKeys, resultCommission } from './types';

export const $commission = createStore<resultCommission | null>(null);

export const initCommissionPage = createEvent();
export const submitUpdateCommissionData = createEvent<commissionApi.UpdateSettingsReqData>();

export const getCommissionFx = createEffect(commissionApi.getSettings);
export const updateCommissionFx = createEffect<
  commissionApi.UpdateSettingsReqData,
  null,
  AxiosError<{ message: string }>
>(commissionApi.updateSettings);

$commission.on(getCommissionFx.doneData, (_, data: Commission) => {
  const commissionKeys = Object.values(CommissionSettingsKeys);
  const resultData =
    (data?.settings || []).reduce((acc, { key, value }) => {
      if (commissionKeys.includes(key || '')) {
        acc = {
          ...acc,
          [key]: key === CommissionSettingsKeys.BankMinAmountCommission ? Number(value) / 100 : value || '',
        };
      }
      return acc;
    }, {} as resultCommission) || {};

  return resultData;
});

sample({
  clock: initCommissionPage,
  target: getCommissionFx,
});

sample({
  clock: submitUpdateCommissionData,
  target: updateCommissionFx,
});

sample({
  clock: updateCommissionFx.doneData,
  target: getCommissionFx,
});

sample({
  clock: updateCommissionFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении настроек комиссии',
  target: setError,
});

sample({
  clock: updateCommissionFx.doneData,
  fn: () => 'Комиссия успешно изменена',
  target: setSuccess,
});
