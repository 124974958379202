import api from '../api';

export interface AddFloorReqData {
  name: string;
  building_id: number;
}

export interface UpdateFloorReqData {
  id: number;
  data: { name: string; building_id: number };
}

export const addFloor = (data: AddFloorReqData) => api.post('/floor', data).then(response => response.data);

export const updateFloor = (data: UpdateFloorReqData) =>
  api.put(`/floor/${data.id}`, data.data).then(response => response.data);

export const removeFloor = (id: number) => api.delete(`/floor/${id}`).then(response => response.data);
