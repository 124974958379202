import {
  ClientStatusOptions,
  IntermedStatusOptions,
  ChatClient,
  ChatIntermed,
  ProfileStatusesFiltered,
  Characteristic,
} from './types';

export const clientStatuses = {
  active: 'Активный',
  ban: 'Бан',
};

export const intermedStatuses = {
  approve: 'Подтвержден',
  reject: 'Не одобрен',
};

export const clientStatusOpts: ClientStatusOptions = Object.entries(clientStatuses) as ClientStatusOptions;
export const intermedStatusOpts: IntermedStatusOptions = Object.entries(intermedStatuses) as IntermedStatusOptions;
export const notAllowedToEditStatuses = ['DELETED', 'FULL_DELETED '];

export const sizeCharacteristic = 'size';

export const PER_PAGE = 10;

export const initImagesReqData = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const initChatInfoData = {
  clients: [] as ChatClient<ProfileStatusesFiltered>[],
  intermediaries: [] as ChatIntermed<ProfileStatusesFiltered>[],
  characteristic: {} as Characteristic,
};
