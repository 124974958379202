import { Button, Form, Input, TreeSelect } from 'antd';
import { setError } from 'src/features/notifications';
import { useEffect, useState } from 'react';
import { Image } from 'src/shared/lib';
import { DragAndDrop, Title } from 'src/shared/ui';
import { getFileFx } from '../../model';
import { Category, CategoryFormData, TreeData } from '../../types';

import styles from './EditModal.module.less';

interface ModalProps {
  category?: Category | null;
  treeData: TreeData[];
  closeHandler: () => void;
  submitHandler: (data: CategoryFormData) => void;
}

export const EditModal = ({ category, treeData, closeHandler, submitHandler }: ModalProps) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(category);
    if (!category) {
      form.resetFields();
    }
  }, [form.setFieldsValue, category?.id]);

  useEffect(() => {
    if (category?.image) {
      getInitialFile(category.image);
    } else {
      setFileList([]);
    }
  }, [category?.image]);

  const confirm = async (values: Category) => {
    try {
      const image = fileList.length > 0 ? await Image.toBase64(fileList[0]) : '';
      submitHandler({ ...values, image: image ? `${image}` : '' });
      form.resetFields();
      setFileList([]);
    } catch (err) {
      setError('Ошибка загрузки изображения');
    }
  };

  const getInitialFile = async (url: string) => {
    const file = await getFileFx(url);
    setFileList([file]);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.editModal}>
      <Title>{category ? 'Изменение категории' : 'Создание категории'}</Title>
      <Form form={form} name="basic" onFinish={confirm} autoComplete="off" layout="vertical">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите наименование категории!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Загрузить фотографию категории" name="image">
          <DragAndDrop name="image" multiple={false} fileList={fileList} setFileList={setFileList} maxCount={1} />
        </Form.Item>
        <Form.Item name="parent_id" label="Родительская категория">
          <TreeSelect
            style={{ width: '100%' }}
            treeData={treeData}
            placeholder="Выберите категорию"
            treeDefaultExpandAll
            allowClear
          />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
