import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import styles from './NameProfilesCard.module.less';
import { Client } from '../../types';

interface INameProfilesCardProps {
  data: Client;
  show?: boolean;
}

export const NameProfilesCard = ({ data, show = true }: INameProfilesCardProps) => (
  <div className={styles.NameProfilesCard}>
    <Avatar shape="circle" size="large" icon={<PictureOutlined />} src={data.avatar_url} />
    <p className={styles.NameProfilesCardName}>{data.name}</p>
  </div>
);
