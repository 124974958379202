import { createEffect, createEvent, createStore, sample } from 'effector';
import { AxiosError } from 'axios';
import { useStore } from 'effector-react';
import { clientsApi } from 'src/shared/api';
import { setError, setSuccess } from 'src/features/notifications';

import { ClientProfile } from './types';
import { renderClientsStatus } from './config';

export const $client = createStore<ClientProfile | null>(null);
export const $isPrevPath = createStore(false);

export const initClientProfilePage = createEvent<string>();
export const submitChangeStatusForm = createEvent<clientsApi.ClientsStatusReqData>();
export const submitDeleting = createEvent<string>();
export const submitForceDeleting = createEvent<string>();
export const setIsPrevPath = createEvent<boolean>();

export const getClientFx = createEffect(clientsApi.getClient);
export const changeStatusFx = createEffect(clientsApi.setClientsStatus);
export const deleteClientFx = createEffect<string, null, AxiosError<{ message: string }>>(clientsApi.deleteClient);
export const forceDeleteClientFx = createEffect<string, null, AxiosError<{ message: string }>>(
  clientsApi.forceDeleteClient,
);

$client.on(getClientFx.doneData, (_, data) => ({
  ...data,
  status: renderClientsStatus(data.status),
  originalStatus: data.status,
}));
$isPrevPath.on(setIsPrevPath, (_, data) => data);

export const useClient = () => useStore($client);

sample({
  clock: submitForceDeleting,
  target: forceDeleteClientFx,
});

sample({
  clock: submitDeleting,
  target: deleteClientFx,
});

sample({
  clock: initClientProfilePage,
  target: getClientFx,
});

sample({
  clock: submitChangeStatusForm,
  target: changeStatusFx,
});

sample({
  clock: changeStatusFx.doneData,
  source: submitChangeStatusForm,
  fn: ({ id }) => id,
  target: getClientFx,
});

sample({
  clock: changeStatusFx.failData,
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: changeStatusFx.doneData,
  fn: () => 'Статус успешно изменен',
  target: setSuccess,
});

sample({
  clock: deleteClientFx.doneData,
  fn: () => 'Клиент успешно удален',
  target: setSuccess,
});

sample({
  clock: deleteClientFx.doneData,
  source: submitDeleting,
  target: getClientFx,
});

sample({
  clock: deleteClientFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении',
  target: setError,
});

sample({
  clock: forceDeleteClientFx.doneData,
  fn: () => 'Все данные успешно удалены',
  target: setSuccess,
});

sample({
  clock: forceDeleteClientFx.doneData,
  source: submitForceDeleting,
  target: getClientFx,
});

sample({
  clock: forceDeleteClientFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении',
  target: setError,
});
