export const throttle = (callee: (...args: any[]) => void, timeout: number) => {
  let timer: string | ReturnType<typeof setTimeout> = '';

  return function perform(...args: any[]) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);

      clearTimeout(timer);
      timer = '';
    }, timeout);
  };
};
