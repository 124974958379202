import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { ColumnsType } from 'antd/lib/table';
import { Table, Card, Empty, Input, Tooltip, Spin, Button, Dropdown } from 'antd';
import { ClearOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { TableSettings } from 'src/entities/tableSettings';
import { Hooks, getIsoTimeZoneTime } from 'src/shared/lib';
import { ArchiveErrorModal } from './ui/archiveErrorModal';
import {
  $visibleColumns,
  changeVisibleColumns,
  $legalEntities,
  $isArchiveError,
  $suppliers,
  $users,
  getLegalEntitiesFx,
  changeRequestData,
  resetArchiveError,
  $pageSize,
  changePageSize,
  submitArchLegalReqData,
  submitChangeSuppData,
  submitChangeUsersData,
} from './model';
import { BackArrow } from 'src/entities/backArrow';
import { columns, mainColumnName, PER_PAGE, typeSelectOptions, initState, statusOptions } from './config';
import { LegalEntity } from './types';
import { NumberTypeEnum, LegalCreatorType } from 'src/shared/types';
import styles from './LegalEntities.module.less';

export interface LegalEntitiesProps {
  isArchive?: boolean;
}

export const LegalEntities = ({ isArchive }: LegalEntitiesProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const legalEntities = useStore($legalEntities);
  const isLoading = useStore(getLegalEntitiesFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const isArchiveError = useStore($isArchiveError);
  const suppliers = useStore($suppliers);
  const users = useStore($users);
  const [creatorType, setCreatorType] = useState<LegalCreatorType>('USER');
  const [activeLegal, setActiveLegal] = useState<LegalEntity | null>();

  useEffect(() => {
    submitChangeSuppData(initState);
    submitChangeUsersData(initState);
  }, []);

  useEffect(() => {
    const { page, statuses, from, to, ...filters } = searchParams;
    const currPage = page ? +page : 1;
    const resultStatuses = statuses ? { statuses: statuses.split(',') } : {};
    const resultDate = from && to ? { registered_at: { from, to } } : {};

    changeRequestData({
      ...filters,
      ...resultStatuses,
      ...resultDate,
      is_archived: !!isArchive,
      pagination: { page: currPage, per_page: pageSize },
    });
  }, [searchParams, pageSize]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<LegalEntity> = [];
    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchFieldHandler = (value: string, type: string) => {
    setSearchParams({ ...searchParams, [type]: value || null, page: 1 });
  };

  const searchNameHandler = () => {
    setSearchParams({ ...searchParams, name: nameValue, page: 1 });
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && nameValue) {
      const { name, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    }
    setNameValue(e.target.value);
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    setSearchParams({
      ...searchParams,
      from: data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : null,
      to: data?.date_to ? getIsoTimeZoneTime({ day: data.date_to }, 'to') : null,
      page: 1,
    });
  };

  const searchCreatorHandler = (value: string) => {
    const reqData = value ? { ...initState, name: value } : initState;
    if (creatorType === 'USER') {
      submitChangeUsersData(reqData);
    } else {
      submitChangeSuppData(reqData);
    }
  };

  const resetCreatorHandler = () => {
    const filters = { ...searchParams };
    delete filters.creator_id;
    delete filters.creator_type;
    setSearchParams({ ...filters, page: 1 });
  };

  const handleSelectCreator = (value: number) => {
    if (value) {
      setSearchParams({ ...searchParams, creator_id: value, creator_type: creatorType, page: 1 });
    }
  };

  const handleChangeCreatorType = (value: LegalCreatorType) => setCreatorType(value);

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSelectStatus = (value: string[]) => {
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });
  };

  const getNameSearch = Hooks.useColumnSearch<LegalEntity>(
    searchFieldHandler,
    'full_name',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.full_name,
  );

  const getInnSearch = Hooks.useColumnSearch<LegalEntity>(
    searchFieldHandler,
    NumberTypeEnum.INN,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.inn,
  );

  const getRegisteredAtSearch = Hooks.useColumnSearchDate<LegalEntity>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
    'registered_at',
  );

  const getCreatorSearch = Hooks.useColumnAutocomplete<LegalEntity>(
    searchCreatorHandler,
    handleSelectCreator,
    resetCreatorHandler,
    creatorType === 'USER' ? users?.items : suppliers?.items,
    isActiveFilter,
    setIsActiveFilter,
    false,
    typeSelectOptions,
    handleChangeCreatorType,
    creatorType,
  );

  const getCeoFullNameSearch = Hooks.useColumnSearch<LegalEntity>(
    searchFieldHandler,
    'ceo_name',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.ceo_name,
  );

  const getCeoPhoneSearch = Hooks.useColumnSearch<LegalEntity>(
    searchFieldHandler,
    NumberTypeEnum.CEO_PHONE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.ceo_phone,
  );

  const getUrlSearch = Hooks.useColumnSearch<LegalEntity>(
    searchFieldHandler,
    'site_url',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.site_url,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<LegalEntity>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
  );

  const filteredColumns: ColumnsType<LegalEntity> = visibleColumns.map(column => {
    switch (column.key) {
      case 'full_name':
        return { ...column, ...getNameSearch(column.key, column.title) };
      case 'inn':
        return { ...column, ...getInnSearch(column.key, column.title) };
      case 'registered_at':
        return { ...column, ...getRegisteredAtSearch(column.key, column.title) };
      case 'creator':
        return { ...column, ...getCreatorSearch(column.key, column.title) };
      case 'ceo_last_name':
        return { ...column, ...getCeoFullNameSearch(column.key, column.title) };
      case 'ceo_phone':
        return { ...column, ...getCeoPhoneSearch(column.key, column.title) };
      case 'site_url':
        return { ...column, ...getUrlSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const tableColumns: ColumnsType<LegalEntity> = !isArchive
    ? [
        ...filteredColumns,
        {
          title: '',
          key: 'settings',
          render: (_, data: LegalEntity) => (
            <Dropdown
              overlayClassName={styles.menu}
              placement="bottom"
              menu={{
                items: [
                  {
                    key: 'archive',
                    label: 'В архив',
                    onClick: () => {
                      submitArchLegalReqData(data?.id);
                      setActiveLegal(data);
                    },
                  },
                ],
              }}>
              <MoreOutlined />
            </Dropdown>
          ),
        },
      ]
    : filteredColumns;

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {isArchive ? <BackArrow prevPath="/profiles/legalEntities" /> : null}
      {isArchive ? <h2 className={styles.title}>Архив юридических лиц</h2> : null}

      <Card className={styles.legalEntities}>
        <div className={styles.searchSettings}>
          <Input.Search
            value={nameValue}
            className={styles.search}
            placeholder="Поиск"
            onChange={handleSearchName}
            onSearch={searchNameHandler}
            allowClear
          />
          <div className={styles.wrapper}>
            {!isArchive ? (
              <>
                <Link className={styles.archive} to="/profiles/legalEntities/archive" state={{ prevPath: true }}>
                  <Button type="primary">Архив</Button>
                </Link>
                <Link to="/profile/legal/create">
                  <Button type="primary">Добавить</Button>
                </Link>
              </>
            ) : null}
            <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
              <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
            </Tooltip>
            <TableSettings
              columns={columns}
              disabledColumns={[mainColumnName]}
              visibleColumnsKeys={visibleColumnsKeys}
              setVisibleColumnsKeys={changeVisibleColumns}
            />
          </div>
        </div>
        <Table
          rowKey={record => record.id}
          bordered
          columns={tableColumns}
          dataSource={legalEntities?.items || []}
          pagination={{
            showSizeChanger: true,
            onChange: handleChangePage,
            onShowSizeChange: handleChangePageSize,
            current: legalEntities?.pagination?.current_page || 1,
            pageSize: legalEntities?.pagination?.per_page || PER_PAGE,
            total: legalEntities?.pagination?.total || 0,
          }}
          scroll={{ x: 1200 }}
          locale={{
            emptyText: <Empty description="Нет данных" />,
          }}
        />
      </Card>

      <ArchiveErrorModal
        isArchive
        legalEntity={activeLegal || null}
        isOpen={isArchiveError}
        closeHandler={() => resetArchiveError()}
      />
    </div>
  );
};
