import { Tag } from 'antd';
import { LegalStatusesType } from 'src/pages/profiles/legalEntities/types';
import { statusColors } from 'src/shared/config';

export const RenderLegalStatus = (status: LegalStatusesType) => {
  switch (status) {
    case 'not_registered':
      return <Tag style={statusColors.green}>Не зарегистрирован</Tag>;
    case 'registered':
      return <Tag style={statusColors.blue}>Зарегистрирован</Tag>;
    case 'registration_error':
      return <Tag style={statusColors.red}>Ошибка регистрации</Tag>;
    default:
      return '-';
  }
};
