export const actionOptions = {
  create: 'Создание',
  update: 'Редактирование',
  delete: 'Удаление',
};

const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};
