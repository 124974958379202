import { useColumnSearch } from './useColumnSearch';
import { useColumnSearchDate } from './useColumnSearchDate';
import { useColumnAutocomplete } from './useColumnAutocomplete';
import { useColumnSelect } from './useColumnSelect';
import { useCheckActivity } from './useCheckActivity';
import { useSearchParamsObject } from './useSearchParamsObject';
import { useColumnMultiSelect } from './useColumnMultiSelect';
import { useColumnTreeSelect } from './useColumnTreeSelect';
import { useInfinitePagination } from './useInfinitePagination';
import { useEchoNewMessage } from './useEchoNewMessage';
import { useTestEchoNewMessage } from './useTestEchoNewMessage';
import { useColumnSearchDateTime } from './useColumnSearchDateTime';

export const Hooks = {
  useColumnSearch,
  useColumnSearchDate,
  useColumnAutocomplete,
  useColumnSelect,
  useCheckActivity,
  useSearchParamsObject,
  useColumnMultiSelect,
  useColumnTreeSelect,
  useInfinitePagination,
  useEchoNewMessage,
  useColumnSearchDateTime,
  useTestEchoNewMessage,
};
