/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { ColumnsType } from 'antd/lib/table';
import { Table, Card, Empty, Input, Tooltip, Spin, Button } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { TableSettings } from 'src/entities/tableSettings';
import { Hooks } from 'src/shared/lib';
import {
  $clientsVisibleColumns,
  $legalsVisibleColumns,
  $clientsPageSize,
  $legalsPageSize,
  $clientsPayments,
  $legalEntitiesPayments,
  $clients,
  $legalEntities,
  $isExcel,
  $isReportPending,
  changeClientsPageSize,
  changeLegalsPageSize,
  changeClientsVisibleColumns,
  changeLegalsVisibleColumns,
  submitReportReqData,
  submitClientsPaymentsReqData,
  submitLegalPaymentsReqData,
  submitLegalReqData,
  submitGetExcel,
  getClientsPaymentsFx,
  getLegalEntitiesPaymentsFx,
  submitClientsReqData,
} from './model';
import { mainColumnName, PER_PAGE, initState, clientsColumns, legalColumns, columns } from './config';
import { getIsoTimeZoneTime, getFinalFilterPrice } from 'src/shared/lib';
import { ClientPayment, LegalEntityPayment, LegalEntitiesPayments, Column } from './types';
import { NumberTypeEnum } from 'src/shared/types';
import { v4 as uuid } from 'uuid';
import styles from './PaymentsList.module.less';

interface PaymentsListProps {
  paymentsType: 'legal-entities' | 'clients';
}

export const PaymentsList = ({ paymentsType }: PaymentsListProps) => {
  const isClientsPayments = paymentsType === 'clients';
  const [visibleColumns, setVisibleColumns] = useState(isClientsPayments ? clientsColumns : legalColumns);
  const clientsVisibleColumnKeys = useStore($clientsVisibleColumns);
  const legalsVisibleColumnKeys = useStore($legalsVisibleColumns);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const clientPayments = useStore($clientsPayments);
  const legalPayments = useStore($legalEntitiesPayments);
  const clients = useStore($clients);
  const legalEntities = useStore($legalEntities);
  const isReportPending = useStore($isReportPending);
  const isExcel = useStore($isExcel);
  const isClientLoading = useStore(getClientsPaymentsFx.pending);
  const isLegalLoading = useStore(getLegalEntitiesPaymentsFx.pending);
  const clientsPageSize = useStore($clientsPageSize);
  const legalsPageSize = useStore($legalsPageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const visibleColumnsKeys = isClientsPayments ? clientsVisibleColumnKeys : legalsVisibleColumnKeys;
  const pageSize = isClientsPayments ? clientsPageSize : legalsPageSize;

  useEffect(() => {
    const columns = paymentsType === 'clients' ? clientsColumns : legalColumns;
    setVisibleColumns(columns);
    handleResetFilters();
  }, [paymentsType]);

  useEffect(() => {
    const {
      page,
      per_page,
      init_at_from,
      init_at_to,
      authorized_at_from,
      authorized_at_to,
      confirmed_at_from,
      confirmed_at_to,
      ...filters
    } = searchParams;
    const currPage = page ? +page : 1;
    const dateArr = [
      ['init_at', init_at_from, init_at_to],
      ['authorized_at', authorized_at_from, authorized_at_to],
      ['confirmed_at', confirmed_at_from, confirmed_at_to],
    ];

    const dateReqData = dateArr.reduce((acc, [dateName, dateFromData, dateToData]) => {
      if (dateFromData && dateToData) {
        acc = { ...acc, [dateName]: { from: dateFromData, to: dateToData } };
      }
      return acc;
    }, {});

    const reqData = {
      ...filters,
      ...dateReqData,
      pagination: {
        page: currPage,
        per_page: pageSize,
      },
    };

    if (isExcel) {
      const columns: Column[] = visibleColumns.map(column => ({
        name: column?.title ? column.title.toString() : '',
        path: column?.key ? column.key.toString() : '',
      }));
      submitReportReqData({ type: paymentsType, payload: { uuid: uuid(), filter: reqData, columns } });
      return;
    }

    if (isClientsPayments) {
      submitClientsPaymentsReqData(reqData);
    } else {
      submitLegalPaymentsReqData(reqData);
    }
  }, [searchParams, pageSize, isClientsPayments, isExcel]);

  useEffect(() => {
    if (!isClientsPayments) {
      submitLegalReqData(initState);
    }
  }, [isClientsPayments]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<ClientPayment | LegalEntityPayment> = [];
    const columns = isClientsPayments ? clientsColumns : legalColumns;

    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchFieldHandler = (value: string, type: string) => {
    setSearchParams({ ...searchParams, [type]: value || null, page: 1 });
  };

  const searchTerminalHandler = (value: string) => {
    setSearchParams({ ...searchParams, terminal_name: value || null, page: 1 });
  };

  const handleSearchTerminal = (e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const searchClientHandler = (value: string) => {
    if (value) {
      submitClientsReqData({ ...initState, name: value });
    }
  };

  const handleSelectClient = (value: number) => {
    setSearchParams({ ...searchParams, client_id: value, page: 1 });
  };

  const resetClientHandler = () => {
    const filters = { ...searchParams };
    delete filters.client_id;
    setSearchParams({ ...filters, page: 1 });
  };

  const handleChangePage = (value: number, size: number) => {
    setSearchParams({ ...searchParams, page: value.toString(), per_page: size });
    if (isClientsPayments) {
      changeClientsPageSize(size);
    } else {
      changeLegalsPageSize(size);
    }
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }, fieldType: string) => {
    if (data.date_after && data.date_to && fieldType === 'init_at') {
      const [afterDay, afterTime] = data.date_after.split(' ');
      const [beforeDay, beforeTime] = data.date_to.split(' ');
      const fromDate = data?.date_after ? getIsoTimeZoneTime({ day: afterDay, time: afterTime }, 'from') : null;
      const toDate = data?.date_to ? getIsoTimeZoneTime({ day: beforeDay, time: beforeTime }, 'from') : null;
      setSearchParams({
        ...searchParams,
        page: 1,
        [`${fieldType}_from`]: fromDate,
        [`${fieldType}_to`]: toDate,
      });
    } else if (data.date_after && data.date_to) {
      const fromDate = data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : null;
      const toDate = data?.date_to ? getIsoTimeZoneTime({ day: data.date_to }, 'to') : null;
      setSearchParams({
        ...searchParams,
        page: 1,
        [`${fieldType}_from`]: fromDate,
        [`${fieldType}_to`]: toDate,
      });
    } else if (!data.date_after && !data.date_to) {
      const filters = { ...searchParams };
      delete filters[`${fieldType}_from`];
      delete filters[`${fieldType}_to`];
      setSearchParams({ ...filters, page: 1 });
    }
  };

  const searchPriceHandler = (value: string, fieldType: string) => {
    const resultValue = value ? getFinalFilterPrice(value) : null;
    setSearchParams({ ...searchParams, [fieldType]: resultValue, page: 1 });
  };

  const handleSearchLegal = (value: string) => {
    const legalsData = value ? { ...initState, name: value } : initState;
    submitLegalReqData(legalsData);
  };

  const handleSelectLegal = (value: number) => {
    setSearchParams({ ...searchParams, legal_entity_id: value, page: 1 });
  };

  const resetLegalHandler = () => {
    const filters = { ...searchParams };
    delete filters.legal_entity_id;
    setSearchParams({ ...filters, page: 1 });
    submitLegalReqData(initState);
  };

  const getOrderSearch = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchFieldHandler,
    'order_id',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.order,
  );

  const getInitAtDate = Hooks.useColumnSearchDateTime<ClientPayment | LegalEntityPayment>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
    'init_at',
  );

  const getAuthorizedAtDate = Hooks.useColumnSearchDate<ClientPayment | LegalEntityPayment>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
    'authorized_at',
  );

  const getConfirmedAtDate = Hooks.useColumnSearchDate<ClientPayment | LegalEntityPayment>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
    'confirmed_at',
  );

  const getAmount = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchPriceHandler,
    NumberTypeEnum.AMOUNT,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );
  const getBankCommission = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchPriceHandler,
    NumberTypeEnum.BANK_COMMISSION,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );

  const getMPCommission = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchPriceHandler,
    NumberTypeEnum.MARKET_PLACE_COMMISSION,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );

  const getMPAmount = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchPriceHandler,
    NumberTypeEnum.MARKET_PLACE_AMOUNT,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );

  const getLegalAmount = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchPriceHandler,
    NumberTypeEnum.LEGAL_ENTITY_AMOUNT,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );

  const getLegalSearch = Hooks.useColumnAutocomplete<ClientPayment | LegalEntityPayment>(
    handleSearchLegal,
    handleSelectLegal,
    resetLegalHandler,
    legalEntities,
    isActiveFilter,
    setIsActiveFilter,
    true,
  );

  const getClientSearch = Hooks.useColumnAutocomplete<ClientPayment | LegalEntityPayment>(
    searchClientHandler,
    handleSelectClient,
    resetClientHandler,
    clients?.items,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getPaymentId = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchFieldHandler,
    'client_payment_id',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.client_payment_id,
  );

  const getClientPaymentId = Hooks.useColumnSearch<ClientPayment | LegalEntityPayment>(
    searchFieldHandler,
    'id',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.id,
  );

  const filteredColumns: ColumnsType<ClientPayment | LegalEntityPayment> = visibleColumns.map(column => {
    switch (column.key) {
      case 'client.name':
        return { ...column, ...getClientSearch(column.key, column.title) };
      case 'order_id':
        return { ...column, ...getOrderSearch(column.key, column.title) };
      case 'init_at':
        return { ...column, ...getInitAtDate(column.key, column.title) };
      case 'authorized_at':
        return { ...column, ...getAuthorizedAtDate(column.key, column.title) };
      case 'confirmed_at':
        return { ...column, ...getConfirmedAtDate(column.key, column.title) };
      case 'legalEntity.full_name':
        return { ...column, ...getLegalSearch(column.key, column.title) };
      case 'amount':
        return { ...column, ...getAmount(column.key, column.title) };
      case 'bank_commission':
        return { ...column, ...getBankCommission(column.key, column.title) };
      case 'market_place_commission':
        return { ...column, ...getMPCommission(column.key, column.title) };
      case 'market_place_amount':
        return { ...column, ...getMPAmount(column.key, column.title) };
      case 'legal_entity_amount':
        return { ...column, ...getLegalAmount(column.key, column.title) };
      case 'client_payment_id':
        return { ...column, ...getPaymentId(column.key, column.title) };
      case 'id':
        return { ...column, ...getClientPaymentId(column.key, column.title) };
      default:
        return column;
    }
  });

  if (isClientLoading || isLegalLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card className={styles.payments}>
      <div className={styles.searchSettings}>
        <Input.Search
          value={nameValue}
          className={styles.search}
          placeholder="Поиск"
          onChange={handleSearchTerminal}
          onSearch={searchTerminalHandler}
          allowClear
        />
        <div className={styles.wrapper}>
          <Button loading={isReportPending} onClick={() => submitGetExcel()}>
            Экспорт в Excel
          </Button>
          <Tooltip
            overlayStyle={{
              position: 'fixed',
            }}
            title="Очистить все фильтры">
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>
          <TableSettings
            columns={isClientsPayments ? clientsColumns : legalColumns}
            disabledColumns={[mainColumnName]}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={isClientsPayments ? changeClientsVisibleColumns : changeLegalsVisibleColumns}
          />
        </div>
      </div>
      <Table
        rowKey={record => record.id}
        bordered
        columns={filteredColumns}
        dataSource={isClientsPayments ? clientPayments?.items || [] : legalPayments?.items || []}
        pagination={{
          showSizeChanger: true,
          onChange: handleChangePage,
          current: isClientsPayments
            ? clientPayments?.pagination?.current_page || 1
            : legalPayments?.pagination?.current_page || 1,
          pageSize: isClientsPayments
            ? clientPayments?.pagination?.per_page || PER_PAGE
            : legalPayments?.pagination?.per_page || PER_PAGE,
          total: isClientsPayments ? clientPayments?.pagination?.total || 0 : legalPayments?.pagination?.total || 0,
        }}
        scroll={{
          x: 1200,
        }}
        locale={{
          emptyText: <Empty description="Нет данных" />,
        }}
      />
    </Card>
  );
};
