import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { sourceApi } from 'src/shared/api';

import { Source } from './ui/suppParserList/SuppParserList';

export const $sources = createStore<Source | null>(null);

export const initSuppParserPage = createEvent<sourceApi.GetSourcesReqData>();
export const submitLoadFromSourceData = createEvent<sourceApi.LoadFromSourceReqData>();
export const clearSources = createEvent();

export const getSourcesFx = createEffect(sourceApi.getSources);
export const loadFromSourceFx = createEffect(sourceApi.loadFromSource);

$sources.on(getSourcesFx.doneData, (_, data) => data);
$sources.reset(clearSources);

sample({
  clock: initSuppParserPage,
  target: getSourcesFx,
});

sample({
  clock: submitLoadFromSourceData,
  target: loadFromSourceFx,
});

sample({
  clock: loadFromSourceFx.failData,
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: loadFromSourceFx.doneData,
  fn: () => 'Данные из источника успешно загружены',
  target: setSuccess,
});
