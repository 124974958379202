import api from '../api';

export interface ClientsRequestData {
  name?: string;
  email?: string;
  phone?: string;
  created_at?: string;
  created_at_from?: string;
  created_at_to?: string;
  statuses?: string[];
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface ClientsStatusReqData {
  id: string | number;
  type: string;
}

export const getClientsList = (data: ClientsRequestData) => api.post('/clients', data).then(response => response.data);

export const getClient = (id: string | number) => api.get(`/client/${id}`).then(response => response.data);
export const deleteClient = (id: string) => api.delete(`/client/${id}/soft`).then(response => response.data);
export const forceDeleteClient = (id: string) => api.delete(`/client/${id}/force`).then(response => response.data);

export const setClientsStatus = (data: ClientsStatusReqData) =>
  api.post(`/clients/${data.id}/${data.type}`).then(response => response.data);
