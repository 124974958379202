import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { employeesFormRules } from 'src/pages/employees/config';
import { ConfirmForm } from 'src/shared/ui';
import { Title } from 'src/shared/ui';
import { getConfirmText } from './helpers';
import styles from './PasswordForm.module.less';

export interface FormSuccess {
  password: string;
  passwordRepeat: string;
}

interface PasswordFormProps {
  changedFields: string[];
  successHandler: (value: string) => void;
  closeHandler: () => void;
}

export const PasswordForm = ({ changedFields, closeHandler, successHandler }: PasswordFormProps) => {
  const [form] = Form.useForm();
  const [title, setTitle] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const newTitle = getConfirmText(changedFields);
    setTitle(newTitle);
  }, [changedFields]);

  const formSuccessHandler = (values: FormSuccess) => {
    setPassword(values.password);
    setIsModalOpen(true);
  };

  const formSubmitHandler = () => {
    setIsModalOpen(false);
    successHandler(password);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className={styles.passwordForm}>
      <Title>Изменение пароля</Title>
      <Form form={form} name="basic" layout="vertical" onFinish={formSuccessHandler} autoComplete="off">
        <Form.Item rules={employeesFormRules.password} label="Новый пароль" name="password">
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: 'Пожалуйста, повторите новый пароль!' },
            ({ getFieldValue }) => ({
              message: 'Введенные пароли не совпадают!',
              validator(_, value) {
                if (getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject();
              },
            }),
          ]}
          label="Повторите новый пароль"
          name="passwordRepeat">
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Подтвердить
            </Button>
          </Form.Item>
          <Button onClick={handleCancelClose} className={styles.button}>
            Отменить
          </Button>
        </div>
      </Form>

      <ConfirmForm
        isOpen={isModalOpen}
        title={title}
        confirmHandler={formSubmitHandler}
        closeHandler={handleCloseModal}
      />
    </div>
  );
};
