import api from '../api';
import { DetailedTask, TaskTransfer } from 'src/pages/task/types';

export interface TasksRequestData {
  code?: string;
  status?: number | string;
  statuses?: string[];
  count?: number | string;
  price?: number | string;
  client_id?: number | string;
  intermediary_id?: number | string;
  order_id?: number | string;
  created_at?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface TransTaskReqData {
  id: string | number;
  data: TaskTransfer;
}

export interface TaskByCodeReqData {
  code: string;
}

export const getTasksList = (data: TasksRequestData) =>
  api.get('/tasks', { params: data }).then(response => response.data);

export const getTask = (id: string): Promise<DetailedTask> => api.get(`/task/${id}`).then(response => response.data);

export const transferTask = ({ id, data }: TransTaskReqData) =>
  api.post(`/task/${id}/transfer`, data).then(response => response.data);

export const getTasksQr = (id: string) => api.get(`/task/${id}/qr`).then(response => response.data);

export const getTaskByCode = (data: TaskByCodeReqData) =>
  api.get(`/task-by-code/${data.code}`).then(response => response.data);

export const getTaskStatuses = () => api.get('/task/statuses').then(response => response.data);

export const cancelIntermediary = (id: number) => api.post(`/task/${id}/cancel-intermediary`)
  .then(response => ({
    ...response.data,
    id
  }));

export const changeStatus = (id: number) => api.post(`/task/${id}/revert-status`);
