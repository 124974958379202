import { createEvent, createEffect, createStore, sample } from 'effector';
import { Paginated } from 'src/shared/types';
import { categoriesApi, sourceApi } from 'src/shared/api';
import { updateSourceFx } from 'src/entities/updateSource/model';
import { initState } from './config';
import { Categories, RawSource, Source } from './types';

export const $sources = createStore<Paginated<Source> | null>(null);
export const $requestData = createStore<sourceApi.GetSourcesReqData>(initState);
export const $categories = createStore<Categories | null>(null);
export const $activeSource = createStore<Source | null>(null);

export const changeRequestData = createEvent<sourceApi.GetSourcesReqData>();
export const initSourcesPage = createEvent();
export const setActiveSource = createEvent<Source | null>();
export const refreshSourceData = createEvent();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const getSourcesFx = createEffect(sourceApi.getSources);

$requestData.on(changeRequestData, (_, data) => data);
$categories.on(getCategoriesFx.doneData, (_, data) => data);
$sources.on(getSourcesFx.doneData, (_, data) => ({
  ...data,
  items: data.items.map((source: RawSource) => ({
    ...source,
    supplier: data.supplier_pavilions[source.supplier_pavilion_id].supplier,
    pavilion: data.supplier_pavilions[source.supplier_pavilion_id].pavilion,
    category: data.categories[source.category_id],
  })),
}));

sample({
  clock: initSourcesPage,
  target: getCategoriesFx,
});

sample({
  clock: changeRequestData,
  target: getSourcesFx,
});

sample({
  clock: setActiveSource,
  target: $activeSource,
});

sample({
  clock: updateSourceFx.doneData,
  source: $requestData,
  fn: data => ({ ...data, ...initState }),
  target: getSourcesFx,
});
