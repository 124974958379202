import { getFinalPrice } from 'src/shared/lib';
import { OrderPrices } from '../../types';
import styles from './OrdersPrice.module.less';

interface OrdersPriceProps {
  prices: OrderPrices;
}
export const OrdersPrice = ({ prices }: OrdersPriceProps) => (
  <article className={styles.ordersPrice}>
    <div className={styles.prices}>
      <h3 className={styles.title}>Стоимость</h3>
      <p>
        <span>Сумма заказа</span>
        <span>
          {prices.at_the_beginning.products === prices.now.products ? (
            getFinalPrice(prices.at_the_beginning.products)
          ) : (
            <>
              <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.products)}</span>
              {getFinalPrice(prices.now.products)}
            </>
          )}
        </span>
      </p>
      <p>
        <span>
          Комиссия
          {prices.at_the_beginning.margin_percent === prices.now.margin_percent ? (
            ` (${prices.at_the_beginning.margin_percent}%)`
          ) : (
            <>
              <span className={styles['beginning-price']}>({prices.at_the_beginning.margin_percent}%)</span> (
              {prices.now.margin_percent}
              %)
            </>
          )}
        </span>
        <span>
          {prices.at_the_beginning.margin === prices.now.margin ? (
            getFinalPrice(prices.at_the_beginning.margin)
          ) : (
            <>
              <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.margin)}</span>
              {getFinalPrice(prices.now.margin)}
            </>
          )}
        </span>
      </p>
      <p>
        <span>Доставка</span>
        <span>
          {prices.at_the_beginning.delivery === prices.now.delivery ? (
            getFinalPrice(prices.at_the_beginning.delivery)
          ) : (
            <>
              <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.delivery)}</span>
              {getFinalPrice(prices.now.delivery)}
            </>
          )}
        </span>
      </p>
      <p>
        <strong>Итого к оплате:</strong>
        <span>
          {prices.at_the_beginning.total === prices.now.total ? (
            getFinalPrice(prices.at_the_beginning.total)
          ) : (
            <>
              <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.total)}</span>
              {getFinalPrice(prices.now.total)}
            </>
          )}
        </span>
      </p>
    </div>
  </article>
);
