import api from '../api';

export interface CharAddReqData {
  type: string;
  data: { value: string; category_ids: number[] };
}

export interface CharEditReqData {
  id: number;
  type: string;
  data: { value: string; category_ids: number[] };
}

export interface GetCharacteristicsReqData {
  code: string;
  filters?: {
    category_id?: number;
  };
}

export const getCharacteristics = (data: GetCharacteristicsReqData) =>
  api.post(`/characteristic/${data.code}/values`, data.filters).then(response => response.data);

export const addCharacteristic = ({ type, data }: CharAddReqData) =>
  api.post(`/characteristic/${type}`, data).then(response => response.data);

export const editCharacteristic = ({ type, data, id }: CharEditReqData) =>
  api.put(`/characteristic/${type}/${id}`, data).then(response => response.data);

export const removeCharacteristic = (data: number) =>
  api.delete(`/characteristic-value/${data}`).then(response => response.data);
