// import { createEffect, createEvent, createStore, sample } from 'effector';
import { createEffect, createEvent, createStore, sample } from 'effector-logger';
import { chatsApi } from 'src/shared/api';
import { setError } from 'src/features/notifications';

export const $chatList = createStore<chatsApi.ChatListResponseData | null>(null);

export const getChatListEvent = createEvent<chatsApi.ChatListRequestData>();
export const getChatListByScrollEvent = createEvent<chatsApi.ChatListRequestData>();
export const resetChatEvent = createEvent();

export const getChatsFx = createEffect(chatsApi.getChatList);
export const getChatsByScrollFx = createEffect(chatsApi.getChatList);

$chatList
  .on(getChatsFx.doneData, (_, data) => data)
  .on(getChatsByScrollFx.doneData, (state, data) =>
    state ? { ...state, items: [...state.items, ...data.items] } : data,
  )
  .reset(resetChatEvent);

sample({
  clock: getChatListEvent,
  target: getChatsFx,
});

sample({
  clock: getChatListByScrollEvent,
  target: getChatsByScrollFx,
});

sample({
  clock: getChatsFx.failData,
  fn: ({ message }) => message,
  target: setError,
});
