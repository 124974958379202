import { Col, Row, Button, Input, Form, Select } from 'antd';

import styles from './AddProductCharacteristics.module.less';

const { TextArea } = Input;
const { Option } = Select;

interface IAddProductCharacteristicsProps {
  next: () => void;
  prev: () => void;
}

export const AddProductCharacteristics = ({ next, prev }: IAddProductCharacteristicsProps) => {
  const [form] = Form.useForm();

  return (
    <article className={styles.AddProductCharacteristics}>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => { }}
      >
        <h3>Характеристики</h3>
        <Row>
          <Col span={24}>
            <div>
              <Form.Item name="type" label="Тип товара" required tooltip="This is a required field">
                <Select
                  mode="tags"
                  allowClear
                  placeholder="Тип товара"
                  onChange={() => { }}
                >
                  <Option key="1">Option 1</Option>
                  <Option key="2">Option 2</Option>
                  <Option key="3">Option 3</Option>
                  <Option key="4">Option 4</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Form.Item name="color" label="Цвет товара" required tooltip="This is a required field">
                <Select
                  mode="tags"
                  allowClear
                  placeholder="Цвет товара"
                  onSelect={() => { }} // Один выбор
                >
                  <Option key="1">Option 1</Option>
                  <Option key="2">Option 2</Option>
                  <Option key="3">Option 3</Option>
                  <Option key="4">Option 4</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Form.Item name="size" label="Размер товара" required tooltip="This is a required field">
                <Select
                  mode="tags"
                  allowClear
                  placeholder="Размер товара"
                  onChange={() => { }}
                >
                  <Option key="1">Option 1</Option>
                  <Option key="2">Option 2</Option>
                  <Option key="3">Option 3</Option>
                  <Option key="4">Option 4</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div>
              <Form.Item name="gender" label="Пол товара" required tooltip="This is a required field">
                <Select
                  mode="tags"
                  allowClear
                  placeholder="Пол товара"
                  onSelect={() => { }} // Один выбор
                >
                  <Option key="1">Option 1</Option>
                  <Option key="2">Option 2</Option>
                  <Option key="3">Option 3</Option>
                  <Option key="4">Option 4</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Form.Item name="season" label="Сезон" required tooltip="This is a required field">
                <Select
                  mode="tags"
                  allowClear
                  placeholder="Сезон"
                  onSelect={() => { }} // Один выбор
                >
                  <Option key="1">Option 1</Option>
                  <Option key="2">Option 2</Option>
                  <Option key="3">Option 3</Option>
                  <Option key="4">Option 4</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Form.Item name="season" label="Описание товара" tooltip="This is a required field">
                <TextArea rows={4} placeholder="Описание товара" />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <div className={styles.AddProductCharacteristicsBtn}>
          <Button
            onClick={prev}
          >Назад</Button>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                // disabled={
                //   !form.isFieldsTouched(true) ||
                //   !!form.getFieldsError().filter(({ errors }) => errors.length).length
                // }
                onClick={next}
              >Далее</Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </article>
  )
};



