import { Card } from 'antd';
import { MainButton } from 'src/shared/ui/mainButton';
import { BackArrow } from 'src/entities/backArrow';
import { ProductInfoCard } from 'src/entities/productInfoCard';
import { InfoCard } from 'src/entities/infoCard';
import { Title } from 'src/shared/ui/title';

import styles from './Refund.module.less';
import { RefundCard } from './ui/refundCard';

const mockClient = {
  title: 'Сведения о клиенте',
  name: 'Константин Константинович',
  email: '123@gmail.com',
  phone: '+7(999)789-09-09',
};

const mockSupplier = {
  title: 'Сведения о поставщике',
  name: 'Константин Константинович',
  email: '123@gmail.com',
  phone: '+7(999)789-09-09',
  pavilion: '123-09',
};

const mockIntermediary = {
  title: 'Сведения о посреднике',
  name: 'Константин Константинович',
  email: '123@gmail.com',
  phone: '+7(999)789-09-09',
};

export const RefundPage = () => (
  <section>
    <div className={styles.buttons}>
      <BackArrow />
      <div className={styles['button-group']}>
        <MainButton>Отказать</MainButton>
        <MainButton>Согласовать</MainButton>
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.column}>
        <Card>
          <Title>Переписка с клиентом</Title>
          <MainButton className={styles.button}>Ознакомиться с перепиской посредника</MainButton>
          <MainButton>Ознакомиться с перепиской поставщика</MainButton>
        </Card>
      </div>
      <div className={styles.column}>
        <RefundCard />
      </div>
    </div>
  </section>
);
