import styles from './AddProductFaq.module.less';

export const AddProductFaq = () => (
  <article className={styles.AddProductFaq}>
    <h3>FAQ</h3>

    <p>
      Подробную информацию по заведению товаров вы сможете
      найти в соответствующем разделе <a href="#0">Справочного центра.</a>
    </p>
  </article>
);


