import { createStore, createEvent, createEffect, sample } from 'effector';
import { AxiosError } from 'axios';
import { categoriesApi } from 'src/shared/api';
import { productsApi } from 'src/shared/api';
import { setError, setSuccess } from 'src/features/notifications';
import { Product } from 'src/pages/products/types';
import { Categories } from 'pages/profiles/suppliersProfile/ui/suppParser/ui/addSource/types';

export const $categories = createStore<Categories | null>(null);

export const initAddProductInfo = createEvent();
export const submitEditProdData = createEvent<productsApi.EditProductReqData>();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const editProductFx = createEffect<productsApi.EditProductReqData, Product, AxiosError<{ message: string }>>(
  productsApi.editProduct,
);

$categories.on(getCategoriesFx.doneData, (_, data) => data);

sample({
  clock: initAddProductInfo,
  target: getCategoriesFx,
});

sample({
  clock: submitEditProdData,
  target: editProductFx,
});

sample({
  clock: editProductFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении товара',
  target: setError,
});

sample({
  clock: editProductFx.doneData,
  fn: () => 'Товар успешно изменен',
  target: setSuccess,
});
