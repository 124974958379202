import api from '../api';

export interface GetSourcesReqData {
  supplier_pavilion_ids?: number[];
  category_id?: number;
  search?: string;
  pagination: {
    page: number;
    per_page: number;
  };
}

export interface AddSourceReqData {
  url: string;
  pavilion_id: number;
  supplier_id: number;
  category_id: number;
  is_active: boolean;
}

export interface UpdateSourceReqData {
  id: number | string;
  data: {
    url: string;
    pavilion_id: number;
    supplier_id: number | string;
    category_id: number;
    is_active: boolean;
  };
}

export interface LoadFromSourceReqData {
  id: number;
  date: string;
}

export const getSources = (data: GetSourcesReqData) => api.post('/sources', data).then(response => response.data);

export const addSource = (data: AddSourceReqData) => api.post('/source', data).then(response => response.data);

export const updateSource = (data: UpdateSourceReqData) =>
  api.put(`/source/${data.id}`, data.data).then(response => response.data);

export const loadFromSource = (data: LoadFromSourceReqData) =>
  api.post(`/source/${data.id}/run`, { to: data.date }).then(response => response.data);
