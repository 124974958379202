import { createEffect, createEvent, createStore, sample } from 'effector';
import { clientsApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { Storage } from 'src/shared/lib';

import { initColumnSettings, initState, PER_PAGE } from './config';
import { Client } from './types';

export const $clients = createStore<Paginated<Client> | null>(null);
export const $requestData = createStore<clientsApi.ClientsRequestData>(initState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'clients', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'clients', key: 'pageSize' });

export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changeRequestData = createEvent<clientsApi.ClientsRequestData>();
export const changePageSize = createEvent<number>();

export const getClientsFx = createEffect(clientsApi.getClientsList);

$clients.on(getClientsFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getClientsFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});
