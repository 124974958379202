import moment from 'moment';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const defaultSelectValue = undefined;

export const treeSelectFieldNames = { label: 'name', value: 'id', children: 'children' };
export const treeFieldNames = { title: 'name', value: 'id', children: 'children', key: 'id' };
export const defaultDatePickerState = moment('00:00:00', 'HH:mm:ss');
export const afterDatePickerState = moment('23:59:59', 'HH:mm:ss');
export const defaultDatePickerFormat = 'YYYY-MM-DD HH:mm:ss';

export const archiveErrorStatus = 403;

export enum productStatusOptions {
  ACTIVE = 'В продаже',
  DEACTIVATED = 'Неактивен',
  IN_REVIEW = 'На модерацию',
}

export const FIRST_ELEMENT_INDEX = 0;

export const PER_PAGE = 10;
export const PER_PAGE_LEGAL = 11;

export const defaultInitState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const initStateLegal = {
  statuses: ['registered'],
  is_archived: false,
  pagination: {
    per_page: PER_PAGE_LEGAL,
    page: 1,
  },
};

export const taskStatus = {
  notPaid: 1,
  readyForWork: 2,
  inWork: 3,
  redeemed: 4,
  inspection: 5,
  inStock: 6,
  notAvailable: 7,
  removalProcess: 9,
  canceled: 0,
};

export const taskStatusesNames = {
  1: 'Не оплачено',
  2: 'Готово к работе',
  3: 'В работе',
  4: 'Выкуплено',
  5: 'Приемка',
  6: 'На складе',
  7: 'Нет в наличии',
  10: 'Выполнено',
  0: 'Отменено',
};

export const orderStatus = {
  notPaid: 1,
  paid: 2,
  inWork: 3,
  redeemed: 4,
  inStock: 5,
  assembly: 6,
  deliveryCreated: 7,
  delivery: 8,
  delivered: 9,
  done: 10,
  debitSuccessfull: 11,
  debitFail: 12,
  courierWaiting: 13,
  rejection: 14,
  refund: 15,
  outOfStock: 16,
  refundFail: 18,
  canceled: 0,
};

export const statusColors = {
  blue: {
    color: '#108ee9',
    borderColor: '#108ee9',
  },
  darkBlue: {
    color: '#160647',
    borderColor: '#160647',
  },
  blueShadow: {
    color: '#0091d5',
    borderColor: '#0091d5',
  },
  red: {
    color: '#e50000',
    borderColor: '#e50000',
  },
  lightRed: {
    color: '#d32d41',
    borderColor: '#d32d41',
  },
  orange: {
    color: '#ffa500',
    borderColor: '#ffa500',
  },
  green: {
    color: '#008000',
    borderColor: '#008000',
  },
  lightGreen: {
    color: '#6ab187',
    borderColor: '#6ab187',
  },
  muddyGreen: {
    color: '#5a959a',
    borderColor: '#5a959a',
  },
  darkGold: {
    color: '#daa520',
    borderColor: '#daa520',
  },
  softPink: {
    color: '#ffc0eb',
    borderColor: '#ffc0eb',
  },
  violet: {
    color: '#d896ff',
    borderColor: '#d896ff',
  },
  brown: {
    color: '#ac3e31',
    borderColor: '#ac3e31',
  },
  salmon: {
    color: '#ff6666',
    borderColor: '#ff6666',
  },
  cyan: {
    color: '#33eee0',
    borderColor: '#33eee0',
  },
  gray: {
    color: '#000000',
    borderColor: '#000000',
  },
  lightGray: {
    color: '#808080',
    borderColor: '#808080',
  },
  lavender: {
    color: '#e6e6fa',
    borderColor: '#e6e6fa',
  },
  default: {
    color: '#031a83',
    borderColor: '#031a83',
  },
};

export const WSConfig = {
  WS_HOST: 'echo.allall.ru',
  WS_PORT: 443,
  WS_AUTH_URL: 'https://api.allall.ru/api/v1/admin-panel/broadcasting/auth',
  WS_KEY: 'pusher_app_key',
};

export const ClientWSConfig = {
  WS_HOST: 'echo.allallru.stage.k8s.zetest.site',
  WS_PORT: 443,
  WS_AUTH_URL: 'https://allallru.develop.k8s.zetest.site/api/v1/client-profile/broadcasting/auth',
  WS_KEY: 'pusher_app_key',
};

// export const WSConfig = {
//   WS_HOST: 'echo.allallru.stage.k8s.zetest.site',
//   WS_PORT: 443,
//   WS_AUTH_URL: 'https://allallru.develop.k8s.zetest.site/api/v1/admin-panel/broadcasting/auth',
//   WS_KEY: 'pusher_app_key',
// };
