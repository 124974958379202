import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import {
  $adminsLogs,
  $users,
  $usersData,
  $requestData,
  getAdminsLogsOnPageInit,
  changeRequestData,
  changeUsersData,
  getAdminsLogsFx,
} from './model';
import { adminsLogsApi } from 'src/shared/api';
import { actionOptions, initState } from './config';
import { Card, Spin, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { Hooks } from 'src/shared/lib';
import { AdminLogs } from './types';
import { AdminsLogsTable } from './ui/table';
import { columns } from './ui/table/config';
import { getIsoTimeZoneTime } from 'src/shared/lib';
import styles from './AdminsLogs.module.less';

export const AdminsLogs = () => {
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const data = useStore($adminsLogs);
  const requestData = useStore($requestData);
  const usersData = useStore($usersData);
  const users = useStore($users);
  const isLoading = useStore(getAdminsLogsFx.pending);

  useEffect(() => {
    getAdminsLogsOnPageInit(initState);
  }, []);

  const handleSelectAction = (value: string) => {
    if (value) {
      changeRequestData({ ...requestData, action: value, ...initState });
    } else {
      const { action, ...filteredReqData } = requestData;
      changeRequestData({ ...filteredReqData, ...initState });
    }
  };

  const handleSelectClient = (value: number) => {
    changeRequestData({ ...requestData, ...initState, user_id: value });
  };

  const handleChangePage = (value: number, size: number) => {
    changeRequestData({ ...requestData, pagination: { page: value, per_page: size } });
  };

  const searchClientHandler = (value: string) => {
    if (value) {
      changeUsersData({ ...usersData, name: value });
    }
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    if (data.date_after || data.date_to) {
      changeRequestData({
        ...requestData,
        date_after: data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : '',
        date_to: data?.date_to ? getIsoTimeZoneTime({ day: data.date_after }, 'to') : '',
      });
    } else {
      const filters = JSON.parse(JSON.stringify(requestData));
      delete filters.date_after;
      delete filters.date_to;
      changeRequestData({ ...filters, ...initState });
    }
  };

  const resetClientHandler = () => {
    const { user_id: userId, ...filteredReqData } = requestData;
    changeRequestData({ ...filteredReqData, ...initState });
  };

  const handleResetFilters = () => {
    changeRequestData(initState);
    setIsActiveFilter(true);
  };

  const getActionSearch = Hooks.useColumnSelect<AdminLogs>(
    handleSelectAction,
    Object.entries(actionOptions),
    'Выбрать действие',
    'action',
    isActiveFilter,
    setIsActiveFilter,
  );

  const getUserSearch = Hooks.useColumnAutocomplete<AdminLogs>(
    searchClientHandler,
    handleSelectClient,
    resetClientHandler,
    users?.items,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getDateSearch = Hooks.useColumnSearchDate<AdminLogs>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
  );

  const filteredColumns: ColumnsType<AdminLogs> = columns.map(column => {
    switch (column.key) {
      case 'description':
        return { ...column, ...getActionSearch(column.key, column.title) };
      case 'user':
        return { ...column, ...getUserSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.adminsLogs}>
      <Card>
        <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
          <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
        </Tooltip>
        <AdminsLogsTable
          data={data?.logs || []}
          columns={filteredColumns}
          handleChangePage={handleChangePage}
          pagination={data?.pagination}
        />
      </Card>
    </div>
  );
};
