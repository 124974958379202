import React, { useState } from 'react';
import { Input, Select, Button } from 'antd';
import { Buildings } from 'src/pages/settings/pavilions/types';
import {
  submitAddBuildingReqData,
  submitAddFloorReqData,
  submitAddLineReqData,
  submitAddPavilionReqData,
} from 'src/pages/settings/pavilions/model';
import { FloorOpts, LineOpts, PavilOpts } from './types';
import { mainOptions, initFloorOpts, initLineOpts, initPavilOpts } from './config';

import styles from './AddBuilding.module.less';

interface AddBuildingProps {
  buildings?: Buildings | null;
  closeHandler: () => void;
}

export const AddBuilding = ({ buildings, closeHandler }: AddBuildingProps) => {
  const [selectedOption, setSelectedOption] = useState('building');
  const [buildingValue, setBuildingValue] = useState('');
  const [selectedFloorOpt, setSelectedFloorOpt] = useState<FloorOpts>(initFloorOpts);
  const [selectedLineOpt, setSelectedLineOpt] = useState<LineOpts>(initLineOpts);
  const [selectedPavilOpt, setSelectedPavilOpt] = useState<PavilOpts>(initPavilOpts);

  const handleChangeOptions = (value: string) => {
    setSelectedOption(value);
    setBuildingValue('');
    setSelectedFloorOpt(initFloorOpts);
    setSelectedLineOpt(initLineOpts);
    setSelectedPavilOpt(initPavilOpts);
  };

  const filteredLinesFloors = selectedLineOpt.building_id
    ? buildings?.items.find(({ id }) => id === selectedLineOpt.building_id)?.floors
    : [];

  const filteredPavilFloors = selectedPavilOpt.building_id
    ? buildings?.items.find(({ id }) => id === selectedPavilOpt.building_id)?.floors
    : [];

  const filteredPavilLines = selectedPavilOpt.floor_id
    ? filteredPavilFloors?.find(({ id }) => id === selectedPavilOpt.floor_id)?.lines
    : [];

  return (
    <div className={styles.addBuilding}>
      <div className={styles.mainSelect}>
        <label className={styles.label} htmlFor="mainSelect">
          <span className={styles.labelName}>Добавить</span>
          <Select id="mainSelect" defaultValue={selectedOption} style={{ width: 470 }} onChange={handleChangeOptions}>
            {mainOptions.map(({ value, name }) => (
              <Select.Option className={styles.mainOption} value={value} key={value}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </label>
      </div>

      <div className={styles.wrapper}>
        {selectedOption === 'building' && (
          <div className={styles.form}>
            <Input
              className={styles.input}
              value={buildingValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBuildingValue(e.target.value);
              }}
              placeholder="Введите название ТЦ"
              required
            />
            <Button
              disabled={!buildingValue}
              type="primary"
              onClick={() => {
                submitAddBuildingReqData({ name: buildingValue });
                closeHandler();
              }}>
              Добавить
            </Button>
          </div>
        )}
      </div>

      {selectedOption === 'floor' && buildings && Object.keys(buildings).length && (
        <div className={styles.form}>
          <label className={styles.label} htmlFor="floorsBuilding">
            <span className={styles.labelName}>ТЦ</span>
            <Select
              className={styles.select}
              id="floorsBuilding"
              style={{ width: 470 }}
              onChange={(value: number) => {
                setSelectedFloorOpt({ ...selectedFloorOpt, building_id: value });
              }}>
              {buildings &&
                buildings?.items.map(({ id, name }) => (
                  <Select.Option className={styles.option} value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </label>
          <Input
            disabled={!selectedFloorOpt.building_id}
            value={selectedFloorOpt.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedFloorOpt({ ...selectedFloorOpt, value: e.target.value });
            }}
            placeholder="Введите название этажа"
            required
          />
          <Button
            disabled={!selectedFloorOpt.value}
            type="primary"
            onClick={() => {
              submitAddFloorReqData({ name: selectedFloorOpt.value, building_id: selectedFloorOpt.building_id });
              closeHandler();
            }}>
            Добавить
          </Button>
        </div>
      )}

      {selectedOption === 'line' && buildings && Object.keys(buildings).length && (
        <div className={styles.form}>
          <label className={styles.label} htmlFor="linesBuilding">
            <span className={styles.labelName}>ТЦ</span>
            <Select
              className={styles.select}
              id="linesBuilding"
              style={{ width: 470 }}
              onChange={(value: number) => {
                const isUnnamedBuilding =
                  buildings?.items.filter(({ id, name }) => id === value && !name).length > 0 || false;
                setSelectedLineOpt({
                  floor_id: 0,
                  building_id: value,
                  value: '',
                  floorValue: 0,
                  isUnnamedBuilding,
                });
              }}>
              {buildings &&
                buildings?.items.map(({ id, name }) => (
                  <Select.Option className={styles.option} value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </label>

          <label className={styles.label} htmlFor="linesFloors">
            <span className={styles.labelName}>Этаж</span>
            <Select
              className={styles.select}
              value={selectedLineOpt.floorValue || null}
              id="linesFloors"
              style={{ width: 470 }}
              onChange={(value: number) => {
                setSelectedLineOpt({ ...selectedLineOpt, floor_id: value, floorValue: value });
              }}>
              <Select.Option className={styles.option} value="&nbsp;">
                Без этажа
              </Select.Option>
              {filteredLinesFloors &&
                filteredLinesFloors?.map(({ id, name }) => (
                  <Select.Option className={styles.option} value={id} key={id} title={name}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </label>

          <Input
            disabled={!selectedLineOpt.floor_id}
            value={selectedLineOpt.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedLineOpt({ ...selectedLineOpt, value: e.target.value });
            }}
            placeholder="Введите название линии"
            required
          />
          <Button
            disabled={!selectedLineOpt.value}
            type="primary"
            onClick={() => {
              const reqData = !selectedLineOpt.floor_id.toString().trim()
                ? { name: selectedLineOpt.value, building_id: selectedLineOpt.building_id }
                : { name: selectedLineOpt.value, floor_id: selectedLineOpt.floor_id };
              submitAddLineReqData(reqData);
              closeHandler();
            }}>
            Добавить
          </Button>
        </div>
      )}

      {selectedOption === 'pavilion' && buildings && Object.keys(buildings).length && (
        <div className={styles.form}>
          <label className={styles.label} htmlFor="pavilionsBuildings">
            <span className={styles.labelName}>ТЦ</span>
            <Select
              className={styles.select}
              id="pavilionsBuildings"
              style={{ width: 470 }}
              onChange={(value: number) => {
                const unnamedBuilding = buildings?.items.filter(({ id, name }) => id === value && !name);
                setSelectedPavilOpt({
                  ...selectedPavilOpt,
                  value: '',
                  building_id: value,
                  line_id: 0,
                  floor_id: 0,
                  floorValue: 0,
                  lineValue: 0,
                  isUnnamedBuilding: unnamedBuilding.length > 0 || false,
                  isUnnamedFloor: false,
                  unnamedLines: unnamedBuilding[0]?.lines || [],
                });
              }}>
              {buildings?.items.map(({ id, name }) => (
                <Select.Option className={styles.option} value={id} key={id}>
                  {name || `Безымянный ${id}`}
                </Select.Option>
              ))}
            </Select>
          </label>

          <label className={styles.label} htmlFor="pavilionsFloors">
            <span className={styles.labelName}>Этаж</span>
            <Select
              className={styles.select}
              value={selectedPavilOpt.floorValue || null}
              id="pavilionsFloors"
              style={{ width: 470 }}
              onChange={(value: number) => {
                const isNamedFloor = value.toString().replace(/&nbsp;/g, '');
                const unnamedFloorLines = !isNamedFloor
                  ? buildings?.items.filter(({ id }) => id === selectedPavilOpt.building_id)
                  : [];
                setSelectedPavilOpt({
                  ...selectedPavilOpt,
                  value: '',
                  floor_id: value,
                  line_id: 0,
                  floorValue: value,
                  lineValue: 0,
                  isUnnamedFloor: !isNamedFloor,
                  unnamedLines: unnamedFloorLines[0]?.lines || [],
                });
              }}>
              <Select.Option className={styles.option} value={'&nbsp;'}>
                Без этажа
              </Select.Option>
              {filteredPavilFloors &&
                filteredPavilFloors?.map(({ id, name }) => (
                  <Select.Option className={styles.option} value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </label>

          <label className={styles.label} htmlFor="pavilionsLines">
            <span className={styles.labelName}>Линия</span>
            <Select
              className={styles.select}
              value={selectedPavilOpt.lineValue || null}
              disabled={selectedPavilOpt.isUnnamedFloor ? false : !filteredPavilLines?.length}
              id="pavilionsLines"
              style={{ width: 470 }}
              onChange={(value: number) => {
                setSelectedPavilOpt({ ...selectedPavilOpt, line_id: value, lineValue: value });
              }}>
              {filteredPavilLines
                ? filteredPavilLines?.map(({ id, name }) => (
                    <Select.Option className={styles.option} value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))
                : selectedPavilOpt.unnamedLines.map(({ id, name }) => (
                    <Select.Option className={styles.option} value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
            </Select>
          </label>

          <Input
            disabled={!selectedPavilOpt.line_id}
            value={selectedPavilOpt.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedPavilOpt({ ...selectedPavilOpt, value: e.target.value });
            }}
            placeholder="Введите название павильона"
            required
          />
          <Button
            disabled={!selectedPavilOpt.value}
            className={styles.button}
            type="primary"
            onClick={() => {
              submitAddPavilionReqData({ name: selectedPavilOpt.value, line_id: selectedPavilOpt.line_id });
              closeHandler();
            }}>
            Добавить
          </Button>
        </div>
      )}
    </div>
  );
};
