import React from 'react';
import { Modal } from 'antd';
import styles from './MainModal.module.less';
import classNames from 'classnames';

interface IMainModalProps {
  width?: string | number;
  isOpen: boolean;
  closeHandler: () => void;
  children: React.ReactNode;
  closeIconFixed?: boolean;
}

export const MainModal = ({
  width = '520px',
  isOpen,
  closeHandler,
  children,
  closeIconFixed,
  ...rest
}: IMainModalProps) => (
  <Modal
    className={styles.modal}
    closeIcon={
      <span className={classNames([
        styles.close,
        closeIconFixed && styles.fixed
      ])}>
        <span />
      </span>
    }
    width={width}
    title=""
    centered
    footer={null}
    open={isOpen}
    onOk={closeHandler}
    onCancel={closeHandler}
    {...rest}
  >
    {children}
  </Modal>
);
