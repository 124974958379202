import { useEffect } from 'react';
import { ProductsTable } from 'src/features/products';
import { useStore } from 'effector-react';
import { $products, $requestData, initSuppProdPage, changeRequestData, getProductsFx } from './model';
import { initState } from './config';

interface PropsType {
  id?: string;
  idType: 'supplier_id' | 'favorite_for_client_id';
}

export const SuppProductsList = ({ id, idType }: PropsType) => {
  const requestData = useStore($requestData);
  const data = useStore($products);
  const isLoading = useStore(getProductsFx.pending);

  useEffect(() => {
    initSuppProdPage({ ...initState, [idType]: id });
  }, [id]);

  const handleChangePage = (value: number, size: number) => {
    changeRequestData({ ...requestData, [idType]: id, pagination: { page: value, per_page: size } });
  };

  const handleSearch = (obj: { [key: string]: string | string[] }) => {
    changeRequestData({ ...requestData, ...obj, ...initState, [idType]: id });
  };

  const resetSearch = (types: string[]) => {
    const filters = JSON.parse(JSON.stringify(requestData));
    types.forEach(type => delete filters[type]);
    changeRequestData({ ...filters, ...initState, [idType]: id });
  };

  const handleResetFilters = () => {
    changeRequestData({ ...initState, [idType]: id });
  };

  return (
    <ProductsTable
      isLoading={isLoading}
      data={data?.items}
      handleChangePage={handleChangePage}
      handleSearch={handleSearch}
      resetSearch={resetSearch}
      resetFilters={handleResetFilters}
      pagination={data?.pagination}
      withSearch={false}
      isSuppProducts
    />
  );
};
