import { Layout } from 'antd';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProductsPage } from 'src/pages/products';
import { Product } from 'src/pages/products/product';
import { ProfilesPage } from 'src/pages/profiles';
import { OrdersPage } from 'src/pages/orders';
import { AnalyticsPage } from 'src/pages/analytics';
import { AdvertisingPage } from 'src/pages/advertising';
import { ReviewsPage } from 'src/pages/reviews';
import { EmployeesPage } from 'src/pages/employees';
import { StockPage } from 'src/pages/stock';
import { SettingsPage } from 'src/pages/settings';
import { AddProduct } from 'src/pages/addProduct';
import { apiModel } from 'src/shared/api';
import { LoginPage } from 'src/pages/login';
import { RefundPage } from 'src/pages/refund';
import { TaskPage } from 'src/pages/task';
import { Order } from 'src/pages/orders/order';
import { ProfilePage } from 'src/pages/profiles';
import { EmployeesProfile as EmployeePage } from 'src/pages/employees/employeesProfile';
import { LegalEntityCreation } from 'src/pages/legalEntityCreation';
import { Chat } from 'src/pages/chat';
import { Payments } from 'src/pages/payments';
import { LegalEntities } from 'src/pages/profiles/legalEntities';
import { useIsAdmin } from 'src/widgets/header';

import styles from './Content.module.less';

const { Content } = Layout;

export const ContentComponent = () => {
  const isAuth = apiModel.useIsAuth();
  const isAdmin = useIsAdmin();

  if (!isAuth) {
    return (
      <Content className={styles.content}>
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Content>
    );
  }

  return (
    <Content className={styles.content}>
      <Routes>
        <Route path="/" element={<Navigate to={isAuth ? '/orders' : '/login'} />} />
        <Route path="/login" element={<LoginPage />} />
        {isAdmin ? <Route path="/products/*" element={<ProductsPage />} /> : null}
        {isAdmin ? <Route path="/product/:id" element={<Product />} /> : null}
        {isAdmin ? <Route path="/add_product" element={<AddProduct />} /> : null}
        {isAdmin ? <Route path="/products/edit/:id" element={<AddProduct />} /> : null}
        {isAdmin ? <Route path="/profiles/*" element={<ProfilesPage />} /> : null}
        {isAdmin ? <Route path="/profiles/legalEntities/archive" element={<LegalEntities isArchive />} /> : null}
        <Route path="/orders/*" element={<OrdersPage />} />
        <Route path="/analytics" element={<AnalyticsPage />} />
        <Route path="/advertising" element={<AdvertisingPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/stock/*" element={<StockPage />} />
        <Route path="/refund/:id" element={<RefundPage />} />
        <Route path="/task/:id" element={<TaskPage />} />
        <Route path="/order/:id/*" element={<Order />} />
        <Route path="/profile/:type/:id/*" element={<ProfilePage />} />
        {isAdmin ? <Route path="/chat/*" element={<Chat />} /> : null}
        {isAdmin ? <Route path="/settings/*" element={<SettingsPage />} /> : null}
        {isAdmin ? <Route path="/profile/legal/create" element={<LegalEntityCreation />} /> : null}
        {isAdmin ? <Route path="/profile/legal/edit/:id" element={<LegalEntityCreation />} /> : null}
        {isAdmin ? <Route path="/employees" element={<EmployeesPage />} /> : null}
        {isAdmin ? <Route path="/employees/:id/*" element={<EmployeePage />} /> : null}
        {isAdmin ? <Route path="/payments/*" element={<Payments />} /> : null}
      </Routes>
    </Content>
  );
};
