import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Table, Input, Empty, Card, Dropdown, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Title, MainModal } from 'src/shared/ui';
import {
  $namings,
  $isNamingUpdated,
  initNamings,
  getNamingsFx,
  submitAddNamingData,
  submitEditNamingData,
  submitDeleteNamingData,
  resetIsNamingUpdated,
  $pageSize,
  changePageSize,
} from './model';
import { columns, PER_PAGE, initState } from './config';
import { EditNaming } from './ui/editNaming';
import { ConfirmForm } from 'src/shared/ui';
import { Naming } from './types';
import { Category } from 'src/pages/settings/categories/types';
import styles from './Namings.module.less';

export interface NamingsProps {
  category: Category | null;
  handleParserModal?: (type: 'naming' | 'categories', value: boolean) => void;
  handleCategory?: (value: Category | null) => void;
  handleActualPost?: () => void;
}

export const Namings = ({ category, handleParserModal, handleCategory, handleActualPost }: NamingsProps) => {
  const [nameValue, setNameValue] = useState('');
  const namings = useStore($namings);
  const isLoading = useStore(getNamingsFx.pending);
  const isUpdated = useStore($isNamingUpdated);
  const pageSize = useStore($pageSize);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [activeName, setActiveName] = useState<Naming | null>(null);

  useEffect(() => {
    if (category?.id) {
      initNamings({ id: category.id, data: { pagination: { ...initState.pagination, per_page: pageSize } } });
    }
  }, [category?.id]);

  useEffect(() => {
    if (isUpdated && handleActualPost) {
      handleActualPost();
      resetIsNamingUpdated();
    }
  }, [isUpdated, handleActualPost]);

  const handleChangePage = (value: number, size: number) => {
    if (category?.id) {
      initNamings({
        id: category.id,
        data: { pagination: { ...initState.pagination, page: value, per_page: size } },
      });
    }
    changePageSize(size);
  };

  const searchNameHandler = (value: string) => {
    if (value && category?.id) {
      initNamings({
        id: category.id,
        data: { search: value, pagination: { ...initState.pagination, per_page: pageSize } },
      });
    }
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && nameValue && category?.id) {
      initNamings({ id: category.id, data: initState });
    }
    setNameValue(e.target.value);
  };

  const handleAddNaming = (value: { name: string; masks: string[] }) => {
    if (activeName && category?.id) {
      submitEditNamingData({ categoryId: category.id, namingId: activeName.id, data: value });
    } else if (category?.id) {
      submitAddNamingData({ id: category.id, data: value });
    }

    if (handleParserModal && handleCategory) {
      handleCategory(category);
      handleParserModal('naming', false);
    }
  };

  const handleModal = (value: boolean) => setIsEditOpen(value);

  const resultColumns = [
    ...columns,
    {
      title: '',
      key: 'settings',
      render: (data: Naming) => (
        <Dropdown
          overlayClassName={styles.menu}
          placement="bottom"
          menu={{
            items: [
              {
                key: 'edit',
                label: 'Редактировать',
                onClick: () => {
                  setActiveName(data);
                  handleModal(true);
                },
              },
              {
                key: 'remove',
                label: 'Удалить',
                onClick: () => {
                  setIsConfirm(true);
                  setActiveName(data);
                },
              },
            ],
          }}>
          <MoreOutlined />
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  return (
    <Card className={styles.body} bordered={false}>
      <div className={styles.button}>
        <Title className={styles.title}>{category?.name ? `${category.name} - наименования` : '-'}</Title>
        <Button
          type="primary"
          onClick={() => {
            setActiveName(null);
            handleModal(true);
          }}>
          Добавить
        </Button>
      </div>
      <div className={styles.settings}>
        <Input.Search
          value={nameValue}
          className={styles.search}
          placeholder="Поиск"
          onChange={handleSearchName}
          onSearch={searchNameHandler}
          allowClear
        />
      </div>

      <Table
        loading={isLoading}
        rowKey={record => record.id}
        bordered
        scroll={{
          x: 1200,
        }}
        columns={resultColumns}
        dataSource={namings?.items || []}
        pagination={{
          onChange: handleChangePage,
          current: namings?.pagination?.current_page || 1,
          pageSize: namings?.pagination?.per_page || PER_PAGE,
          total: namings?.pagination?.total || 0,
          showSizeChanger: true,
        }}
        locale={{
          emptyText: <Empty description="Нет данных" />,
        }}
      />

      <MainModal isOpen={isEditOpen} closeHandler={() => handleModal(false)}>
        <EditNaming
          categoryId={category?.id}
          categoryName={category?.name}
          naming={activeName}
          successHandler={handleAddNaming}
          closeHandler={() => handleModal(false)}
        />
      </MainModal>

      <ConfirmForm
        isOpen={isConfirm}
        title="Вы уверены, что хотите удалить наименование?"
        closeHandler={() => setIsConfirm(false)}
        confirmHandler={() => {
          setIsConfirm(false);
          if (activeName?.id) {
            submitDeleteNamingData(activeName.id);
          }
          if (handleActualPost) {
            handleActualPost();
          }
        }}
      />
    </Card>
  );
};
