export interface IntermediaryProfile {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar_url: string;
  pass_number: string;
  pass_photo_url?: string;
  pass_valid_until: string;
  status: IntermediaryStatus;
}

export interface ProfilePass {
  pass_number: string;
  pass_photo?: string;
  pass_valid_until: string;
}

export interface IntermedBalance {
  income: number;
  buyout: number;
  balance: number;
}

export enum IntermediaryStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CHECKING = 'CHECKING',
  DELETED = 'DELETED',
  FULL_DELETED = 'FULL_DELETED',
}
