import { createEffect, createEvent, createStore, sample, combine } from 'effector';
import { useStore } from 'effector-react';
import Pusher, { Channel, ChannelAuthorizationCallback } from 'pusher-js';
import axios from 'axios';
import Echo from 'laravel-echo';
import { setError } from 'src/features/notifications';
import { WSConfig as Config, ClientWSConfig } from 'src/shared/config';
import { $chats } from 'src/pages/settings/chatTest/model';
import { RefreshToken } from './types';
import { Storage } from '../lib';
import * as authApi from './queries/authApi';
import { Result } from 'antd';

// import { echoClientHandler } from './echoClientHandler';

export const $token = createStore('');
Storage.persist.entity($token, { slice: 'api', key: 'token' });

export const $refreshToken = createStore('');
Storage.persist.entity($refreshToken, { slice: 'api', key: 'refreshToken' });

export const $isAuthorized = $token.map(token => !!token);

export const setRefreshToken = createEvent<RefreshToken>();

export const getTokenFx = createEffect(authApi.login);
export const loginTrigger = getTokenFx.prepend(({ userName, password }: { userName: string; password: string }) => {
  setError('');
  return {
    email_or_phone: userName,
    password,
  };
});

export const refreshTokenFx = createEffect(authApi.refreshToken);
export const redirectFx = createEffect(() => {
  if (window) {
    window.history.pushState({ backPath: window.location.pathname }, '');
  }
});
export const goBackFx = createEffect(() => {
  if (window) {
    const obj = window.history.state;
    window.location.assign(obj.backPath);
  }
});
const returnEvent = createEvent();

getTokenFx.failData.watch(() => {
  setError('Неверный логин или пароль');
});

$token.on([getTokenFx.doneData, refreshTokenFx.doneData, setRefreshToken], (oldToken, data) => {
  if (!oldToken) {
    returnEvent();
  }
  return data.token;
});
$refreshToken.on([getTokenFx.doneData, refreshTokenFx.doneData, setRefreshToken], (_, data) => data.refresh_token);

export const logOut = createEvent();

$token.on([logOut, refreshTokenFx.failData], () => '');
$refreshToken.on([logOut, refreshTokenFx.failData], () => '');

sample({
  source: $token,
  filter: token => !token,
  target: redirectFx,
});

sample({ clock: returnEvent, target: goBackFx });

export const useIsAuth = () => useStore($isAuthorized);
export const useLoading = () => useStore(getTokenFx.pending);

// websocket
export const initSocket = createEvent();

const createEchoClientFx = createEffect<string, Echo, Error>({
  handler: accessToken => {
    Pusher.logToConsole = true;

    const PusherClient = new Pusher(Config.WS_KEY || '', {
      cluster: 'mt1',
      wsHost: Config.WS_HOST,
      wsPort: Number(Config.WS_PORT || 80),
      wssPort: Number(Config.WS_PORT || 443),
      enabledTransports: ['ws', 'wss'],
      authEndpoint: Config.WS_AUTH_URL || '',
      authorizer: (channel: Channel) => ({
        authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
          axios
            .post(
              Config.WS_AUTH_URL || '',
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            )
            .then(response => {
              callback(null, response.data);
            })
            .catch(error => {
              callback(error, null);
            });
        },
      }),
    });

    return new Echo({
      broadcaster: 'pusher',
      client: PusherClient,
    });
  },
});

export const $echoClient = createStore<Echo | null>(null).on(createEchoClientFx.doneData, (_, result) => result);

sample({
  clock: initSocket,
  source: $token,
  fn: (token: string) => token,
  target: createEchoClientFx,
});

export const initTestSocket = createEvent<number>();

export const updateElindex = createEvent();

export const $elIndex = createStore<number>(0).on(updateElindex, (data, _) => data + 1);

export const $activeChat = combine($chats, $elIndex, (chats, elIndex) => chats[elIndex]);

const createTestEchoClientFx = createEffect<any, Echo, Error>({
  handler: accessToken => {
    Pusher.logToConsole = true;

    const PusherClient = new Pusher(ClientWSConfig.WS_KEY || '', {
      cluster: 'mt1',
      wsHost: ClientWSConfig.WS_HOST,
      wsPort: Number(ClientWSConfig.WS_PORT || 80),
      wssPort: Number(ClientWSConfig.WS_PORT || 443),
      enabledTransports: ['ws', 'wss'],
      authEndpoint: ClientWSConfig.WS_AUTH_URL || '',
      authorizer: (channel: Channel) => ({
        authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
          if (channel.name.includes('presence')) {
            return;
          }
          const sockId = channel.name.split('.')[1];

          const token = accessToken.access_token;

          axios
            .post(
              ClientWSConfig.WS_AUTH_URL || '',
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then(response => {
              callback(null, response.data);
            })
            .catch(error => {
              callback(error, null);
            });
        },
      }),
    });

    return new Echo({
      broadcaster: 'pusher',
      client: PusherClient,
    });
  },
});

export const $echoTestClients = createStore<Echo | null>(null).on(
  createTestEchoClientFx.doneData,
  (data, result) => result,
);

sample({
  clock: initTestSocket,
  target: $elIndex,
});

sample({
  clock: initTestSocket,
  source: $activeChat,
  target: createTestEchoClientFx,
});
