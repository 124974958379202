import { separator } from './separator';
import { DateFormatEnum, timeFormat, dateFormat, getFormatDate, dateFormatChat, isSameDay } from './dateFormat';
import { throttle } from './helpers';

export const Utils = {
  separator,
  DateFormatEnum,
  timeFormat,
  dateFormat,
  getFormatDate,
  throttle,
  dateFormatChat,
  isSameDay,
};
