import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import { Order } from './types';
import { Utils } from 'src/shared/lib';
import { getFinalPrice } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';
import { renderOrderStatus } from 'src/shared/ui/renderOrderStatus';

export const mainColumnName = 'code';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<Order> = [
  {
    title: 'Заказ',
    dataIndex: 'code',
    key: 'code',
    render: (code, { id }) =>
      code ? (
        <Link to={`/order/${id}`} state={{ prevPath: true }}>
          {code}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Стоимость',
    dataIndex: 'price',
    key: 'price',
    render: data => {
      const resultPrice = getFinalPrice(data);
      return data ? resultPrice : '-';
    },
  },
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => Utils.getFormatDate(data, 'DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Количество товаров',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Статус заказа',
    dataIndex: 'status_name',
    key: 'status',
    render: data => renderOrderStatus(data),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
