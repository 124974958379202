import { createEffect, createEvent, createStore, sample } from 'effector';
import { tasksApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { IncomeTasks, Task } from './types';
import { initColumnSettings, initState } from './config';
import { Storage } from 'src/shared/lib';
import { OrderTaskStatuses } from 'src/pages/orders/ordersList/types';
import { SelectOptions } from 'src/shared/types';

export const $tasks = createStore<Paginated<Task> | null>(null);
export const $tasksStatusOptions = createStore<SelectOptions>([]);
export const $requestData = createStore<tasksApi.TasksRequestData>(initState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'latest-tasks', key: 'tableSettings' });

export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const initLatestTasksPage = createEvent<tasksApi.TasksRequestData>();
export const submitChangeRequestData = createEvent<tasksApi.TasksRequestData>();
export const initPage = createEvent();

export const getTasksFx = createEffect(tasksApi.getTasksList);
export const getTaskStatusesFx = createEffect(tasksApi.getTaskStatuses);

$tasks.on(getTasksFx.doneData, (_, data: IncomeTasks) => ({
  pagination: data.pagination,
  items: data.items.map(order => ({
    ...order,
    client: data.clients[order.client_id],
    supplier: data.suppliers[order.supplier_id],
    intermediary: data.intermediarys[order.intermediary_id],
  })),
}));
$requestData.on(submitChangeRequestData, (_, data) => data);
$tasksStatusOptions.on(getTaskStatusesFx.doneData, (_, data: OrderTaskStatuses) => {
  const statusOptions =
    data?.items.map(item => ({
      value: item?.status || item?.status === 0 ? item.status.toString() : '',
      label: item?.status_name || '',
    })) || [];
  return statusOptions;
});

sample({
  clock: initPage,
  target: getTaskStatusesFx,
});

sample({
  clock: initLatestTasksPage,
  target: getTasksFx,
});

sample({
  clock: submitChangeRequestData,
  source: $requestData,
  target: getTasksFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});
