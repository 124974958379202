import { Tag } from 'antd';
import { statusColors } from 'src/shared/config';

export const renderOrderStatus = (status: string) => {
  const colors = {
    Отменен: 'gray',
    'Не оплачен': 'red',
    Оплачен: 'green',
    'В работе': 'blue',
    Выкуплен: 'green',
    'На складе': 'brown',
    Сборка: 'blueShadow',
    'Оформление доставки': 'green',
    'В пути': 'blueShadow',
    'Доставлен в точку выдачи': 'blue',
    Получен: 'green',
    'Списание средств успешно': 'green',
    'Списание средств не удалось': 'red',
    'Ожидает курьера': 'brown',
    Отклонен: 'gray',
    Возврат: 'red',
    'Нет в наличии': 'blueShadow',
    'Возврат средств не удался': 'red',
    'Разморозка средств не удалась': 'red',
  };

  const resultColor = colors?.[status as keyof typeof colors] || '';
  const resultStyle = resultColor ? statusColors[resultColor as keyof typeof statusColors] : statusColors.default;

  return status ? <Tag style={resultStyle}>{status}</Tag> : '-';
};
