export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export enum MenuTableEnum {
  EDIT = 'edit',
  REMOVE = 'remove',
}
