import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Synonym } from 'src/pages/settings/synonyms/types';

import styles from './AddSynonym.module.less';

export interface IFormSuccess {
  word: string;
  synonym: string;
}

interface AddSynonymProps {
  title: string;
  updatedSynonym?: Synonym;
  successHandler: (value: IFormSuccess) => void;
  closeHandler: () => void;
}

export const AddSynonym = ({ title, updatedSynonym, closeHandler, successHandler }: AddSynonymProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(updatedSynonym);
  }, [form, updatedSynonym?.id]);

  const formSubmitHandler = (values: IFormSuccess) => {
    successHandler(values);
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.addSynonym}>
      <h2 className={styles.title}>{title}</h2>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={updatedSynonym}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item label="Слово" name="word" rules={[{ required: true, message: 'Пожалуйста, введите слово!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Синоним" name="synonym" rules={[{ required: true, message: 'Пожалуйста, введите синоним!' }]}>
          <Input />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
