import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';
import { NameProductCard } from 'src/entities/nameProductCard';

export interface DataType {
  id: string;
  name: string;
  category: string;
  description: string;
  price: number;
  quantity: number;
  supplier: string;
  date: Date;
  status: string;
  images: string[];
}

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'Наименование товара',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => <NameProductCard data={record} />,
  },
  {
    title: 'Категория',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Стоимость',
    dataIndex: 'price',
    key: 'price',
    render: data => (data ? `${Utils.separator(data)} ₽` : '-'),
  },
  {
    title: 'Количество',
    dataIndex: 'quantity',
    key: 'quantity',
    render: data => (data ? `${Utils.separator(data)} шт` : '-'),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Дата создания',
    dataIndex: 'date',
    key: 'date',
    render: (data: DateType) => Utils.dateFormat(data),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: data => <Tag color="blue">{data}</Tag>,
  },
];
