import React from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { loginTrigger, useLoading } from 'src/shared/api/model';

export interface IFormSuccess {
  userName: string;
  password: string;
}

interface IFormFail {
  values: IFormSuccess;
  errorFields: {
    name: (string | number)[];
    errors: string[];
  }[];
  outOfDate: boolean;
}

export const AuthForm: React.FC = () => {
  const loading = useLoading();

  const formSubmitHandler = (values: IFormSuccess): void => {
    loginTrigger(values);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={formSubmitHandler}
      autoComplete="off">
      <Form.Item label="Логин" name="userName" rules={[{ required: true, message: 'Пожалуйста, введите логин!' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Пароль"
        name="password"
        rules={[
          { required: true, message: 'Пожалуйста, введите пароль!' },
          { min: 6, message: 'Длина пароля должна составлять не менее 6 символов!' },
        ]}>
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {loading ? <Spin /> : 'Войти'}
        </Button>
      </Form.Item>
    </Form>
  );
};
