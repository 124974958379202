import { useParams } from 'react-router-dom';

import { SuppliersProfile } from '../suppliersProfile';
import { IntermediariesProfile } from '../intermediariesProfile';
import { ClientsProfile } from '../clientsProfile';
import { LegalEntitiesProfile } from '../legalEntitiesProfile';

export const Profile = () => {
  const { type, id } = useParams<{ type: string; id: string }>();

  switch (type) {
    case 'supplier':
      return <SuppliersProfile id={id} />;
    case 'intermediary':
      return <IntermediariesProfile id={id} />;
    case 'client':
      return <ClientsProfile id={id} />;
    case 'legalEntity':
      return <LegalEntitiesProfile id={id} />;
    default:
      return null;
  }
};
