export const dataMock = [
  {
    name: 'Пн',
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Вт',
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Ср',
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Чт',
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Пт',
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Сб',
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Вс',
    pv: 4300,
    amt: 2100,
  },
];
