import { useState } from 'react';
import { Card, Select, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MainButton } from 'src/shared/ui/mainButton';
import { statusOptions } from '../../config';
import { getFormattedPhone } from 'src/shared/lib';
import { IntermediaryProfile, IntermediaryStatus } from '../../types';
import { renderIntermediaryStatus } from '../../../intermediaries/config';
import { ConfirmForm } from 'src/shared/ui';

import styles from './IntermedProfilesCard.module.less';

interface IntermedProfilesCardProps {
  data: IntermediaryProfile;
  allowEdit?: boolean;
  handleChangeStatus: (value: string) => void;
  deleteProfile: () => void;
  forceDeleteProfile: () => void;
}

export const IntermedProfilesCard = ({
  data,
  allowEdit,
  handleChangeStatus,
  deleteProfile,
  forceDeleteProfile,
}: IntermedProfilesCardProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isOpenSubmit, setIsOpenSubmit] = useState(false);

  const handleEditStatus = (): void => setIsEdit(true);

  const handleSelectStatus = (value: string): void => setSelectedStatus(value);

  const handleSaveStatus = (): void => {
    if (selectedStatus) {
      handleChangeStatus(selectedStatus);
    }
    setIsEdit(false);
  };

  const confirmFullDeleting = () => {
    forceDeleteProfile();
    setIsOpenSubmit(false);
  };

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.avatar}>
            <Avatar shape="square" size={150} icon={<UserOutlined />} src={data.avatar_url} />
          </div>

          <div className={styles.body}>
            <div>
              <p className={styles.name}>{data.name || ''}</p>
              <p className={styles.data}>
                <strong>Email: </strong> {data.email || '-'}
              </p>
              <p className={styles.data}>
                <strong>Телефон: </strong> {getFormattedPhone(data.phone) || '-'}
              </p>

              <div className={styles.data}>
                <strong>Статус: </strong>
                {!isEdit ? (
                  renderIntermediaryStatus(data.status)
                ) : (
                  <Select className={styles.select} onChange={handleSelectStatus}>
                    {Object.entries(statusOptions).map(([key, value]) => (
                      <Select.Option value={key} key={key}>
                        <div className={`${styles.option} ${styles[`${key}`]}`}>{value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
            </div>
            {allowEdit &&
            data.status !== IntermediaryStatus.DELETED &&
            data.status !== IntermediaryStatus.FULL_DELETED ? (
              <div className={styles.buttons}>
                {!isEdit ? (
                  <MainButton onClick={handleEditStatus} className={styles.button}>
                    Редактировать
                  </MainButton>
                ) : (
                  <MainButton onClick={handleSaveStatus} className={styles.button}>
                    Сохранить
                  </MainButton>
                )}
                <Button danger className={styles['ban-button']} onClick={deleteProfile}>
                  Удалить профиль
                </Button>
              </div>
            ) : null}
            {allowEdit && data.status === IntermediaryStatus.DELETED ? (
              <div className={styles.buttons}>
                <Button className={styles.button} onClick={() => setIsOpenSubmit(true)}>
                  Удалить все данные
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      {allowEdit ? (
        <ConfirmForm
          isOpen={isOpenSubmit}
          title="Вы собираетесь полностью удалить данные пользователя из системы. Восстановление данных в дальнейшем невозможно. Вы действительно хотите удалить данные?"
          closeHandler={() => setIsOpenSubmit(false)}
          confirmHandler={confirmFullDeleting}
        />
      ) : null}
    </>
  );
};
