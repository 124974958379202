import { createEffect, createEvent, createStore, sample } from 'effector';
import { Product } from 'src/pages/products';
import { productsApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { initState } from './config';

export const $products = createStore<Paginated<Product> | null>(null);
export const $requestData = createStore<productsApi.ProductsListRequestData>(initState);

export const initSuppProdPage = createEvent<productsApi.ProductsListRequestData>();
export const changeRequestData = createEvent<productsApi.ProductsListRequestData>();

export const getProductsFx = createEffect(productsApi.getProductsList);

$products.on(getProductsFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);

sample({
  source: initSuppProdPage,
  target: getProductsFx,
});

sample({
  source: $requestData,
  target: getProductsFx,
});
