import { Menu } from 'antd';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

import { ProfilesIntermediaries } from './intermediaries';
import { ProfilesSuppliers } from './suppliers';
import { ProfilesClients } from './clients';
import { LegalEntities } from './legalEntities';
import styles from './Profiles.module.less';

export const ProfilesPage = () => {
  const location = useLocation();

  return (
    <section className={styles.profiles}>
      <h2 className={styles.title}>Профили</h2>

      <Menu mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
        <Menu.Item key="/profiles">
          <Link to="/profiles">Посредники</Link>
        </Menu.Item>
        <Menu.Item key="/profiles/suppliers">
          <Link to="/profiles/suppliers">Поставщики</Link>
        </Menu.Item>
        <Menu.Item key="/profiles/clients">
          <Link to="/profiles/clients">Клиенты</Link>
        </Menu.Item>
        <Menu.Item key="/profiles/legalEntities">
          <Link to="/profiles/legalEntities">Юридические лица</Link>
        </Menu.Item>
      </Menu>

      <div>
        <Routes>
          <Route path="/" element={<ProfilesIntermediaries />} />
          <Route path="/suppliers" element={<ProfilesSuppliers />} />
          <Route path="/clients" element={<ProfilesClients />} />
          <Route path="/legalEntities" element={<LegalEntities />} />
        </Routes>
      </div>
    </section>
  );
};
