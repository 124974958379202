export const typeOptions = {
  legal: 'Юридический',
  actual: 'Фактический',
  post: 'Почтовый',
  other: 'Прочий',
};

export const initLegalEntityId = '0';

export const typeSelectOptions = Object.entries(typeOptions).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const initModalState = {
  isRegistration: false,
  isDelete: false,
  isArchive: false,
  isExtract: false,
};
