import api from '../api';

export interface AddPavilionReqData {
  name: string;
  line_id: number;
}
export interface UpdatePavilionReqData {
  id: number;
  data: { name: string; line_id: number };
}

export interface getPavilionsReqData {
  query?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

export const getPavilions = (data: getPavilionsReqData) => api.post('/pavilions', data).then(response => response.data);

export const addPavilion = (data: AddPavilionReqData) => api.post('/pavilion', data).then(response => response.data);

export const updatePavilion = (data: UpdatePavilionReqData) =>
  api.put(`/pavilion/${data.id}`, data.data).then(response => response.data);

export const removePavilion = (id: number) => api.delete(`/pavilion/${id}`).then(response => response.data);
