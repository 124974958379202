import { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import {
  $product,
  $productComplaint,
  $productParsePost,
  $postDescription,
  $categories,
  $prevPath,
  initProductPage,
  getComplaintPageInit,
  getProductParsePostFx,
  submitParsePostData,
  submitProductParseFx,
  submitParsePostReqData,
  submitActualPostReqData,
  initPrevPathData,
  resetProductParsePost,
  getProductFx,
} from './model';
import { Button, Col, Row, Spin, Empty } from 'antd';
import Card from 'antd/lib/card/Card';
import { Title } from 'src/shared/ui';
import { BackArrow } from 'src/entities/backArrow';
import { ProductImages } from './ui/productImages';
import { ProductInfo } from './ui/productInfo';
import { ProductParser } from './ui/productParser';
import { ProductComplaints } from './ui/productComplaints';
import { MainModal } from 'src/shared/ui';
import { Hooks } from 'src/shared/lib';
import { initState } from './config';
import styles from './Product.module.less';

export const Product = () => {
  const { id } = useParams<{ id: string }>();
  const prevPathData = useLocation().state as { prevPath: string };
  const prevPath = useStore($prevPath);
  const product = useStore($product);
  const productComplaints = useStore($productComplaint);
  const productParsePost = useStore($productParsePost);
  const postDescription = useStore($postDescription);
  const categories = useStore($categories);
  const isLoading = useStore(getProductFx.pending);
  const isParserLoading = useStore(getProductParsePostFx.pending);
  const isParseLoading = useStore(submitProductParseFx.pending);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    if (id) {
      initProductPage(id);
      getComplaintPageInit({ id, data: initState });
      resetProductParsePost();
      if (prevPathData) {
        initPrevPathData(prevPathData.prevPath);
      }
    }
  }, [id]);

  useEffect(() => {
    const { prodId } = searchParams;
    if (prodId) {
      submitParsePostReqData(prodId);
      handleModal(true);
    }
  }, [searchParams]);

  const handleChangePage = (value: number, size: number) => {
    if (id) {
      getComplaintPageInit({ id, data: { pagination: { page: value, per_page: size } } });
    }
  };

  const handleModal = (value: boolean) => setIsModalOpen(value);

  const handleParser = () => setSearchParams({ ...searchParams, prodId: id || '' });

  const handleParsePost = () => {
    if (productParsePost?.post?.id) {
      submitParsePostData(productParsePost.post.id);
    }
  };

  const handleActualPost = () => {
    if (productParsePost?.post?.id) {
      submitActualPostReqData(productParsePost.post.id);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.product}>
      {prevPath ? <BackArrow prevPath={prevPath} /> : null}

      {product ? (
        <>
          <div className={styles.header}>
            <Title>{product.name}</Title>
          </div>
          <div className={styles.body}>
            <Button className={styles.edit}>
              <Link to={`/products/edit/${id}`}>Редактировать</Link>
            </Button>
            <Row>
              <Col span={8}>
                <Card>
                  <ProductImages images={product.images} />
                </Card>
              </Col>
              <Col span={16}>
                <Card>
                  <ProductInfo product={product} />
                  <Button loading={isParseLoading} type="primary" onClick={handleParser}>
                    Парсер
                  </Button>
                  {productComplaints && productComplaints?.items.length ? (
                    <ProductComplaints productComplaints={productComplaints} handleChangePage={handleChangePage} />
                  ) : null}
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <Empty description={<span className={styles.empty}>Товар не найден</span>} />
      )}
      <MainModal
        width="95%"
        isOpen={isModalOpen}
        closeHandler={() => {
          handleModal(false);
          setSearchParams({ ...searchParams, prodId: '' });
        }}>
        <ProductParser
          productId={id || null}
          data={productParsePost}
          description={postDescription}
          categories={categories}
          handleParsePost={handleParsePost}
          handleActualPost={handleActualPost}
          isLoading={isParserLoading}
          closeHandler={handleModal}
          isParseLoading={isParseLoading}
        />
      </MainModal>
    </div>
  );
};
