export const statusOptions = {
  1: 'Не оплачен',
  2: 'Оплачен',
  3: 'В работе',
  4: 'Выкуплен',
  5: 'На складе',
  6: 'Сборка',
  7: 'Оформление доставки',
  8: 'В пути',
  9: 'Доставлен в точку выдачи',
  10: 'Получен',
  11: 'Списание средств успешно',
  12: 'Списание средств не удалось',
  13: 'Ожидает курьера',
  14: 'Отклонен',
  15: 'Возврат',
  16: 'Нет в наличии',
  18: 'Возврат средств не удался',
  19: 'Разморозка средств не удалась',
  0: 'Отменен',
};

export const notAllowedToCancelStatuses = [0, 10, 14, 15, 16];
export const allowedToChangeWeightStatuses = [5, 6, 11];

export const filteredTransType = 'notification';

export const transactionTypeOptions = {
  Init: 'Создание платежа',
  Finish: 'Подтверждение платежа передачей реквизитов и списание/блокировка средств',
  Confirm: 'Подтверждение платежа и списание ранее заблокированные средства',
  Cancel: 'Отмена платежа',
  Status: 'Получение текущего статуса платежа',
  Charge: 'Выполнение повторного платежа',
};

export const transactionStatusOptions = {
  NEW: 'Создан',
  FORM_SHOWED: 'Платежная форма открыта покупателем',
  DEADLINE_EXPIRED: 'Платежная сессия закрыта в связи с превышением срока отсутствия активности по текущему статусу',
  CANCELED: 'Отменен',
  PREAUTHORIZING: 'Проверка платежных данных',
  AUTHORIZING: 'Резервируется',
  AUTH_FAIL: 'Не прошел авторизацию',
  REJECTED: 'Отклонен',
  '3DS_CHECKING': 'Проверяется по протоколу 3-D Secure',
  '3DS_CHECKED': 'Проверен по протоколу 3-D Secure',
  PAY_CHECKING: 'Платеж обрабатывается',
  AUTHORIZED: 'Зарезервирован',
  REVERSING: 'Резервирование отменяется',
  PARTIAL_REVERSED: 'Резервирование отменено частично',
  REVERSED: 'Резервирование отменено',
  CONFIRMING: 'Подтверждается',
  CONFIRM_CHECKING: 'Платеж обрабатывается',
  CONFIRMED: 'Подтвержден',
  REFUNDING: 'Возвращается',
  PARTIAL_REFUNDED: 'Возвращен частично',
  REFUNDED: 'Возвращен полностью',
};
