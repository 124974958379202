import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { apiModel } from 'src/shared/api';
import { useIsAdmin } from 'src/widgets/header';

import styles from './Menu.module.less';

const getPathLocation = (str: string): string => {
  const firstPath = str.split('/')?.[1] || '';

  if (firstPath === 'profile') {
    return 'profiles';
  }
  if (firstPath === 'order' || firstPath === 'task') {
    return 'orders';
  }
  return firstPath;
};

export const MenuComponent = () => {
  const location = useLocation();
  const pathLocation = getPathLocation(location.pathname);
  const isAuth = apiModel.useIsAuth();
  const isAdmin = useIsAdmin();

  return isAuth ? (
    <Menu mode="horizontal" className={styles.headerMenu} selectedKeys={[`/${pathLocation}`]}>
      {isAdmin ? (
        <Menu.Item key="/products">
          <Link to="/products">Товары</Link>
        </Menu.Item>
      ) : null}
      {isAdmin ? (
        <Menu.Item key="/profiles">
          <Link to="/profiles">Профили</Link>
        </Menu.Item>
      ) : null}
      {isAdmin ? (
        <Menu.Item key="/payments">
          <Link to="/payments">Платежи</Link>
        </Menu.Item>
      ) : null}
      <Menu.Item key="/orders">
        <Link to="/orders">Заказы</Link>
      </Menu.Item>
      <Menu.Item hidden key="/analytics">
        <Link to="/analytics">Аналитика</Link>
      </Menu.Item>
      <Menu.Item hidden key="/advertising">
        <Link to="/advertising">Реклама</Link>
      </Menu.Item>
      <Menu.Item hidden key="/reviews">
        <Link to="/reviews">Отзывы</Link>
      </Menu.Item>
      {isAdmin ? (
        <Menu.Item key="/employees">
          <Link to="/employees">Сотрудники</Link>
        </Menu.Item>
      ) : null}
      <Menu.Item key="/stock">
        <Link to="/stock">Склад</Link>
      </Menu.Item>
      {isAdmin ? (
        <Menu.Item key="/chat">
          <Link to="/chat">Чаты</Link>
        </Menu.Item>
      ) : null}
      {isAdmin ? (
        <Menu.Item key="/settings">
          <Link to="/settings">Настройки</Link>
        </Menu.Item>
      ) : null}
    </Menu>
  ) : null;
};
