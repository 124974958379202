import { DateType } from 'src/shared/ui';

export type ClientStatusOptions = [['active', 'Активный'], ['ban', 'Бан']];
export type IntermedStatusOptions = [['approve', 'Подтвержден'], ['reject', 'Не одобрен']];
export type StatusOptions = ClientStatusOptions | IntermedStatusOptions;

export interface ChatTask {
  id: number;
  code: string;
  status_name: string;
  count: number;
  received_count: number;
  comment: string;
  product_offer_id: number;
  product_offer_name: string;
  product_offer_image: string;
  intermediary_id: number;
  client_id: number;
  supplier_pavilion_id: number;
  created_at: DateType;
  price: number;
  amount: number;
  take_in_work_date: DateType;
}

export type ClientStatuses = 'NOT_REGISTERED' | 'ACTIVE' | 'BANNED' | 'DELETED' | 'FULL_DELETED';
export type IntermedStatuses = 'APPROVED' | 'REJECTED' | 'CHECKING' | 'DELETED' | 'FULL_DELETED';
export type ProfileStatusesFiltered = JSX.Element | '';

export enum chatUsersEnum {
  CLIENT = 'CLIENT',
  USER = 'USER',
  INTERMEDIARY = 'INTERMEDIARY',
  SUPPLIER = 'SUPPLIER',
  SELLER = 'SELLER',
}

export interface ChatClient<T> {
  id: number;
  name: string;
  email: string;
  phone: string;
  avatar_url: string;
  status: T;
  originalStatus?: ClientStatuses;
  created_at: DateType;
}

export interface ChatIntermed<T> {
  id: number;
  name: string;
  email: string;
  phone: string;
  avatar_url: string;
  pass_number: string;
  status: T;
  originalStatus?: IntermedStatuses;
  created_at: DateType;
}

export interface ChatInfoFiltered {
  task: ChatTask;
  clients: ChatClient<ProfileStatusesFiltered>[];
  intermediaries: ChatIntermed<ProfileStatusesFiltered>[];
  characteristic: Characteristic;
}

export interface Characteristic {
  value: string;
  code: 'size';
}

export interface ChatInfo {
  chat: {
    id: number;
    members: [
      {
        member: {
          id: number;
          user_type: chatUsersEnum;
          user_id: number;
          name: string;
          avatar: string;
        };
      },
    ];
  };
  clients: { [index: number]: ChatClient<ClientStatuses> };
  intermediaries: { [index: number]: ChatIntermed<IntermedStatuses> };
  task: ChatTask;
  offer: {
    characteristics: Characteristic[];
  };
}

export interface AttachmentCountType {
  type: 'image';
  type_name: string;
  count: number;
  months: {
    year: number;
    month: number;
    count: number;
  }[];
}

export interface AttachmentCount {
  types: AttachmentCountType[];
}

interface Image {
  id: number;
  type: 'image';
  url: string;
}

export interface ImagesData {
  items: Image[];
  pagination: {
    per_page: number;
    current_page: number;
    total: number;
    last_page: number;
  };
}

export interface resultImagesData {
  images: { [index: string]: Image[] }[];
  totalCount: number;
  currentPage: number;
  lastPage: number;
  totalPages: number;
}

export type chatUsers = 'client' | 'intermediary';
