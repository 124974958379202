import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';
import { getFinalPrice } from 'src/shared/lib';
import { getFormattedPhone } from 'src/shared/lib';
import { renderOrderStatus } from 'src/shared/ui/renderOrderStatus';
import { IOrder } from './types';

export const debitResponseStatuses = {
  debitSuccessStatus: 11,
  debitFailStatuses: [12, 18],
};

export const getDebitMessage = (status: number) => {
  switch (status) {
    case 12:
      return 'Списание средств не удалось';
    case 18:
      return 'Возврат средств не удался';
    default:
      return 'Ошибка при попытке списания средств';
  }
};

export const mainColumnName = 'code';

export const printStatus = 7;

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const renderColumns = ({
  setOrderId
}: {
  setOrderId?: (value: string) => void;
}) => {

  const columns: ColumnsType<IOrder> = [
    {
      title: 'Заказ',
      dataIndex: 'code',
      key: 'code',
      onCell: ({ id }) => ({
        onClick: () => setOrderId?.(String(id)),
        style: {
          cursor: 'pointer'
        }
      }),
      render: (code) =>
        code || '-',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      render: data => {
        const resultPrice = getFinalPrice(data);
        return data ? resultPrice : '-';
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      render: data =>
        data ? (
          <Link to={`/profile/client/${data.id}`} state={{ prevPath: true }}>
            {data.name}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, { client }) => getFormattedPhone(client.phone) || '-',
    },
    {
      title: 'Дата заказа',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data: DateType) => Utils.dateFormat(data),
    },
    {
      title: 'Статус заказа',
      dataIndex: 'status_name',
      key: 'status',
      render: data => renderOrderStatus(data),
    },
  ];

  return columns;
}



export const initColumnSettings = renderColumns({}).reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
