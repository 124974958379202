import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $product, initEditProductPage, getProductFx } from './model';
import { Col, Row, Spin } from 'antd';
import {
  AddProductSteps,
  AddProductInfo,
  AddProductOptions,
  AddProductFaq,
  AddProductRequirements,
  AddProductCharacteristics,
} from 'src/entities/addProduct';
import styles from './AddProduct.module.less';

export const AddProduct = () => {
  const { id } = useParams<{ id: string }>();
  const [step, setStep] = useState(0);
  const product = useStore($product);
  const isLoading = useStore(getProductFx.pending);

  useEffect(() => {
    if (id) {
      initEditProductPage(id);
    }
  }, [id]);

  const next = () => {
    if (step > 2) return;
    setStep(step + 1);
  };

  const prev = () => {
    if (step === 0) return;
    setStep(step - 1);
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <section className={styles.addProduct}>
      <h2 className={styles.title}>{id ? 'Редактирование товара' : 'Добавление товара'}</h2>

      <Row className={styles.body}>
        <Col span={5}>
          {!id ? (
            <div className={styles.column}>
              <AddProductSteps step={step} />
            </div>
          ) : null}
        </Col>
        <Col span={14}>
          <div className={styles.column}>
            {step === 0 && <AddProductInfo id={id} next={next} product={product} />}
            {step === 1 && <AddProductCharacteristics prev={prev} next={next} />}
            {step === 2 && <AddProductOptions prev={prev} />}
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.column}>
            <div className={styles.faq}>
              <AddProductFaq />
            </div>
            {!id ? <AddProductRequirements /> : null}
          </div>
        </Col>
      </Row>
    </section>
  );
};
