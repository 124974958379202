import { Table, Empty, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NumberTypeEnum } from 'src/shared/types';
import { Hooks } from 'src/shared/lib';

import { renderStatus } from '../../helpers';
import { DataType, PER_PAGE, statusOptions } from './config';
import styles from './ParserLogsTable.module.less';

interface IProductsTableProps {
  data: DataType[];
  isLoading: boolean;
  pagination?: { current_page: number; total: number; per_page: number };
  handleSearchId: (value: string) => void;
  handleChangePage: (value: number, size: number) => void;
  handleChangePageSize: (currentSize: number, size: number) => void;
  setLogId: (id: string) => void;
  isActiveFilter: boolean;
  setIsActiveFilter: (value: boolean) => void;
  handleSelectStatus: (value: string[]) => void;
}

export const ParserLogsTable = ({
  data,
  pagination,
  isLoading,
  handleChangePage,
  handleChangePageSize,
  handleSearchId,
  setLogId,
  isActiveFilter,
  setIsActiveFilter,
  handleSelectStatus,
}: IProductsTableProps) => {
  const getProductIdSearch = Hooks.useColumnSearch<DataType>(
    handleSearchId,
    NumberTypeEnum.COUNT,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<DataType>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      key: 'id',
      render: data => (
        <Button
          type="link"
          onClick={() => {
            setLogId(data.id);
          }}>
          {data.id}
        </Button>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: data => (data || data === 0 ? renderStatus(data) : '-'),
    },
    {
      title: 'ID товара',
      dataIndex: 'product_id',
      key: 'product_id',
      render: data => data || '-',
    },
  ];

  const filteredColumns: ColumnsType<DataType> = columns.map(column => {
    switch (column.key) {
      case 'product_id':
        return { ...column, ...getProductIdSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey={record => record.id}
        loading={isLoading}
        columns={filteredColumns}
        dataSource={data}
        pagination={{
          onChange: handleChangePage,
          onShowSizeChange: handleChangePageSize,
          current: pagination?.current_page || 1,
          pageSize: pagination?.per_page || PER_PAGE,
          total: pagination?.total || 0,
          showSizeChanger: true,
        }}
        locale={{ emptyText: <Empty description="Нет данных" /> }}
      />
    </div>
  );
};
