import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $chats, submitAmountData, $subData, getChatsDataFx } from './model';
import { Form, Input, Button, Card, Spin } from 'antd';
import { Title } from 'src/shared/ui';
import { CaptchaTypes, CaptchaSettingsKeys } from './types';

import { initTestSocket, $echoTestClients, $activeChat, $elIndex, updateElindex } from 'src/shared/api/model';
import { Hooks } from 'src/shared/lib/hooks';
import styles from './ChatTest.module.less';

export const ChatTest = () => {
  const [form] = Form.useForm();
  const chats = useStore($chats);
  const elIndex = useStore($elIndex);
  const data = useStore($echoTestClients);
  const isLoading = useStore(getChatsDataFx.pending);
  const [resultData, setResultData] = useState<any[]>([]);

  useEffect(() => {
    data?.connector.pusher.connection.bind('connected', () => {
      setResultData([...resultData, { id: elIndex + 1, socket: 'success' }]);
    });
  }, [data?.connector.pusher.connection, elIndex, resultData]);

  data?.connector.pusher.subscribe(`private-client.${chats[elIndex]?.id}`).bind('pusher:subscription_succeeded', () => {
    const filteredData = resultData.map(item => {
      if (item.id === elIndex + 1) {
        return { ...item, subscription: 'success' };
      }
      return item;
    });
    setResultData(filteredData);

    if (chats?.length > elIndex + 1) {
      initTestSocket(elIndex + 1);
    }
  });

  data?.connector.pusher.subscribe(`private-client.${chats[elIndex]?.id}`).bind('pusher:subscription_error', () => {
    const filteredData = resultData.map(item => {
      if (item.id === elIndex + 1) {
        return { ...item, subscription: 'error' };
      }
      return item;
    });
    setResultData(filteredData);

    if (chats?.length > elIndex + 1) {
      initTestSocket(elIndex + 1);
    }
  });

  const formSubmitHandler = (values: { amount: string }) => {
    submitAmountData(values.amount);
  };

  useEffect(() => {
    if (chats?.length) {
      initTestSocket(0);
    }
  }, [chats]);

  Hooks.useTestEchoNewMessage(chats);

  return (
    <div className={styles.captcha}>
      <Card>
        <div className={styles.flex}>
          <div className={styles.flexItem}>
            <Title>Тестирование чата</Title>

            <Form
              form={form}
              className={styles.form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={formSubmitHandler}
              autoComplete="off">
              <Form.Item label="Количество" name="amount">
                <Input type="number" min={0} />
              </Form.Item>

              <Form.Item className={styles.button}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Сгенерировать
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className={`${styles.flexItem} ${styles.flexItemBlock}`}>
            <div className={styles.grid}>
              <p>
                <b>номер подключения</b>
              </p>
              <p>
                <b>успешность соединения с сокетом</b>
              </p>
              <p>
                <b>успешность подписки на канал</b>
              </p>
            </div>
            {resultData.length
              ? resultData.map(item => (
                  <div className={styles.grid}>
                    <p>{item.id}</p>
                    <p>{item.socket}</p>
                    <p>{item.subscription}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </Card>
    </div>
  );
};
