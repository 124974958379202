import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Button, Empty, Pagination, Spin } from 'antd';
import { MainModal } from 'src/shared/ui/mainModal';
import { UpdateSource } from 'src/entities/updateSource';
import { $sources, getSourcesFx, initSuppParserPage, clearSources } from './model';
import { DetailedSource } from './ui/suppParserList/SuppParserList';
import { initState, PER_PAGE } from './config';
import { SuppParserList, AddSource } from './ui';
import styles from './SuppParser.module.less';

export interface Pavilion {
  id: number;
  name: string;
  line_id?: number;
}

interface SuppParserProps {
  id?: string;
  pavilions?: { id: number; pavilion: Pavilion }[];
}

export const SuppParser = ({ id, pavilions }: SuppParserProps) => {
  const sources = useStore($sources);
  const [activeSource, setActiveSource] = useState<DetailedSource | null>(null);
  const [modalsState, setModalsState] = useState<{ isAdd: boolean; isUpdate: boolean }>({
    isAdd: false,
    isUpdate: false,
  });
  const isLoading = useStore(getSourcesFx.pending);

  useEffect(() => {
    clearSources();
    if (pavilions?.length) {
      const pavilionsIds = pavilions?.map(({ id }) => id) || [];
      initSuppParserPage({ supplier_pavilion_ids: pavilionsIds, ...initState });
    }
  }, [pavilions]);

  const handleOpenAdd = () => setModalsState({ ...modalsState, isAdd: true });

  const handleCloseAdd = () => setModalsState({ ...modalsState, isAdd: false });

  const handleCloseUpdate = () => setModalsState({ ...modalsState, isUpdate: false });

  const handleChangePage = (value: number, size: number) => {
    const pavilionsIds = pavilions?.map(({ id }) => id) || [];
    initSuppParserPage({ supplier_pavilion_ids: pavilionsIds, pagination: { page: value, per_page: size } });
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.suppParser}>
      <div className={styles.wrapper}>
        <div className={styles.addSource}>
          <Button htmlType="button" onClick={handleOpenAdd}>
            Добавить источник
          </Button>
        </div>
        <div>
          {sources?.items.length ? (
            <>
              <div className={styles.sources}>
                {sources?.items.map(source => (
                  <SuppParserList
                    key={source.id}
                    source={source}
                    sources={sources}
                    categories={sources?.categories}
                    pavilions={sources?.supplier_pavilions}
                    setModalsState={setModalsState}
                    setActiveSource={setActiveSource}
                  />
                ))}
              </div>
              <Pagination
                className={styles.pagination}
                showSizeChanger
                onChange={handleChangePage}
                current={sources?.pagination?.current_page || 1}
                pageSize={sources?.pagination?.per_page || PER_PAGE}
                total={sources?.pagination?.total || 0}
              />
            </>
          ) : (
            <Empty description="Нет источников" />
          )}
        </div>
      </div>

      {pavilions && (
        <MainModal isOpen={modalsState.isAdd} closeHandler={handleCloseAdd}>
          <AddSource id={id} closeHandler={handleCloseAdd} />
        </MainModal>
      )}

      <MainModal isOpen={modalsState.isUpdate} closeHandler={handleCloseUpdate}>
        <UpdateSource
          id={id}
          closeHandler={handleCloseUpdate}
          activeSource={activeSource}
          supPavilions={sources?.supplier_pavilions}
        />
      </MainModal>
    </div>
  );
};
