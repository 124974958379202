const ms = 1000;
const secs = 60;
const msInHour = 3600000;
const dataTo = 24 * msInHour - 1;

export const getIsoTimeZoneTime = (value: { day: string; time?: string }, dateType: 'to' | 'from') => {
  const date = new Date(value?.day);
  let dayTime = 0;
  if (value?.time) {
    const [hours, minutes, seconds] = value.time.split(':');
    dayTime = Number(hours) * secs * secs + Number(minutes) * secs + Number(seconds);
  }
  const time = date.getTime() + date.getTimezoneOffset() * secs * ms + dayTime * ms;
  const timeWithDataTo = time + dataTo;
  const msDate = date.setTime(dateType === 'from' ? time : timeWithDataTo);
  return new Date(msDate).toISOString();
};
