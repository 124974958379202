import { forwardRef } from 'react';
import { Dayjs } from 'dayjs';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';

import { DatePicker } from './DatePicker';

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> { }

const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export { TimePicker };