import { createEffect, createEvent, createStore, sample } from 'effector';
import { Product } from 'src/pages/products';
import { productsApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { setSuccess, setError } from 'src/features/notifications';
import { Storage } from 'src/shared/lib';

import { initState, PER_PAGE } from './config';

export const $activeProducts = createStore<Paginated<Product> | null>(null);
export const $requestData = createStore<productsApi.ProductsListRequestData>(initState);
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'products', key: 'pageSize' });

export const submitChangeRequestData = createEvent<productsApi.ProductsListRequestData>();
export const submitDeactivateData = createEvent<productsApi.ActivityProductReqData>();
export const changePageSize = createEvent<number>();

export const getProductsFx = createEffect(productsApi.getProductsList);
export const deactivateProductFx = createEffect(productsApi.deactivateProduct);

$requestData.on(submitChangeRequestData, (_, data) => data);
$activeProducts.on(getProductsFx.doneData, (_, data) => data);

sample({
  clock: submitDeactivateData,
  target: deactivateProductFx,
});

sample({
  source: $requestData,
  fn: data => {
    if (!data.name) {
      const { name, ...searchObj } = data;
      return searchObj;
    }
    return data;
  },
  target: getProductsFx,
});

sample({
  clock: deactivateProductFx.doneData,
  source: $requestData,
  target: getProductsFx,
});

sample({
  clock: deactivateProductFx.failData,
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: deactivateProductFx.doneData,
  fn: () => 'Товар успешно деактивирован',
  target: setSuccess,
});

// change page size

sample({
  clock: changePageSize,
  target: $pageSize,
});
