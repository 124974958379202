export const initLegalState = {
  registered: true,
  pagination: {
    per_page: 10,
    page: 1,
  },
};

export const initSuppState = {
  legal_entity_id: null,
  pagination: {
    per_page: 10,
    page: 1,
  },
};
