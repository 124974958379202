import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $isSizesUpdated, $sizeValues, initSizePage, submitEditCategoryChar, resetIsSizesUpdated } from './model';
import { Form, Button, Checkbox } from 'antd';
import { InputTag } from 'src/shared/ui/inputTag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { columnWidth, initState, emptyValue } from './config';
import { ConfirmForm } from 'src/shared/ui';
import { defaultSelectValue } from 'src/shared/config';
import { Category } from 'src/shared/api/types';
import styles from './EditSizes.module.less';

interface EditSizesProps {
  category: Category | null;
  closeHandler: () => void;
  handleActualPost?: () => void;
}

export const EditSizes = ({ category, closeHandler, handleActualPost }: EditSizesProps) => {
  const [form] = Form.useForm();
  const [sizes, setSizes] = useState<string[]>();
  const [isCancel, setIsCancel] = useState(false);
  const [isEmptyChecked, setIsEmptyChecked] = useState(false);
  const isUpdated = useStore($isSizesUpdated);
  const sizeValues = useStore($sizeValues);

  useEffect(() => {
    if (category?.id) {
      initSizePage({ ...initState, filters: { category_id: category.id } });
    }
  }, [form, category]);

  useEffect(() => {
    form.resetFields();
    setDefaultFields();
  }, [form, sizeValues]);

  useEffect(() => {
    if (isUpdated && handleActualPost) {
      handleActualPost();
      resetIsSizesUpdated();
    }
  }, [isUpdated, handleActualPost]);

  const formSubmitHandler = () => {
    const resultValues = isEmptyChecked ? [emptyValue] : sizes || [];
    if (category?.id) {
      submitEditCategoryChar({
        ...initState,
        id: category.id,
        data: {
          values: resultValues,
        },
      });
    }
    closeHandler();
    form.resetFields();
    setSizes([]);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
    setIsCancel(false);
    setDefaultFields();
  };

  const setDefaultFields = () => {
    const isEmptySizes = sizeValues?.includes(emptyValue);
    const sizes = isEmptySizes ? [] : sizeValues || [];
    setIsEmptyChecked(isEmptySizes || false);
    setSizes(sizes);
  };

  const handleMasks = (values: string[]) => setSizes(values);

  const handleCheckbox = (e: CheckboxChangeEvent) => setIsEmptyChecked(e.target.checked);

  return (
    <div className={styles.editSizes}>
      {category?.name ? <h2 className={styles.title}>{category.name} - размеры</h2> : null}
      <div className={styles.check}>
        <Checkbox checked={isEmptyChecked} onChange={handleCheckbox} />
        <p>Без размера</p>
      </div>
      <Form
        form={form}
        name="basic"
        labelCol={columnWidth}
        wrapperCol={columnWidth}
        initialValues={defaultSelectValue}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item hidden={isEmptyChecked} className={styles.sizes} label="" name="sizes">
          <InputTag masks={sizes || []} handleMasks={handleMasks} />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Сохранить
            </Button>
          </Form.Item>

          <Button onClick={() => setIsCancel(true)} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>

      <ConfirmForm
        isOpen={isCancel}
        title="Вы уверены, что хотите закрыть форму? Несохранённые данные будут потеряны"
        closeHandler={() => setIsCancel(false)}
        confirmHandler={handleCancelClose}
      />
    </div>
  );
};
