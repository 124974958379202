export enum CommissionSettingsKeys {
  IntCommission = 'intermediary_comission',
  ServiseCommission = 'service_comission',
  BankCommission = 'bank_commission_percent',
  BankMinAmountCommission = 'bank_commission_min_amount',
  Ffd_version = 'ffd_version',
}

export interface CommissionTypes {
  intermediary_comission: string;
  service_comission: string;
  bank_commission_percent: string;
  bank_commission_min_amount: string;
  ffd_version: string;
}

export interface Commission {
  settings: {
    id: number;
    key: CommissionSettingsKeys;
    value: string;
  }[];
}

export interface resultCommission {
  [key: string]: string | number;
}
