import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Title } from 'src/shared/ui';
import { Form, Input, Button, Card, Spin, Select } from 'antd';
import { CommissionTypes, CommissionSettingsKeys } from './types';
import { $commission, initCommissionPage, submitUpdateCommissionData, getCommissionFx } from './model';
import { ffdSelectOptions } from './config';
import { trimNullsFromString } from 'src/shared/lib';
import styles from './Commission.module.less';

export const Commission = () => {
  const [form] = Form.useForm();

  const commission = useStore($commission);
  const isLoading = useStore(getCommissionFx.pending);

  const formSubmitHandler = (values: CommissionTypes): void => {
    submitUpdateCommissionData({
      settings: [
        {
          key: CommissionSettingsKeys.IntCommission,
          value: trimNullsFromString(values.intermediary_comission),
        },
        {
          key: CommissionSettingsKeys.ServiseCommission,
          value: trimNullsFromString(values.service_comission),
        },
        {
          key: CommissionSettingsKeys.BankCommission,
          value: trimNullsFromString(values.bank_commission_percent),
        },
        {
          key: CommissionSettingsKeys.BankMinAmountCommission,
          value: trimNullsFromString((Number(values.bank_commission_min_amount) * 100).toString()),
        },
        {
          key: CommissionSettingsKeys.Ffd_version,
          value: values.ffd_version,
        },
      ],
    });
  };

  useEffect(() => {
    initCommissionPage();
  }, []);

  useEffect(() => {
    form.setFieldsValue(commission);
  }, [form, commission]);

  return (
    <div className={styles.commission}>
      <Card>
        <Title>Установка комиссии</Title>

        {isLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={form}
            className={styles.form}
            name="basic"
            id="commissionForm"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={formSubmitHandler}
            autoComplete="off">
            <Form.Item
              label="Комиссия для посредников, %"
              name="intermediary_comission"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите комиссию посредника',
                },
              ]}>
              <Input type="number" min={0} max={100} step={0.01} />
            </Form.Item>
            <Form.Item
              label="Комиссия для сервиса, %"
              name="service_comission"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите комиссию сервиса',
                },
              ]}>
              <Input type="number" min={0} max={100} step={0.01} />
            </Form.Item>
            <Form.Item
              label="Комиссия банка, %"
              name="bank_commission_percent"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите комиссию банка',
                },
              ]}>
              <Input type="number" min={0} max={100} step={0.01} />
            </Form.Item>
            <Form.Item
              label="Минимальная сумма комиссии банка, ₽"
              name="bank_commission_min_amount"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите минимальную сумму комиссии банка',
                },
              ]}>
              <Input type="number" min={0} step={0.01} />
            </Form.Item>
            <Form.Item label="Версия ФФД" name="ffd_version">
              <Select options={ffdSelectOptions} placeholder="Выберите версию ФФД" />
            </Form.Item>
            <Form.Item className={styles.button}>
              <Button disabled={!commission} htmlType="submit" type="primary">
                Сохранить изменения
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
};
