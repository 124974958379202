import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample } from 'effector';
import { commissionApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { Settings, CaptchaSettingsKeys, resultCaptcha } from './types';

export const $captchaSettings = createStore<resultCaptcha | null>(null);

export const initCaptchaPage = createEvent();
export const submitUpdateSettingsData = createEvent<commissionApi.UpdateSettingsReqData>();

export const getSettingsFx = createEffect(commissionApi.getSettings);
export const updateSettingsFx = createEffect<
  commissionApi.UpdateSettingsReqData,
  null,
  AxiosError<{ message: string }>
>(commissionApi.updateSettings);

$captchaSettings.on(getSettingsFx.doneData, (_, data: Settings) => {
  const captchaValues = Object.values(CaptchaSettingsKeys);
  const resultData = data?.settings
    ? data.settings.reduce((acc, { key, value }) => {
        if (captchaValues.includes(key || '')) {
          acc = { ...acc, [key]: value || '' };
        }
        return acc;
      }, {} as resultCaptcha)
    : {};
  return resultData;
});

sample({
  clock: initCaptchaPage,
  target: getSettingsFx,
});

sample({
  clock: submitUpdateSettingsData,
  target: updateSettingsFx,
});

sample({
  clock: updateSettingsFx.doneData,
  target: getSettingsFx,
});

sample({
  clock: updateSettingsFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении настроек капчи',
  target: setError,
});

sample({
  clock: updateSettingsFx.doneData,
  fn: () => 'Капча успешно изменена',
  target: setSuccess,
});
