import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { categoriesApi, imageApi } from 'src/shared/api';

import { Category, CategoryFormData, TreeData } from './types';

const transformTree = (list: Category, level: number): TreeData => ({
  ...list,
  title: list.name || '',
  value: list.id,
  children: level < 1 ? list.children?.map(item => transformTree(item, level + 1)) : [],
});

export const $categories = createStore<Category[]>([]);
export const $treeData = $categories.map(categories => categories.map(item => transformTree(item, 0)));
export const $isLoading = createStore<boolean>(false);

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const deleteCategoryFx = createEffect<number, null, AxiosError<{ message: string }>>(
  categoriesApi.deleteCategory,
);
export const editCategoryFx = createEffect(categoriesApi.editCategory);
export const createCategoryFx = createEffect(categoriesApi.createCategory);
export const getFileFx = createEffect(imageApi.getFileFromUrl);

export const initCategoriesPage = createEvent();
export const deleteCategory = createEvent<number>();
export const submitEditCategory = createEvent<CategoryFormData>();
export const submitCreationCategory = createEvent<CategoryFormData>();
export const loadingChange = createEvent<boolean>();

sample({
  clock: initCategoriesPage,
  target: getCategoriesFx,
});

sample({
  clock: deleteCategory,
  target: deleteCategoryFx,
});

sample({
  clock: deleteCategoryFx.doneData,
  fn: () => 'Категория удалена',
  target: [setSuccess, initCategoriesPage],
});

sample({
  clock: deleteCategoryFx.failData,
  fn: () => 'Невозможно удалить категорию. Необходимо изменить привязку источников, использующих эту категорию',
  target: setError,
});

sample({
  clock: deleteCategoryFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении категории',
  target: setError,
});

sample({
  clock: submitCreationCategory,
  target: createCategoryFx,
});

sample({
  clock: createCategoryFx.doneData,
  fn: () => 'Категория создана',
  target: [setSuccess, initCategoriesPage],
});

sample({
  clock: createCategoryFx.fail,
  fn: () => 'Ошибка создания категории',
  target: setError,
});

sample({
  clock: submitEditCategory,
  target: editCategoryFx,
});

sample({
  clock: editCategoryFx.doneData,
  fn: () => 'Категория изменена',
  target: [setSuccess, initCategoriesPage],
});

sample({
  clock: editCategoryFx.fail,
  fn: () => 'Ошибка изменения категории',
  target: setError,
});

sample({
  source: [getCategoriesFx.pending, createCategoryFx.pending, editCategoryFx.pending, deleteCategoryFx.pending],
  fn: data => data.reduce((prev, curr) => prev || curr, false),
  target: loadingChange,
});

$categories.on(getCategoriesFx.doneData, (_, data) => data.items);
$isLoading.on(loadingChange, (_, data) => data);
