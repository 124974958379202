import { notification } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { $error, $success, setError, setSuccess } from '../model';

export const NotificationItem = () => {
  const errorText = useStore($error);
  const successText = useStore($success);

  useEffect(() => {
    if (successText) {
      notification.success({
        message: successText,
        style: {
          backgroundColor: '#ffffff',
        },
        key: successText,
        onClose: () => {
          setSuccess('');
        },
      });
    }
  }, [successText]);

  useEffect(() => {
    if (errorText) {
      notification.error({
        message: errorText,
        key: errorText,
        style: {
          backgroundColor: '#fff2f0',
        },
        onClose: () => {
          setError('');
        },
      });
    }
  }, [errorText]);

  return <div />;
};
