import { useEffect, useState } from 'react';
import {
  $categories,
  $pavilionsOptions,
  initAddSourcePage,
  initPagePavilions,
  submitAddSource,
  getCategoriesFx,
} from './model';
import { useStore } from 'effector-react';
import { Form, Input, Button, Checkbox, TreeSelect, AutoComplete } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Hooks } from 'src/shared/lib';
import { Title } from 'src/shared/ui';
import { treeSelectFieldNames } from 'src/shared/config';
import { AddSourceReqData } from 'shared/api/queries/sourceApi';
import { defaultInitState } from 'src/shared/config';
import styles from './AddSource.module.less';

export interface Pavilion {
  id: number;
  name: string;
  line_id?: number;
}

interface AddSourceProps {
  id?: string;
  closeHandler: () => void;
}

export const AddSource = ({ id, closeHandler }: AddSourceProps) => {
  const [form] = Form.useForm();
  const [selectedPavId, setSelectedPavId] = useState('');
  const categories = useStore($categories);
  const pavilionsOptions = useStore($pavilionsOptions);
  const isCategoriesLoading = useStore(getCategoriesFx.pending);

  useEffect(() => {
    initAddSourcePage();
    initPagePavilions(defaultInitState);
  }, []);

  const formSubmitHandler = (values: AddSourceReqData): void => {
    if (id) {
      submitAddSource({ ...values, supplier_id: Number(id), pavilion_id: Number(selectedPavId) });
    }
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  const handleSelect = (value: string, options: DefaultOptionType) => setSelectedPavId(options.id);

  const handleSearch = (value: string) => {
    const reqData = value ? { ...defaultInitState, query: value } : { ...defaultInitState };
    initPagePavilions(reqData);
  };

  const activity = Hooks.useCheckActivity('Неактивный');

  return (
    <div>
      <Title>Добавление источника</Title>
      <Form
        form={form}
        className={styles.addSource}
        name="basic"
        layout="vertical"
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item
          label="Ссылка на источник"
          name="url"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, добавьте ссылку на источник!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Павильон"
          name="supplier_pavilion_id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите павильон!',
            },
          ]}>
          <AutoComplete
            options={pavilionsOptions}
            onSelect={handleSelect}
            onChange={handleSearch}
            placeholder="Поиск по павильонам"
          />
        </Form.Item>
        <Form.Item
          label="Категория"
          name="category_id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите категорию!',
            },
          ]}>
          <TreeSelect
            placement="topRight"
            disabled={isCategoriesLoading}
            style={{ width: '100%' }}
            fieldNames={treeSelectFieldNames}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={categories?.items}
          />
        </Form.Item>
        <Form.Item
          label="Активность источника"
          name="is_active"
          valuePropName="checked"
          rules={[{ required: true, message: 'Пожалуйста, подтвердите активность источника!' }]}>
          <Checkbox onChange={activity.onChange}>{activity.value}</Checkbox>
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} htmlType="button" className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
