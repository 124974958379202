import { v4 as uuid } from 'uuid';

export const nullLegalId = '0';

export const disabledLegalOption = { id: uuid(), key: uuid(), full_name: '...', value: '...', disabled: true };
export const nullLegalOption = { id: nullLegalId, full_name: 'Без юрлица' };
export const emptyLegalOption = {
  id: uuid(),
  key: uuid(),
  full_name: 'Ничего не найдено',
  value: 'Ничего не найдено',
  disabled: true,
};
