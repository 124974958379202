import { createEffect, createEvent, createStore, sample } from 'effector';
import { adminsLogsApi } from 'src/shared/api';
import { usersApi } from 'src/shared/api';
import { AdminLogsPaginated } from './types';
import { Paginated } from 'src/shared/types';
import { AdminLogs } from './types';
import { User } from 'src/pages/employees/types';
import { initState } from './config';

export const $adminsLogs = createStore<AdminLogsPaginated<AdminLogs> | null>(null);
export const $requestData = createStore<adminsLogsApi.AdminsLogsReqData>(initState);
export const $users = createStore<Paginated<User> | null>(null);
export const $usersData = createStore<usersApi.UsersRequestData>(initState);

export const getAdminsLogsOnPageInit = createEvent<adminsLogsApi.AdminsLogsReqData>();
export const changeRequestData = createEvent<adminsLogsApi.AdminsLogsReqData>();
export const changeUsersData = createEvent<usersApi.UsersRequestData>();

export const getAdminsLogsFx = createEffect(adminsLogsApi.getLogs);
export const getUsersFx = createEffect(usersApi.getUsersList);

$usersData.on(changeUsersData, (_, data) => data);

$users.on(getUsersFx.doneData, (_, data) => data);

$adminsLogs.on(getAdminsLogsFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);

sample({
  clock: getAdminsLogsOnPageInit,
  source: $requestData,
  target: getAdminsLogsFx,
});

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getAdminsLogsFx,
});

sample({
  clock: changeUsersData,
  source: $usersData,
  target: getUsersFx,
});
