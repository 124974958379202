import { Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Title } from 'src/shared/ui/title';

import styles from './RefundCard.module.less';

export const RefundCard = () => (
  <Card className={styles['refund-card']}>
    <Title>Причина возврата</Title>
    <div>
      <p>
        <strong>Причина возврата:</strong> Брак
      </p>
      <p>
        <strong>Комментарий:</strong>
      </p>
      <TextArea rows={4} disabled value="Товар пришел бракованный. Плохо качества" />
    </div>
  </Card>
);
