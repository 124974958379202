import {
    ConfigStore,
    persist as localStoragePersist,
  } from 'effector-storage/local'
  
  type Config<State, Err> = {
    slice: string
  } & Omit<ConfigStore<State, Err>, 'store'>
  
  type Store<State> = ConfigStore<State>['store']
  
  /**
   * Сохранение состояния стора в localStorage
   */
  export const persist = {
    entity: <State, Err = Error>(
      store: Store<State>,
      { key, slice, ...config }: Config<State, Err>
    ) => localStoragePersist({ key: `entity.${slice}.${key}`, store, ...config }),
  }
  