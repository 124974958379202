import { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Measurements } from 'src/entities/measurements';
import { MainModal } from 'src/shared/ui';
import { allowedToChangeWeightStatuses } from '../../config';
import { DetailedOrder } from '../../types';
import { OrderMeasurements } from 'shared/types';
import styles from './OrdersDelivery.module.less';

interface OrdersDeliveryProps {
  order: DetailedOrder;
  handleSubmitMeasurements: (value: OrderMeasurements) => void;
  isLoading: boolean;
}

export const OrdersDelivery = ({ order, handleSubmitMeasurements, isLoading }: OrdersDeliveryProps) => {
  const [isModal, setIsModal] = useState(false);
  const isAvailable = allowedToChangeWeightStatuses.includes(order?.order?.status);

  const handleModal = (value: boolean) => {
    setIsModal(value);
  };

  const handleSuccess = (value: OrderMeasurements) => {
    handleSubmitMeasurements(value);
    setIsModal(false);
  };

  return (
    <article className={styles.delivery}>
      <div>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>Доставка</h3>
          {isAvailable ? (
            <button type="button" className={styles.button} onClick={() => handleModal(true)}>
              <SettingOutlined />
            </button>
          ) : null}
        </div>
        <p>
          <strong>Тип: </strong>
          {order?.order?.delivery?.name || '-'}
        </p>
        <p>
          <strong>Внутренний трек номер: </strong> {order?.order?.delivery_number || '-'}
        </p>
        <p>
          <strong>Внешний трек номер: </strong> {order?.order?.dispatch_number || '-'}
        </p>
        <p>
          <strong>Адрес доставки: </strong> {order?.order?.delivery?.address || '-'}
        </p>
        <p>
          <strong>Адрес клиента: </strong> {order?.order?.address?.index || '-'}, {order?.order?.address?.full || '-'}
        </p>
      </div>

      <MainModal isOpen={isModal} closeHandler={() => handleModal(false)}>
        <Measurements
          order={order}
          successHandler={handleSuccess}
          closeHandler={() => handleModal(false)}
          isLoading={isLoading}
        />
      </MainModal>
    </article>
  );
};
