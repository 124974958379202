import api from '../api';

export interface ExceptionsRequestData {
  search?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

interface ExceptionData {
  id: number;
  word: string;
}

export const getExceptions = (data: ExceptionsRequestData) =>
  api.post('/spellcheck-exceptions', data).then(response => response.data);

export const createException = (word: string) =>
  api.post('/spellcheck-exception', { word }).then(response => response.data);

export const updateException = ({ id, word }: ExceptionData) =>
  api.put(`/spellcheck-exception/${id}`, { word }).then(response => response.data);

export const deleteException = (id: number) =>
  api.delete(`/spellcheck-exception/${id}`).then(response => response.data);
