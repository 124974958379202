import { Button, Form } from 'antd';
import { MainModal } from 'src/shared/ui';

import styles from './ConfirmForm.module.less';
import classNames from 'classnames';

type TPositions = 'left' | 'right';

interface ConfirmModalProps {
  isOpen: boolean;
  title: string;
  closeHandler: () => void;
  confirmHandler: () => void;
  isSplit?: boolean;
  children?: JSX.Element;
  buttonsPosition?: TPositions;
  titlePosition?: TPositions;
}

export const ConfirmForm = ({
  isOpen,
  title,
  closeHandler,
  confirmHandler,
  isSplit,
  children,
  buttonsPosition,
  titlePosition,
}: ConfirmModalProps) => (
  <MainModal width={isSplit ? '720px' : ''} isOpen={isOpen} closeHandler={closeHandler}>
    <div className={styles.remove}>
      <h2 className={classNames([
        styles.removeTitle,
        titlePosition && styles[titlePosition]
      ])}>
        {title || 'Вы действительно хотите продолжить?'}
      </h2>
      {children &&
        <div className={styles.content}>{children}</div>
      }
      <div className={classNames([
        styles.buttons,
        buttonsPosition && styles[buttonsPosition]
      ])}>
        <Form onFinish={confirmHandler} name="basic">
          <Button htmlType="submit" className={styles.button} type="primary" autoFocus>
            {isSplit ? 'OK' : 'Подтвердить'}
          </Button>
          <Button hidden={isSplit} className={styles.button} onClick={closeHandler}>
            Отменить
          </Button>
        </Form>
      </div>
    </div>
  </MainModal>
);
