import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';
import { NameProductCard } from 'src/entities/nameProductCard';
import { Product, ProductCategory } from 'src/pages/products';
import { getFinalPrice } from 'src/shared/lib';
import { statusColors, productStatusOptions } from 'src/shared/config';
import { ESupplierStatus } from 'src/pages/profiles/suppliers/types';
import { Icons } from 'src/img/icons';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

const renderStatus = (status: string) => {
  switch (status) {
    case 'active':
      return <Tag style={statusColors.green}>В продаже</Tag>;
    case 'deactivated':
      return <Tag style={statusColors.gray}>Неактивен</Tag>;
    case 'in_review':
      return <Tag style={statusColors.blue}>На модерацию</Tag>;
    default:
      return '-';
  }
};

export const selectStatusOptions = Object.entries(productStatusOptions).map(([key, value]) => ({
  value: key.toLocaleLowerCase(),
  label: value,
}));

export const mainColumnName = 'name';

export const columns: ColumnsType<Product> = [
  {
    title: 'Наименование товара',
    key: 'name',
    render: data => <NameProductCard data={data} />,
    width: '400px',
  },
  {
    title: 'Категория',
    dataIndex: 'categories',
    key: 'categories',
    render: data => data?.map((item: ProductCategory) => item.name).join(', '),
  },
  {
    title: 'Цена',
    dataIndex: 'price',
    key: 'price',
    render: data => {
      const resultPrice = getFinalPrice(data);
      return data ? resultPrice : '-';
    },
  },
  {
    title: 'Количество',
    dataIndex: 'quantity',
    key: 'quantity',
    render: data => (data ? `${data} шт.` : '-'),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    render: data =>
      data?.supplier?.name ? (
        <Link to={`/profile/supplier/${data.supplier.id}`} state={{ prevPath: true }}>
          {data.supplier.name}
          {data?.supplier?.is_registered && <Icons.StatusActive />}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => Utils.dateFormat(data),
  },
  {
    title: 'Автор жалобы',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (clientName, data) =>
      clientName ? (
        <Link to={`/profile/client/${data.clientId}`} state={{ prevPath: true }}>
          {clientName}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Жалоба',
    dataIndex: 'complaint',
    key: 'complaint',
    render: data => data?.comment || '-',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: data => renderStatus(data),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
