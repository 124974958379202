import { useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { roleOptions } from 'src/pages/employees/config';
import { AddUserRequestData } from 'src/shared/api/queries/usersApi';
import { employeesFormRules } from 'src/pages/employees/config';
import { getNumbers } from 'src/shared/lib';
import { PhoneInput } from 'src/entities/phoneInput';
import styles from './AddUser.module.less';

interface UpdateUserProps {
  successHandler: (value: AddUserRequestData) => void;
  closeHandler: () => void;
  isEmployeeError: boolean;
}

export const AddUser = ({ successHandler, closeHandler, isEmployeeError }: UpdateUserProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEmployeeError) {
      closeHandler();
      form.resetFields();
    }
  }, [isEmployeeError]);

  const formSubmitHandler = (values: AddUserRequestData) => {
    const phone = getNumbers(values.phone);
    successHandler({ ...values, phone });
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.addUser}>
      <h2 className={styles.title}>Добавление нового сотрудника</h2>
      <p className={styles.description}>
        Ваш сотрудник получит письмо с уведомлением и ссылкой для подтверждения аккаунта
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item rules={employeesFormRules.name} label="Имя" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Пожалуйста, введите email сотрудника!' },
            { type: 'email', message: 'Не валидный email' },
          ]}
          label="Email"
          name="email">
          <Input />
        </Form.Item>
        <PhoneInput />
        <Form.Item
          rules={[{ required: true, message: 'Пожалуйста, выберите роль сотрудника!' }]}
          label="Роль"
          name="role">
          <Select
            style={{
              width: '100%',
            }}>
            {Object.entries(roleOptions)?.map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item rules={employeesFormRules.password} label="Пароль" name="password">
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Подтвердить
            </Button>
          </Form.Item>
          <Button onClick={handleCancelClose} className={styles.button}>
            Отменить
          </Button>
        </div>
      </Form>
    </div>
  );
};
