export interface Paginated<T> {
  items: T[];
  pagination: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

export enum NumberTypeEnum {
  PRICE = 'price',
  PHONE = 'phone',
  CEO_PHONE = 'ceo_phone',
  COUNT = 'count',
  AMOUNT = 'amount',
  BANK_COMMISSION = 'bank_commission',
  MARKET_PLACE_COMMISSION = 'market_place_commission',
  MARKET_PLACE_AMOUNT = 'market_place_amount',
  LEGAL_ENTITY_AMOUNT = 'legal_entity_amount',
  INN = 'inn',
}

export enum MessageTypeEnum {
  TEXT = 'text',
  IMAGE = 'image',
}

export type SelectOptions = { label: string; value: string }[];

export type LegalCreatorType = 'USER' | 'SUPPLIER';
export type UserType = 'CLIENT' | 'INTERMEDIARY' | 'USER' | 'SUPPLIER' | 'SELLER';

interface Member {
  access: 'available' | 'readable' | 'not_available';
  member: {
    avatar?: string;
    id: number;
    name: string;
    status: 'offline' | 'online';
    user_id: number;
    user_type: UserType;
  };
  reason?: 'task_interior_abandon' | 'task_canceled' | 'task_out_of_stock' | 'task_shipping';
}

export interface Attachment {
  id: number;
  type: string;
  url: string;
}
export interface LastUpdatedMessage {
  id: number;
  chat_id: number;
  type: 'text';
  created_at: string;
  author: Member;
  text?: string;
  is_read: boolean;
  attachments: Attachment[];
}

export interface IChatItem {
  entity_id?: number;
  id: number;
  image?: string;
  members: Member[];
  message: LastUpdatedMessage;
  name: string;
  type: 'task' | 'client_support' | 'intermediary_support';
}

export interface MessageAuthor {
  id: number;
  user_type: UserType;
  user_id: number;
  name: string;
  avatar?: string;
  status: 'offline' | 'online';
}

export interface Message {
  id: number;
  chat_id: number;
  type: MessageTypeEnum;
  created_at: string;
  author: MessageAuthor;
  text?: string;
  is_read?: boolean;
  attachments: Attachment[];
  read: MessageAuthor[];
}

export interface Pavilion {
  id: number;
  line_id: number;
  name: string;
  full_name?: string;
  disabled?: boolean;
}

export interface OrderMeasurements {
  weight: string;
  length?: string;
  width?: string;
  height?: string;
}
