import { createStore, createEffect, createEvent, sample } from 'effector';
import { Paginated } from 'src/shared/types';
import { intermediariesApi } from 'src/shared/api';
import { Storage } from 'src/shared/lib';

import { initColumnSettings, initState, PER_PAGE } from './config';
import { Intermediary } from './types';

export const $intermediaries = createStore<Paginated<Intermediary> | null>(null);
export const $requestData = createStore<intermediariesApi.IntermedRequestData>(initState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'intermediaries', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'intermediaries', key: 'pageSize' });

export const changeRequestData = createEvent<intermediariesApi.IntermedRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changePageSize = createEvent<number>();

export const getIntermediariesFx = createEffect(intermediariesApi.getIntermedList);

$intermediaries.on(getIntermediariesFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getIntermediariesFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});
