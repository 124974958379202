import { Tooltip } from 'antd';

interface ShortenedTooltipProps {
  data?: string;
  length: number;
}

export const ShortenedTooltip = ({ data, length }: ShortenedTooltipProps) => {
  const isLongText = data && data?.length > length;
  const resultText = isLongText ? `${data.slice(0, length)}...` : data;

  return (
    <Tooltip overlayStyle={{ position: 'fixed' }} title={isLongText ? data : ''}>
      {resultText || '-'}
    </Tooltip>
  );
};
