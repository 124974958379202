import React from 'react';
import { Button } from 'antd';

interface IMainButtonProps {
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined;
  className?: string | undefined;
  children: React.ReactNode;
  onClick?: () => void;
}

export const MainButton = ({ type = 'default', className, children, onClick }: IMainButtonProps) => (
  <Button onClick={onClick} className={className} type={type}>
    {children}
  </Button>
);
