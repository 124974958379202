import { DateType } from 'src/shared/ui/datePicker';
import { LegalCreatorType } from 'src/shared/types';

export interface LegalEntityDetailed {
  id: number;
  billing_descriptor: string;
  full_name: string;
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  smz: boolean;
  email: string;
  archived_at: DateType;
  registered_at: DateType;
  creator_id: number;
  creator_type: LegalCreatorType;
  ceo: {
    first_name: string;
    last_name: string;
    middle_name: string;
    birth_date: DateType;
    phone: string;
    country: string;
  };
  site_url: string;
  bank_account: {
    account: string;
    bank_name: string;
    bik: string;
    details: string;
    tax: number;
  };
  addresses: [
    {
      id: number;
      type: LegalAdressType;
      zip: string;
      country: string;
      city: string;
      street: string;
    },
  ];
  point_id: string;
  type: 'delivery' | 'commission';
  is_internal: boolean;
}

export interface LegalEntityDetailedData {
  creator_name: string;
  legalEntity: LegalEntityDetailed;
}

export type LegalAdressType = 'legal' | 'actual' | 'post' | 'other';

export enum LegalEntityCardType {
  ORGANIZATION = 'ORGANIZATION',
  ADDRESS = 'ADDRESS',
  CEO = 'CEO',
  BANK = 'BANK',
  CONTACTS = 'CONTACTS',
}

export type LegalEntityModalType = 'isRegistration' | 'isDelete' | 'isArchive' | 'isExtract';
