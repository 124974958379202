import { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { PasswordForm } from './ui';
import { MainModal } from 'src/shared/ui';
import { Title } from 'src/shared/ui';
import { PhoneInput } from 'src/entities/phoneInput';
import { User } from 'src/pages/employees/types';
import { roleOptions } from 'src/pages/employees/config';
import { employeesFormRules } from 'src/pages/employees/config';
import { getNumbers } from 'src/shared/lib';

import styles from './UpdateUser.module.less';

export interface FormSuccess {
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
  password?: string;
}

interface UpdateUserProps {
  data: User;
  successHandler: (value: FormSuccess) => void;
  closeHandler: () => void;
  isEmployeeError: boolean;
}

export const UpdateUser = ({ data, closeHandler, successHandler, isEmployeeError }: UpdateUserProps) => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [changedFields, setChangedFields] = useState<FormSuccess>({});

  useEffect(() => {
    form.setFieldsValue(data);
  }, [form, data]);

  useEffect(() => {
    if (isEmployeeError) {
      closeHandler();
    }
  }, [isEmployeeError]);

  const formSubmitHandler = (values: FormSuccess) => {
    const phone = values?.phone ? getNumbers(values.phone) : '';
    successHandler({ ...values, phone });
  };

  const passFormSubmitHandler = (password: string) => {
    successHandler({ ...data, ...changedFields, password });
    setIsOpen(false);
  };

  const handleFormOnChange = (values: FormSuccess) => {
    const [key, value] = Object.entries(values)[0];
    if (data[key as keyof typeof data] !== value) {
      const resultValue = key === 'phone' ? getNumbers(value) : value;
      setChangedFields({ ...changedFields, [key]: resultValue });
    } else {
      const updatedFields = { ...changedFields };
      delete updatedFields[key as keyof typeof updatedFields];
      setChangedFields(updatedFields);
    }
  };

  const handleOpenPassEdit = () => setIsOpen(true);

  const handleClosePassEdit = () => setIsOpen(false);

  const handleCancelClose = () => {
    form.resetFields();
    setIsOpen(false);
    setChangedFields({});
    closeHandler();
  };

  return (
    <div className={styles.updateUser}>
      <Title>Редактирование сотрудника</Title>
      <Form
        form={form}
        initialValues={{ ...{ name: '', email: '', phone: '', role: '', password: '' }, ...data }}
        name="basic"
        layout="vertical"
        onFinish={formSubmitHandler}
        onValuesChange={handleFormOnChange}
        autoComplete="off">
        <Form.Item rules={employeesFormRules.name} label="Имя" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Пожалуйста, введите email сотрудника!' },
            { type: 'email', message: 'Не валидный email' },
          ]}
          label="Email"
          name="email">
          <Input />
        </Form.Item>
        <PhoneInput />
        <Form.Item
          rules={[{ required: true, message: 'Пожалуйста, введите роль сотрудника!' }]}
          label="Роль"
          name="role">
          <Select style={{ width: '100%' }}>
            {Object.entries(roleOptions)?.map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button onClick={handleOpenPassEdit} htmlType="button" className={styles.buttonPass} type="text">
          Изменить пароль
          <ArrowRightOutlined className={styles.icon} />
        </Button>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Подтвердить
            </Button>
          </Form.Item>
          <Button onClick={handleCancelClose} className={styles.button}>
            Отменить
          </Button>
        </div>
      </Form>

      <MainModal isOpen={isOpen} closeHandler={handleClosePassEdit}>
        <PasswordForm
          successHandler={passFormSubmitHandler}
          closeHandler={handleClosePassEdit}
          changedFields={Object.keys(changedFields)}
        />
      </MainModal>
    </div>
  );
};
