import { createEffect, createEvent, createStore, sample } from 'effector';
import { Storage } from 'src/shared/lib';
import { Paginated, Pavilion } from 'src/shared/types';
import { imageApi, suppliersApi, pavilionsApi, legalEntitiesApi, sourceApi } from 'src/shared/api';
import { ESupplierStatus, Supplier, SuppliersSource } from './types';
import { LegalEntity } from '../legalEntities/types';
import { DetailedSource } from '../suppliersProfile/ui/suppParser/ui/suppParserList/SuppParserList';
import { initColumnSettings, nullLegalId, nullLegalOption, disabledLegalOption } from './config';
import { PER_PAGE_LEGAL, defaultInitState, PER_PAGE } from 'src/shared/config';
import { SupplierProfile } from '../suppliersProfile';
import { v4 as uuid } from 'uuid';
import { setError, setSuccess } from 'src/features/notifications';
import { AxiosError } from 'axios';
import { IGetSupplierStatusesResponse } from 'shared/api/queries/suppliersApi';

export const $suppliers = createStore<Paginated<Supplier> | null>(null);
export const $pavilions = createStore<Paginated<Pavilion> | null>(null);
export const $legalEntities = createStore<LegalEntity[]>([nullLegalOption]);
export const $sources = createStore<DetailedSource[]>([]);
export const $isOpenModal = createStore(false);
export const $requestData = createStore<suppliersApi.SuppRequestData>(defaultInitState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'suppliers', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'suppliers', key: 'pageSize' });

export const submitChangeRequestData = createEvent<suppliersApi.SuppRequestData>();
export const submitCreation = createEvent<SupplierProfile>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const handleModal = createEvent<boolean>();
export const changePageSize = createEvent<number>();
export const initPagePavilions = createEvent<pavilionsApi.getPavilionsReqData>();
export const initPageSources = createEvent<sourceApi.GetSourcesReqData>();
export const submitLegalData = createEvent<legalEntitiesApi.LegalEntitiesReqData>();

export const saveSupplierStatuses = createEvent<IGetSupplierStatusesResponse['items']>();
export const $supplierStatuses = createStore<IGetSupplierStatusesResponse['items']>([]).on(
    saveSupplierStatuses,
    (_, data) => data,
);

export const getSuppliersFx = createEffect(suppliersApi.getSuppList);
export const createSupplierFx = createEffect<SupplierProfile, SupplierProfile, AxiosError<{ message: string }>>(
  suppliersApi.createSupplier,
);
export const getFileFx = createEffect(imageApi.getFileFromUrl);
export const getPavilionsFx = createEffect(pavilionsApi.getPavilions);
export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);
export const getSourcesFx = createEffect(sourceApi.getSources);

$suppliers.on(getSuppliersFx.doneData, (_, data) => {
  const resultData = data?.items
    ? data.items.map((item: Supplier) => {
        const resultItem =
          data?.sources && data.sources.length
            ? data.sources.reduce((acc: Supplier, source: SuppliersSource) => {
                if (item.id === source.supplier_id) {
                  acc = {
                    ...acc,
                    ...item,
                    sources: acc?.sources
                      ? [...acc.sources, { id: source?.id || uuid(), name: source?.name || '', url: source?.url || '' }]
                      : [{ id: source?.id || uuid(), name: source?.name || '', url: source?.url || '' }],
                  };
                } else {
                  acc = { ...acc, ...item };
                }
                return acc;
              }, {})
            : item;
        return resultItem;
      })
    : [];
  return { ...data, items: resultData };
});

$requestData.on(submitChangeRequestData, (_, data) => {
  if (Object.hasOwn(data, 'legal_entity_id') && data?.legal_entity_id === nullLegalId) {
    return { ...data, legal_entity_id: null };
  }
  return data;
});
$pavilions.on(getPavilionsFx.doneData, (_, data) => data);
$legalEntities.on(getLegalEntitiesFx.doneData, (_, data: Paginated<LegalEntity>) => {
  const resultLegalOptions =
    data?.items && data.items.length > PER_PAGE_LEGAL - 1
      ? [nullLegalOption, ...data.items.slice(0, PER_PAGE_LEGAL - 1), disabledLegalOption]
      : (data?.items && [nullLegalOption, ...data.items]) || [nullLegalOption];

  return resultLegalOptions;
});

$sources.on(getSourcesFx.doneData, (_, data: Paginated<DetailedSource>) => {
  const resultData = data?.items ? data.items.map(item => ({ ...item, ...{ name: item?.name || item?.url } })) : [];
  return resultData;
});

sample({
  clock: initPagePavilions,
  target: getPavilionsFx,
});

sample({
  clock: initPageSources,
  target: getSourcesFx,
});

sample({
  clock: submitLegalData,
  target: getLegalEntitiesFx,
});

sample({
  clock: $requestData,
  target: getSuppliersFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: submitCreation,
  target: createSupplierFx,
});

sample({
  clock: createSupplierFx.doneData,
  fn: () => 'Поставщик успешно создан',
  target: setSuccess,
});

sample({
  clock: createSupplierFx.doneData,
  fn: () => false,
  target: handleModal,
});

sample({
  clock: createSupplierFx.doneData,
  fn: () => defaultInitState,
  target: getSuppliersFx,
});

sample({
  clock: createSupplierFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка создания',
  target: setError,
});

sample({
  clock: handleModal,
  target: $isOpenModal,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});
