import { useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { trimNullsFromString, isStringIncludesValues } from 'src/shared/lib';
import { sdekNames } from './config';
import { DetailedOrder } from 'src/pages/orders/order/types';
import styles from './Measurements.module.less';

export interface FormSuccess {
  weight: string;
  length: string;
  width: string;
  height: string;
}

interface MeasurementsProps {
  order: DetailedOrder | null;
  successHandler: (value: FormSuccess) => void;
  isLoading: boolean;
  closeHandler: () => void;
}

export const Measurements = ({ order, successHandler, closeHandler, isLoading }: MeasurementsProps) => {
  const [form] = Form.useForm();
  const isSdek = order?.order?.delivery?.provider
    ? isStringIncludesValues(order.order.delivery.provider, sdekNames)
    : false;

  useEffect(() => {
    form.setFieldsValue(order?.order?.dimensions || {});
  }, [form, order]);

  const formSubmitHandler = (values: FormSuccess) => {
    const filteredValues = Object.keys(values).reduce((acc, key) => {
      if (values[key as keyof typeof values]) acc[key as keyof typeof acc] = values[key as keyof typeof values];
      return acc;
    }, {} as FormSuccess);
    const reqData = { ...filteredValues };

    successHandler(reqData);
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.measurements}>
      {order?.order ? (
        <>
          <div className={styles.head}>
            <h2 className={styles.title}>
              Заказ {order.order?.code || '-'} {order.order?.delivery?.name ? `в ${order.order.delivery.name}` : ''}
            </h2>
            <p className={styles.groupTitle}>
              {order.order?.delivery_number || order.order?.dispatch_number
                ? `${order.order?.delivery_number || '-'} / ${order.order?.dispatch_number || '-'}`
                : null}
            </p>
          </div>

          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={order?.order?.dimensions || {}}
            onFinish={formSubmitHandler}
            autoComplete="off">
            <div className={styles.group}>
              <p className={styles.groupTitle}>Посылка</p>

              <Form.Item
                label="Вес (в гр)"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите вес',
                  },
                ]}>
                <Input type="number" step={1} min={0} placeholder="Только число" />
              </Form.Item>

              <div className={styles.wrapper}>
                <Form.Item
                  label="Длина (в см)"
                  name="length"
                  rules={[
                    {
                      required: isSdek,
                      message: 'Пожалуйста, введите длину',
                    },
                  ]}>
                  <Input type="number" step={1} min={0} placeholder="Только число" />
                </Form.Item>
                <Form.Item
                  label="Ширина (в см)"
                  name="width"
                  rules={[
                    {
                      required: isSdek,
                      message: 'Пожалуйста, введите ширину',
                    },
                  ]}>
                  <Input type="number" step={1} min={0} placeholder="Только число" />
                </Form.Item>
                <Form.Item
                  label="Высота (в см)"
                  name="height"
                  rules={[
                    {
                      required: isSdek,
                      message: 'Пожалуйста, введите высоту',
                    },
                  ]}>
                  <Input type="number" step={1} min={0} placeholder="Только число" />
                </Form.Item>
              </div>
            </div>

            <div className={styles.buttons}>
              <Form.Item>
                <Button htmlType="submit" className={styles.button} type="primary">
                  Сохранить
                </Button>
              </Form.Item>

              <Button onClick={handleCancelClose} className={styles.button}>
                Отмена
              </Button>
            </div>
          </Form>
        </>
      ) : null}
    </div>
  );
};
