import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { legalEntitiesApi } from 'src/shared/api';
import { LegalEntityDetailed, LegalEntityDetailedData } from 'src/pages/profiles/legalEntitiesProfile/types';
import { AxiosError } from 'axios';
import { LegalValidationErrors } from './types';
import { getErrorFieldName } from './helpers';

export const $legalEntity = createStore<LegalEntityDetailedData | null>(null);
export const $validationErrors = createStore<LegalValidationErrors | null>(null);

export const initEditLegalEntity = createEvent<string>();
export const submitCreateForm = createEvent<legalEntitiesApi.LegalEntityCreateReqData>();
export const submitEditForm = createEvent<legalEntitiesApi.LegalEntityEditReqData>();
export const resetLegalEntity = createEvent();
export const submitRemoveErrorField = createEvent<string>();
export const resetValidationErrors = createEvent();

export const getLegalEntityFx = createEffect(legalEntitiesApi.getLegalEntity);
export const createLegalEntityFx = createEffect<
  legalEntitiesApi.LegalEntityCreateReqData,
  LegalEntityDetailed,
  AxiosError<{ message: string; errors: LegalValidationErrors }>
>(legalEntitiesApi.createLegalEntity);
export const editLegalEntityFx = createEffect<
  legalEntitiesApi.LegalEntityEditReqData,
  LegalEntityDetailed,
  AxiosError<{ message: string; errors: LegalValidationErrors }>
>(legalEntitiesApi.editLegalEntity);

const successRedirectFx = createEffect(() => {
  if (window) {
    window.history.back();
  }
});

const successCreateRedirectFx = createEffect((id: number | string) => {
  if (window && id) {
    window.location.replace(`/profile/legalEntity/${id}`);
  }
});

$legalEntity.on(getLegalEntityFx.doneData, (_, data) => data).reset(resetLegalEntity);
$validationErrors
  .on([createLegalEntityFx.failData, editLegalEntityFx.failData], (_, data) => data?.response?.data?.errors)
  .on(submitRemoveErrorField, (errors, field) => {
    if (errors) {
      const [, index] = field.split('-');
      const finalIndex = index || Number(index) === 0 ? index : '';
      const errorFieldName = getErrorFieldName(field, finalIndex);
      const newErrors = { ...errors };
      delete newErrors[errorFieldName as keyof typeof newErrors];
      return newErrors;
    }
    return errors;
  })
  .reset(resetValidationErrors);

sample({
  clock: initEditLegalEntity,
  target: getLegalEntityFx,
});

sample({
  clock: submitCreateForm,
  target: createLegalEntityFx,
});

sample({
  clock: submitEditForm,
  target: editLegalEntityFx,
});

sample({
  clock: createLegalEntityFx.done,
  fn: data => data?.result?.id || '',
  target: successCreateRedirectFx,
});

sample({
  clock: editLegalEntityFx.done,
  fn: () => 'Юридическое лицо успешно изменено',
  target: [successRedirectFx, setSuccess],
});

sample({
  clock: editLegalEntityFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении юридического лица',
  target: setError,
});

sample({
  clock: createLegalEntityFx.done,
  fn: () => 'Юридическое лицо успешно создано',
  target: setSuccess,
});

sample({
  clock: createLegalEntityFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при создании юридического лица',
  target: setError,
});
