import { ColumnsType } from 'antd/lib/table';
import { Document } from './types';

export const documentsCodes: Record<string, string> = {
  'client-reference': 'Справка',
  'intermediary-reference': 'Справка',
  'client-reference-documents': 'Справка документы',
  'intermediary-reference-documents': 'Справка документы',
  'client-faq': 'Часто задаваемые вопросы',
  'intermediary-faq': 'Часто задаваемые вопросы',
  'client-support': 'Техническая поддержка',
  'intermediary-support': 'Техническая поддержка',
  'client-phone': 'Телефон',
  'intermediary-phone': 'Телефон',
  'client-email': 'Электронная почта',
  'intermediary-email': 'Электронная почта',
  'client-vk': 'Сообщество вконтакте',
  'intermediary-vk': 'Сообщество вконтакте',
  'client-whatsapp': 'WhatsApp',
  'intermediary-whatsapp': 'WhatsApp',
  'client-telegram': 'Telegram',
  'intermediary-telegram': 'Telegram',
  'client-offer': 'Оферта',
  'intermediary-offer': 'Оферта',
  'client-policy': 'Политика обработки персональных данных',
  'intermediary-policy': 'Политика обработки персональных данных',
};

export enum MenuTableEnum {
  EDIT = 'edit',
  REMOVE = 'remove',
}

export const columns: ColumnsType<Document> = [
  {
    title: 'Название',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
];
