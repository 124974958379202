import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { removeDuplicates } from 'src/shared/lib/array';
import { setError, setSuccess } from 'src/features/notifications';
import { docsApi, imageApi } from 'src/shared/api';
import { Document, DocumentForm, TreeData } from './types';

const transformTreeNode = (item: Document): TreeData => ({
  id: item.id,
  pId: item.parent_id,
  isLeaf: !item.has_children,
  value: item.id,
  title: item.name,
});

export const $documents = createStore<Document[]>([]);
export const $editingDocument = createStore<Document | null >(null);
export const $treeData = createStore<TreeData[]>([]);
$treeData.on($documents, (_, docs) => docs.map(transformTreeNode));
export const $isLoading = createStore<boolean>(false);
export const $childrenDocuments = createStore<Record<number, Document[]>>({});

export const getDocumentsFx = createEffect(docsApi.getDocuments);
export const getDocumentFx = createEffect(docsApi.getDocument);
export const editDocumentFx = createEffect<docsApi.EditingCategory, Document, AxiosError<{ message: string }>>(
  docsApi.editDocument,
);
export const createDocumentFx = createEffect(docsApi.createDocument);
export const getFileFx = createEffect(imageApi.getFileFromUrl);
export const getSubRowsFx = createEffect(docsApi.getDocuments);

export const initDocumentsSettings = createEvent();
export const submitEditDocument = createEvent<DocumentForm>();
export const submitCreationDocument = createEvent<DocumentForm>();
export const loadingChange = createEvent<boolean>();
export const openRow = createEvent<number>();
export const openDocument = createEvent<number>();
export const clearEditingDoc = createEvent();
export const expandRow = createEvent<number>();

sample({
  clock: initDocumentsSettings,
  target: getDocumentsFx,
});

sample({
  clock: openRow,
  fn: (id: number) => ({ parent_id: id }),
  target: getSubRowsFx,
});

sample({
  clock: openDocument,
  target: getDocumentFx
});

sample({
  clock: submitCreationDocument,
  target: createDocumentFx,
});

sample({
  clock: createDocumentFx.doneData,
  fn: () => 'Категория создана',
  target: [setSuccess, initDocumentsSettings],
});

sample({
  clock: createDocumentFx.failData,
  fn: ({message}) => message,
  target: setError,
});

sample({
  clock: submitEditDocument,
  target: editDocumentFx,
});

sample({
  clock: editDocumentFx.doneData,
  fn: () => 'Документ изменен',
  target: [setSuccess, initDocumentsSettings],
});

sample({
  clock: editDocumentFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка редактирования',
  target: setError,
});

sample({
  source: [getDocumentsFx.pending, createDocumentFx.pending, editDocumentFx.pending],
  fn: data => data.reduce((prev, curr) => prev || curr, false),
  target: loadingChange,
});

sample({
  clock: expandRow,
  fn: (id: number) => ({ parent_id: id }),
  target: getSubRowsFx,
});

$documents.on(getDocumentsFx.doneData, (_, data) =>
  data.items.map((item: Document) => ({
    ...item,
    children: item.has_children ? [] : null,
    name: `${item.name} ${item.code ? `(${item.code})` : ''}`,
  })),
);
$isLoading.on(loadingChange, (_, data) => data);
$editingDocument.on(getDocumentFx.doneData, (_, data) => data);
$editingDocument.on([submitEditDocument, clearEditingDoc], () => null);
$treeData.on(getSubRowsFx.doneData, (state, data) =>
  removeDuplicates([...state, ...data.items.map(transformTreeNode)], 'id'),
);
$childrenDocuments.on(getSubRowsFx.doneData, (state, data) => ({
  ...state,
  [data.items[0].parent_id]: data.items.map((doc: Document) => ({
    ...doc,
    name: `${doc.name} ${doc.code ? `(${doc.code})` : ''}`,
  })),
}));
