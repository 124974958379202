import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Link from 'antd/lib/typography/Link';
import { statusColors } from 'src/shared/config';

import { Source } from './types';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

const renderActivity = (value: boolean) => {
  if (value) {
    return <Tag style={statusColors.green}>Активный</Tag>;
  }
  return <Tag style={statusColors.lightGray}>Неактивный</Tag>;
};

export const columns: ColumnsType<Source> = [
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    render: value => value.name,
  },
  {
    title: 'Павильон',
    dataIndex: 'pavilion',
    key: 'pavilion',
    render: value => value.name,
  },
  {
    title: 'Ссылка на источник',
    dataIndex: 'url',
    key: 'url',
    render: value => (
      <Link href={value} target="_blank">
        {value}
      </Link>
    ),
  },
  {
    title: 'Активность источника',
    dataIndex: 'is_active',
    key: 'is_active',
    render: value => renderActivity(value),
  },
  {
    title: 'Категория',
    dataIndex: 'category',
    key: 'category',
    render: value => value.name,
  },
];
