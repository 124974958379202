import api from '../api';

export interface GetNamingsRequestData {
  id: number;
  data: {
    search?: string;
    pagination: {
      per_page: number;
      page: number;
    };
  };
}

export interface AddNamingsRequestData {
  id: number;
  data: {
    name: string;
    masks: string[];
  };
}

export interface EditNamingsRequestData {
  categoryId: number;
  namingId: number;
  data: {
    name: string;
    masks: string[];
  };
}

export const getNamings = ({ id, data }: GetNamingsRequestData) =>
  api.post(`/category/${id}/names`, data).then(response => response.data);

export const addNamings = ({ id, data }: AddNamingsRequestData) =>
  api.post(`/category/${id}/name`, data).then(response => response.data);

export const editNamings = ({ categoryId, namingId, data }: EditNamingsRequestData) =>
  api.put(`/category/${categoryId}/name/${namingId}`, data).then(response => response.data);

export const deleteNamings = (id: number) => api.delete(`/name/${id}`).then(response => response.data);
