import { Card, Form, Switch } from 'antd';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { Title } from 'src/shared/ui';
import { $setting, getSettingsFx, initSplittingSetting, changeSettingValue, $legalEntities, $suppliers } from './model';
import { ConfirmForm } from 'src/shared/ui';
import { getInfoMessage } from './helpers';
import styles from './Splitting.module.less';

export const Splitting = () => {
  const settingValue = useStore($setting);
  const legalEntities = useStore($legalEntities);
  const suppliers = useStore($suppliers);
  const isLoading = useStore(getSettingsFx.pending);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    initSplittingSetting();
  }, []);

  const handleSubmitSplitData = (value: boolean) => {
    if ((legalEntities && !legalEntities?.items.length) || (suppliers && suppliers?.items.length)) {
      const infoMessage = getInfoMessage(!!legalEntities?.items.length, !suppliers?.items.length);
      setMessage(infoMessage);
      setIsOpen(true);
    } else {
      changeSettingValue(value);
    }
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <Card className={styles.wrapper}>
      <Title>Настройка сплитования</Title>
      <Form>
        <Form.Item label="Использовать сплитование платежей" name="split">
          <Switch checked={settingValue} loading={isLoading} onChange={handleSubmitSplitData} />
        </Form.Item>
      </Form>
      <p className={styles.info}>
        Если сплитование платежей включено, получаемые платежи распределяются согласно схеме маркетплейса, клиент
        получает в чеке информацию о поставщиках товаров и услуг.
      </p>
      <p className={styles.info}>
        Если отключить — платежи будут зачисляться на единый счёт и клиент не будет получать информацию о поставщиках
        товаров и услуг в чеке.
      </p>

      <ConfirmForm
        isOpen={isOpen}
        title={message}
        closeHandler={handleCloseModal}
        confirmHandler={handleCloseModal}
        isSplit
      />
    </Card>
  );
};
