import { useEffect, useRef, useState } from 'react';
import { Button, Space, AutoComplete, Select } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Client } from 'src/pages/profiles/clients/types';
import { User } from 'src/pages/employees/types';
import { Supplier } from 'src/pages/profiles/suppliers/types';
import { LegalEntity } from 'src/pages/profiles/legalEntities/types';
import { DetailedSource } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/suppParserList/SuppParserList';
import { getSearchTypeName } from './helpers';
import { DefaultOptionType } from 'antd/lib/select';
import styles from './useColumn.module.less';
import { ColumnTitle } from 'antd/lib/table/interface';
import { defaultSelectValue } from 'src/shared/config';
import { LegalCreatorType, Pavilion } from 'src/shared/types';

function useColumnAutocomplete<DataType>(
  searchHandler: (value: string) => void,
  selectHandler: (value: number) => void,
  resetSelectHandler: () => void,
  options?: Client[] | User[] | Pavilion[] | LegalEntity[] | DetailedSource[] | Supplier[],
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  isLegalEntities?: boolean,
  selectOption?: DefaultOptionType[],
  handleChangeCreatorType?: (value: LegalCreatorType) => void,
  creatorType?: LegalCreatorType,
) {
  const searchInput = useRef<InputRef>(null);
  const [value, setValue] = useState<string>('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setValue('');
      setIsActive(false);
      setIsActiveFilter(false);
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const filteredOptions = !isLegalEntities
    ? options?.map(({ id, name }) => ({ label: name, value: name, key: id, disabled: false })) || []
    : options?.map(({ id, full_name: name, disabled }) => ({ label: name, value: name, key: id, disabled })) || [];

  const handleSearch = (value: string) => {
    setValue(value);
    searchHandler(value);
    setIsActive(true);
  };

  const handleSelect = (value: string, options: { label: string }) => {
    setValue(options?.label || '');
    selectHandler(Number(value));
  };

  const handleReset = (clearFilters: () => void) => {
    setValue('');
    resetSelectHandler();
    clearFilters();
    setIsActive(false);
  };

  const handleChange = (value: LegalCreatorType) => {
    if (handleChangeCreatorType) {
      handleChangeCreatorType(value);
    }
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className={styles.useColumnAutoselect}>
        {selectOption ? (
          <div className={styles.selectType}>
            <Select
              value={creatorType || defaultSelectValue}
              placeholder="Тип ответственного"
              onChange={handleChange}
              options={selectOption}
              placement="topLeft"
            />
          </div>
        ) : null}
        <div>
          <AutoComplete
            value={value}
            onSelect={handleSelect}
            onChange={handleSearch}
            placeholder={`Поиск по ${getSearchTypeName(String(dataIndex))}`}>
            {filteredOptions?.map(({ value, key, label, disabled }) => (
              <AutoComplete.Option key={key} label={label} disabled={disabled}>
                {value}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
          <Space>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="middle">
              Сбросить
            </Button>
          </Space>
        </div>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnAutocomplete };
