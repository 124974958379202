import { createEffect, createEvent, createStore, sample } from 'effector';
import { Paginated } from 'src/shared/types';
import { Storage } from 'src/shared/lib';
import { ordersApi } from 'src/shared/api';
import { clientsApi } from 'src/shared/api';
import { initColumnSettings, initState } from './config';
import { initState as clientsInitState } from 'src/pages/profiles/clients/config';
import { Client } from 'src/pages/profiles/clients/types';
import { IOrder } from '../ordersList/types';
import { IncomeOrders } from '../ordersList/types';

export const $orders = createStore<Paginated<IOrder> | null>(null);
export const $requestData = createStore<ordersApi.OrdersRequestData>(initState);
export const $clients = createStore<Paginated<Client> | null>(null);
export const $clientsData = createStore<clientsApi.ClientsRequestData>(clientsInitState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'orders', key: 'tableSettings' });

export const initRefundsListPage = createEvent<ordersApi.OrdersRequestData>();
export const submitChangeReqData = createEvent<ordersApi.OrdersRequestData>();
export const changeClientsData = createEvent<clientsApi.ClientsRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();

export const getOrdersFx = createEffect(ordersApi.getOrdersList);
export const getClientsFx = createEffect(clientsApi.getClientsList);

$orders.on(getOrdersFx.doneData, (_, data: IncomeOrders) => ({
  pagination: data.pagination,
  items: data.items.map(order => ({ ...order, client: data.clients[order.client_id] })),
}));
$requestData.on([submitChangeReqData, initRefundsListPage], (_, data) => data);
$clients.on(getClientsFx.doneData, (_, data) => data);
$clientsData.on(changeClientsData, (_, data) => data);

sample({
  clock: [initRefundsListPage, submitChangeReqData],
  target: getOrdersFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: changeClientsData,
  source: $clientsData,
  target: getClientsFx,
});
