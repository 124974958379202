export const dataMock = [
  {
    name: '01 Янв',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '02 Янв',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '03 Янв',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '04 Янв',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '05 Янв',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '06 Янв',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '07 Янв',
    pv: 4300,
    amt: 2100,
  },
  {
    name: '08 Янв',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '09 Янв',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '10 Янв',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '11 Янв',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '12 Янв',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '13 Янв',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '14 Янв',
    pv: 4300,
    amt: 2100,
  },
];
