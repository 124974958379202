import { AxiosError } from 'axios';
import { createStore, createEffect, createEvent, sample } from 'effector';
import { productsApi, postApi, categoriesApi } from 'src/shared/api';
import { editProductFx } from 'src/entities/addProduct/ui/info/model';
import { Product, ProductComplaint } from 'src/pages/products/types';
import { Categories } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/addSource/types';
import { DetailedCategory } from 'src/entities/updateSource/types';
import { setError } from 'src/features/notifications';
import { v4 as uuid } from 'uuid';
import { ProductParsePost, ProductParseSize } from './types';

export const $product = createStore<Product | null>(null);
export const $productComplaint = createStore<ProductComplaint | null>(null);
export const $categories = createStore<DetailedCategory[] | null>(null);
export const $productId = createStore<string>('');
export const $productParsePost = createStore<ProductParsePost | null>(null);
export const $postDescription = createStore<string[]>([]);
export const $prevPath = createStore<string>('');

export const initProductPage = createEvent<string>();
export const submitParsePostReqData = createEvent<string>();
export const getComplaintPageInit = createEvent<productsApi.ComplaintProductReqData>();
export const submitParsePostData = createEvent<number>();
export const submitActualPostReqData = createEvent<number>();
export const resetProductParsePost = createEvent();
export const initPrevPathData = createEvent<string>();

export const getProductFx = createEffect(productsApi.getProduct);
export const getProductComplaintFx = createEffect(productsApi.getProductComplaint);
export const getProductParsePostFx = createEffect<string, null, AxiosError<{ message: string }>>(
  postApi.getProductParsingPost,
);
export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const submitProductParseFx = createEffect(postApi.parsePost);
export const getActualPostInfoFx = createEffect(postApi.getActualPostInfo);

$product.on(getProductFx.doneData, (_, data) => data);
$productComplaint.on(getProductComplaintFx.doneData, (_, data) => data);
$categories.on(getCategoriesFx.doneData, (_, data: Categories) => {
  const resultData: DetailedCategory[] = data?.items ? data.items.map(item => ({ ...item, key: item?.id || '' })) : [];
  return resultData;
});
$productId.on(initProductPage, (_, data) => data);
$productParsePost
  .on(
    [getProductParsePostFx.doneData, submitProductParseFx.doneData, getActualPostInfoFx.doneData],
    (_, data: ProductParsePost) => {
      const resultData: ProductParseSize[] = data?.post?.sizes
        ? data.post.sizes.map(size => ({ ...size, id: uuid() }))
        : [];
      data.post.sizes = resultData;
      return data;
    },
  )
  .reset(resetProductParsePost);
$postDescription.on(
  [getProductParsePostFx.doneData, submitProductParseFx.doneData, getActualPostInfoFx.doneData],
  (_, data: ProductParsePost) => {
    const resultData = data?.post?.description?.split('\n') || [];
    return resultData;
  },
);
$prevPath.on(initPrevPathData, (_, data) => data);

sample({
  clock: initProductPage,
  target: getProductFx,
});

sample({
  clock: submitParsePostReqData,
  target: [getProductParsePostFx, getCategoriesFx],
});

sample({
  clock: submitActualPostReqData,
  target: getActualPostInfoFx,
});

sample({
  clock: getComplaintPageInit,
  target: getProductComplaintFx,
});

sample({
  clock: editProductFx.doneData,
  source: $productId,
  target: getProductFx,
});

sample({
  clock: submitParsePostData,
  target: submitProductParseFx,
});

sample({
  clock: submitProductParseFx.doneData,
  source: $productId,
  target: getProductFx,
});

sample({
  clock: getProductParsePostFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка',
  target: setError,
});
