export enum CaptchaSettingsKeys {
  ClientCaptcha = 'client_registrations_without_captcha',
  IntermedCaptcha = 'intermediary_registrations_without_captcha',
  SupplierCaptcha = 'supplier_registrations_without_captcha',
}

export interface CaptchaTypes {
  client_registrations_without_captcha: string;
  intermediary_registrations_without_captcha: string;
  supplier_registrations_without_captcha: string;
}

export interface CaptchaSetting {
  id: number;
  key: CaptchaSettingsKeys;
  value: string;
}

export interface Settings {
  settings: CaptchaSetting[];
}

export interface resultCaptcha {
  [key: string]: string;
}
