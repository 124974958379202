import { createStore, createEffect, createEvent, sample } from 'effector';
import { productsApi } from 'src/shared/api';
import { editProductFx } from 'src/entities/addProduct/ui/info/model';
import { Product } from 'src/pages/products/types';

export const $product = createStore<Product | null>(null);
export const $productId = createStore<string>('');

export const initEditProductPage = createEvent<string>();

export const getProductFx = createEffect(productsApi.getProduct);

$product.on(getProductFx.doneData, (_, data) => data);
$productId.on(initEditProductPage, (_, data) => data);

sample({
  clock: initEditProductPage,
  target: getProductFx,
});

sample({
  clock: editProductFx.doneData,
  source: $productId,
  target: getProductFx,
});
