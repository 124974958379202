import { Rule } from 'antd/lib/form';
import { getNumbers } from 'src/shared/lib';

export const smzSelectOptions = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
];

export const ruleOnlyNumbers = {
  pattern: /^[0-9]+$/i,
  message: 'только цифры',
  validateTrigger: 'onChange',
};

export const ruleNames = {
  pattern: /([а-яёA-Za-z]+(?:[ -][a-яё ]+)*[ -]?|)$/i,
  message: 'Имя может содержать только кириллицу, латиницу, дефис и пробелы',
  validateTrigger: 'onChange',
};

export const legalEntityFormRules = {
  fullName: [
    { required: true, message: 'Пожалуйста, введите полное название' },
    { max: 255, message: 'Полное название не должно содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  name: [
    { required: true, message: 'Пожалуйста, введите сокращенное название' },
    {
      pattern: /^[~`!@#$%^&*()_+=[\]{}|;':",.\\/<>?а-яёА-ЯЁ0-9- ]+$/i,
      message: 'только кириллица, цифры и символы',
      validateTrigger: 'onChange',
    },
    { max: 255, message: 'Сокращенное название не должно содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  billingDescriptor: [
    { required: true, message: 'Пожалуйста, введите название на иностранном языке' },
    {
      pattern: /^[~`!@#$%^&*()_+=[\]{}|;':",.\\/<>?a-zA-Z0-9- ]+$/i,
      message: 'только латиница, цифры и символы',
      validateTrigger: 'onChange',
    },
  ],
  inn: [{ required: true, message: 'Пожалуйста, введите ИНН' }, ruleOnlyNumbers],
  kpp: [
    { required: true, message: 'Пожалуйста, введите КПП' },
    { min: 9, message: 'КПП должен содержать не менее 9 символов', validateTrigger: 'onChange' },
    ruleOnlyNumbers,
  ],
  firstName: [
    { required: true, message: 'Пожалуйста, введите имя' },
    ruleNames,
    { max: 255, message: 'Имя не должно содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  lastName: [
    { required: true, message: 'Пожалуйста, введите фамилию' },
    ruleNames,
    { max: 255, message: 'Фамилия не должна содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  middleName: [
    { required: true, message: 'Пожалуйста, введите отчество' },
    ruleNames,
    { max: 255, message: 'Отчество не должно содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  birthDate: [{ required: true, message: 'Пожалуйста, введите дату рождения' }],
  account: [
    { required: true, message: 'Пожалуйста, введите реквизиты счета' },
    { min: 20, message: 'Реквизиты должны содержать не менее 20 символов', validateTrigger: 'onChange' },
    ruleOnlyNumbers,
  ],
  bankName: [{ required: true, message: 'Пожалуйста, введите банк' }],
  bik: [
    { required: true, message: 'Пожалуйста, введите БИК' },
    { min: 9, message: 'БИК должен содержать не менее 9 символов', validateTrigger: 'onChange' },
    ruleOnlyNumbers,
  ],
  details: [
    { required: true, message: 'Пожалуйста, введите назначение платежа' },
    { max: 255, message: 'Назначение платежа не должно содержать более 255 символов', validateTrigger: 'onChange' },
  ],
  tax: [
    { required: true, message: 'Пожалуйста, введите комиссию маркетплейсу' },
    {
      pattern: /^[0-9.,]*$/i,
      message: 'только цифры и "." или ","',
      validateTrigger: 'onChange',
    },
    {
      message: 'Комиссия должна быть не менее 0',
      validator(_: Rule, value: string) {
        if (value && Number(value) < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      validateTrigger: 'onChange',
    },
    {
      message: 'Комиссия не должна быть более 100',
      validator(_: Rule, value: string) {
        if (value && Number(value) > 100) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      validateTrigger: 'onChange',
    },
  ],
  address: [{ required: true, message: 'Пожалуйста, выберите тип адреса' }],
  zip: [{ required: true, message: 'Пожалуйста, введите почтовый индекс' }, ruleOnlyNumbers],
  city: [
    { required: true, message: 'Пожалуйста, введите город или населенный пункт' },
    {
      min: 3,
      message: 'Поле город или населенный пункт должно содержать не менее 3 символов',
      validateTrigger: 'onChange',
    },
  ],
  street: [{ required: true, message: 'Пожалуйста, введите улицу, дом, корпус, квартиру, офис' }],
  addressCountry: [
    {
      required: true,
      message: 'Пожалуйста, введите код страны по ISO',
    },
    {
      pattern: /^[a-zA-Z]+$/i,
      message: 'только латиница',
      validateTrigger: 'onChange',
    },
    { min: 3, message: 'Длина кода страны должна составлять не менее 3 символов', validateTrigger: 'onChange' },
  ],
  country: [
    {
      required: true,
      message: 'Пожалуйста, введите гражданство',
    },
    {
      pattern: /^[a-zA-Z]+$/i,
      message: 'только латиница',
      validateTrigger: 'onChange',
    },
    {
      min: 3,
      message: 'Длина наименования гражданства должна составлять не менее 3 символов',
      validateTrigger: 'onChange',
    },
    {
      max: 3,
      message: 'Длина наименования гражданства не должна составлять более 3 символа',
      validateTrigger: 'onChange',
    },
  ],
  phone: [
    { required: true, message: 'Пожалуйста, введите телефон' },
    {
      pattern: /(?=.*^7|\+7)/,
      message: 'Телефон должен начинаться с +7',
    },
    {
      message: 'Длина телефона должна составлять не менее 11 символов',
      validator(rule: Rule, value: string) {
        const phoneNumbers = value ? getNumbers(value) : '';
        if (phoneNumbers.length < 11) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      validateTrigger: 'onChange',
    },
  ],
  email: [
    { required: true, message: 'Пожалуйста, введите email' },
    {
      pattern: /^[^А-Яа-я]*$/i,
      message: 'только латиница, цифры и символы',
      validateTrigger: 'onChange',
    },
  ],
  siteUrl: [
    {
      required: true,
      message: 'Пожалуйста, введите веб-сайт',
    },
    {
      pattern: /^(http|https):\/\//,
      message: 'Веб-сайт должен начинаться с http:// или https://',
      validateTrigger: 'onChange',
    },
  ],
};

export const phoneMask = [
  '+',
  /\d/,
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const legalEntityFormTooltips = {
  billingDescriptor: 'Введите название юр. лица на английском языке. Допускаются так же цифры и символы.',
  fullName: 'Введите полное наименование юр. лица.',
  name: 'Введите сокращенное наименование организации. Необходимо заполнить кириллицей и обязательно указать организационно-правовую форму (например ОАО, ЗАО, ИП)',
  inn: 'Введите ИНН. Допускается введение только цифр! Количество символов в ИНН должно быть до 12 символов.',
  kpp: 'Введите КПП. Допускается введение только цифр. Если КПП нет (Самозанятый, ИП), то нужно заполнить все нули.',
  ogrn: 'Введите ОГРН. Если юр. лицо - ИП, то введите ОГРНИП.',
  smz: 'Отметьте, если юр. лицо является самозанятым.',
  address: 'Выберите тип адреса организации. Если ОПФ организации - ИП, тип адреса должен быть "Юридический".',
  addressCountry: 'Введите код страны (например, для России - RUS)',
  street: 'Введите адрес в формате "Улица, дом, корпус, квартира/офис". Например: ул. Ленина, д. 21, офис 5.',
  email: 'Введите e-mail организации.',
  middleName: 'Поставьте прочерк, если отчество отсутствует.',
  siteUrl: 'Введите ссылку на сервис юр.лица (сайт, соц.сеть, ссылка для связи в мессенджере).',
  account: 'Введите данные расчетного счета (20 символов, только цифры). Пример: 40702840000000001221',
  bankName: 'Введите наименование банка.',
  bik: 'Введите БИК банка (9 символов, только цифры).',
  bankInfo:
    'Если расчетным банком является Тинькофф Банк, введите реквизиты партнера агрегатора для перечисления возмещения.',
  ceoInfo: 'Введите сведения о руководителе организации.',
  city: 'При заполнении использовать непробельные символы.',
};

export const legalEntityFieldLength = {
  inn: 12,
  kpp: 9,
  country: 3,
  account: 20,
  bik: 9,
  email: 63,
};

export const defaultValidateStatus = undefined;
