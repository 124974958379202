export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const complaintsOptions = {
  incorrect_product_description: 'Некорректное описание продукта',
  photo_name_or_description_does_not_match: 'Имя, описание или фото не совпадают',
  product_contains_inappropriate_content: 'Товар содержит неприемлимый контент',
};
