import { DragEvent } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadListType } from 'antd/lib/upload/interface';

const { Dragger } = Upload;

export type { UploadChangeParam, UploadFile };

interface IDragAndDropProps {
  name?: string;
  multiple?: boolean;
  isDisabled?: boolean;
  listType?: UploadListType;
  maxCount?: number;
  fileList?: any[];
  defaultFileList?: UploadFile<any>[];
  setFileList?: (list: File[]) => void;
  action?: string; // 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
  onDrop?: (event: DragEvent<HTMLDivElement>) => void;
  onSuccess?: (info: UploadChangeParam<UploadFile<any>>) => void;
  onError?: (info: UploadChangeParam<UploadFile<any>>) => void;
}

export const DragAndDrop = ({
  name = 'file',
  multiple = true,
  isDisabled = false,
  listType = 'picture',
  action,
  fileList,
  maxCount,
  defaultFileList,
  setFileList,
  onDrop,
  onSuccess,
  onError,
}: IDragAndDropProps) => (
  <Dragger
    disabled={isDisabled}
    name={name}
    listType={listType}
    multiple={multiple}
    maxCount={maxCount}
    defaultFileList={defaultFileList}
    onRemove={(file: UploadFile) => {
      if (fileList && setFileList) {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      }
    }}
    beforeUpload={file => {
      if (fileList && setFileList) {
        if (maxCount === 1) {
          setFileList([file]);
        } else {
          setFileList([...fileList, file]);
        }
        return false;
      }
      return true;
    }}
    fileList={fileList}
    onDrop={onDrop}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Выберите файл</p>
    <p className="ant-upload-hint">или перетащите сюда</p>
  </Dragger>
);
