import { useEffect, useRef, useState } from 'react';
import { Button, Space, TreeSelect } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import { getSearchTypeName } from './helpers';
import type { InputRef } from 'antd';
import { ColumnTitle } from 'antd/lib/table/interface';
import { defaultSelectValue, treeSelectFieldNames } from 'src/shared/config';
import { DetailedCategory } from 'src/entities/updateSource/types';
import styles from './useColumn.module.less';

function useColumnTreeSelect<DataType>(
  selectHandler: (value: string, type: string) => void,
  data: DetailedCategory[],
  filterType: string,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  defaultValue?: string | number,
) {
  const searchInput = useRef<InputRef>(null);
  const [value, setValue] = useState(defaultValue?.toString());
  const [isActive, setIsActive] = useState(!!defaultValue);

  useEffect(() => {
    setValue(defaultValue?.toString());
    setIsActive(!!defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setValue('');
      setIsActive(false);
      setIsActiveFilter(false);
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const handleSelect = (value: string) => {
    setValue(value);
    selectHandler(value, filterType);
    setIsActive(true);
  };

  const handleReset = (clearFilters: () => void) => {
    setValue('');
    selectHandler('', filterType);
    clearFilters();
    setIsActive(false);
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className={styles.useColumnTreeSelect}>
        <TreeSelect
          value={value || defaultSelectValue}
          placeholder={`Поиск по ${getSearchTypeName(String(dataIndex))}`}
          placement="topRight"
          fieldNames={treeSelectFieldNames}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={data}
          onChange={handleSelect}
        />
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="middle">
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnTreeSelect };
