import { createEffect, createEvent, createStore, sample } from 'effector';
import { legalEntitiesApi } from 'src/shared/api';
import { changeSuppLegalFx } from 'src/features/changeLegalEntity/model';
import { setError, setSuccess } from 'src/features/notifications';
import { AxiosError } from 'axios';
import { initLegalEntityId } from './config';
import { archiveErrorStatus } from 'src/shared/config';
import { LegalEntityDetailed, LegalEntityDetailedData } from './types';
import { IIsInternalRequest } from 'shared/api/queries/legalEntitiesApi';

export const $legalEntity = createStore<LegalEntityDetailedData | null>(null);
export const $tinkoffError = createStore<string | null>(null);
export const $legalEntityId = createStore<string>(initLegalEntityId);
export const $isPrevPath = createStore(false);
export const $isArchiveError = createStore(false);

export const initLegalEntityPage = createEvent<string>();
export const resetTinkoffError = createEvent();
export const setIsPrevPath = createEvent<boolean>();
export const submitTinkoffReqData = createEvent<string>();
export const submitDeleteLegalEntity = createEvent<string>();
export const submitArchLegalReqData = createEvent<string | number>();
export const submitExtrLegalReqData = createEvent<string | number>();
export const resetArchiveError = createEvent();
export const submitIsInternalData = createEvent<IIsInternalRequest>();

export const getLegalEntityFx = createEffect(legalEntitiesApi.getLegalEntity);
export const regLegalTinkoffFx = createEffect<string, LegalEntityDetailed, AxiosError<{ message: string }>>(
  legalEntitiesApi.regLegalEntityTinkoff,
);
export const deleteLegalEntityFx = createEffect<string, LegalEntityDetailed, AxiosError<{ message: string }>>(
  legalEntitiesApi.deleteLegalEntity,
);
const successRedirectFx = createEffect(() => {
  if (window) {
    window.history.back();
  }
});
export const archiveLegalEntityFx = createEffect<string | number, null, AxiosError<{ message: string }>>(
  legalEntitiesApi.archiveLegalEntity,
);
export const extractLegalEntityFx = createEffect<string | number, null, AxiosError<{ message: string }>>(
  legalEntitiesApi.extractLegalEntity,
);
export const isInternalLegalEntityFx = createEffect<
  IIsInternalRequest,
  LegalEntityDetailed,
  AxiosError<{ message: string }>
>(
  legalEntitiesApi.isInternalLegalEntity,
);

$legalEntity.on(getLegalEntityFx.doneData, (_, data) => data);
$tinkoffError.on(regLegalTinkoffFx.failData, (_, data) => data.response?.data?.message).reset(resetTinkoffError);
$legalEntityId.on(initLegalEntityPage, (_, data) => data);
$isPrevPath.on(setIsPrevPath, (_, data) => data);
$isArchiveError.reset(resetArchiveError);
$legalEntity.on(isInternalLegalEntityFx.doneData, (entity, data) => {
  if (entity) {
    return {
      ...entity,
      legalEntity: data
    }
  }
  return entity;
});

sample({
  clock: initLegalEntityPage,
  target: getLegalEntityFx,
});

sample({
  clock: submitTinkoffReqData,
  target: regLegalTinkoffFx,
});

sample({
  clock: submitDeleteLegalEntity,
  target: deleteLegalEntityFx,
});

sample({
  clock: [regLegalTinkoffFx.done, changeSuppLegalFx.done, archiveLegalEntityFx.done, extractLegalEntityFx.done],
  source: $legalEntityId,
  target: getLegalEntityFx,
});

sample({
  clock: submitArchLegalReqData,
  target: archiveLegalEntityFx,
});

sample({
  clock: submitExtrLegalReqData,
  target: extractLegalEntityFx,
});

sample({
  clock: submitIsInternalData,
  target: isInternalLegalEntityFx,
});

sample({
  clock: archiveLegalEntityFx.failData,
  filter: data => data.response?.status === archiveErrorStatus,
  fn: () => true,
  target: $isArchiveError,
});

sample({
  clock: regLegalTinkoffFx.done,
  fn: () => 'Юридическое лицо успешно зарегистрировано в Тинькофф',
  target: setSuccess,
});

sample({
  clock: deleteLegalEntityFx.done,
  fn: () => 'Юридическое лицо успешно удалено',
  target: [successRedirectFx, setSuccess],
});

sample({
  clock: regLegalTinkoffFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при регистрации юридического лица в Тинькофф',
  target: setError,
});

sample({
  clock: deleteLegalEntityFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении юридического лица',
  target: setError,
});

sample({
  clock: archiveLegalEntityFx.doneData,
  fn: () => 'Юр.лицо успешно отправлено в архив',
  target: setSuccess,
});

sample({
  clock: archiveLegalEntityFx.failData,
  filter: data => data.response?.status !== archiveErrorStatus,
  fn: data => data.response?.data?.message || 'Ошибка при отправке юр.лица в архив',
  target: setError,
});

sample({
  clock: extractLegalEntityFx.doneData,
  fn: () => 'Юр.лицо успешно возвращено из архива',
  target: setSuccess,
});

sample({
  clock: extractLegalEntityFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при попытке вернуть юр.лицо из архива',
  target: setError,
});

sample({
  clock: isInternalLegalEntityFx.doneData,
  fn: () => 'Тип ЮЛ успешно изменен',
  target: setSuccess,
});

sample({
  clock: isInternalLegalEntityFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при смене типа ЮЛ',
  target: setError,
});