import { ProductsListRequestData } from 'shared/api/queries/productsApi';

const PER_PAGE = 10;

export const initState: ProductsListRequestData = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};
