import { createEffect, createEvent, createStore, sample } from 'effector';
import { addressApi } from 'src/shared/api';
import { initReqData } from './config';
import { setError, setSuccess } from 'src/features/notifications';
import { AddressData } from './types';

export const $addresses = createStore<AddressData | null>(null);
export const $clientsId = createStore<addressApi.getAddReqData>(initReqData);

export const initClientsAddressPage = createEvent<addressApi.getAddReqData>();
export const initIdClientsAddressPage = createEvent<addressApi.getAddReqData>();
export const submitAddAddressReqData = createEvent<addressApi.AddAddressReqData>();
export const submitUpdateAddressReqData = createEvent<addressApi.UpdateAddressReqData>();
export const submitRemoveAddressReqData = createEvent<string | number>();

export const getAddressesFx = createEffect(addressApi.getAddresses);
export const addAddressFx = createEffect(addressApi.addAddress);
export const updateAddressFx = createEffect(addressApi.updateAddress);
export const removeAddressFx = createEffect(addressApi.removeAddress);

$addresses.on(getAddressesFx.doneData, (_, data) => data);
$clientsId.on(initIdClientsAddressPage, (_, data) => data);

sample({
  clock: initClientsAddressPage,
  target: getAddressesFx,
});

sample({
  clock: submitAddAddressReqData,
  target: addAddressFx,
});

sample({
  clock: submitUpdateAddressReqData,
  target: updateAddressFx,
});

sample({
  clock: submitRemoveAddressReqData,
  target: removeAddressFx,
});

sample({
  clock: [addAddressFx.doneData, updateAddressFx.doneData, removeAddressFx.doneData],
  source: $clientsId,
  target: getAddressesFx,
});

sample({
  clock: [addAddressFx.failData, updateAddressFx.failData, removeAddressFx.failData],
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: addAddressFx.doneData,
  fn: () => 'Адрес успешно добавлен',
  target: setSuccess,
});

sample({
  clock: updateAddressFx.doneData,
  fn: () => 'Адрес успешно изменен',
  target: setSuccess,
});

sample({
  clock: removeAddressFx.doneData,
  fn: () => 'Адрес успешно удален',
  target: setSuccess,
});
