import { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import {
  $task,
  $qrCode,
  initTaskPage,
  clearQrData,
  submitTransTaskData,
  submitRefuseTaskData,
  submitTasksQrReqData,
  getTaskFx,
} from './model';
import { useStore } from 'effector-react';
import { Button, Card, Space, Spin } from 'antd';
import { BackArrow } from 'src/entities/backArrow';
import { ProductInfoCard } from 'src/entities/productInfoCard';
import { useIsStorekeeper } from 'src/widgets/header';
import { InfoCard } from 'src/entities/infoCard';
import { orderStatus, taskStatus } from 'src/shared/config';
import { Title } from 'src/shared/ui';
import { TaskCount } from './ui/taskCount';
import { TaskStatus } from './ui/taskStatus';
import { TransferHistory } from './ui/transferHistory';
import { QrCode } from './ui/QrCode';
import { ScanCode } from 'src/features/scanCode';
import { TaskTransfer } from './types';
import { mainShowStatuses } from './config';
import styles from './Task.module.less';
import { CancelIntermedaryModal } from 'src/entities/ordersProductsInfo/components/CancelIntermedaryModal';
import { initOrderPage, submitCancelIntermedary, submitChangeStatus } from 'src/pages/orders/order/model';
import { $order } from 'src/pages/orders/order/model';
import { availableCancelIntermediary } from 'src/utils';
import { ChangeStatusModal } from 'src/entities/ordersProductsInfo/components/ChangeStatusModal';

export const TaskPage = () => {
  const { id } = useParams<{ id: string }>();
  const prevPath = useLocation().state as { prevPath: boolean };
  const task = useStore($task);
  const qrCode = useStore($qrCode);
  const isLoading = useStore(getTaskFx.pending);
  const isStorekeeper = useIsStorekeeper();
  const [isCountActive, setIsCountActive] = useState(false);
  const [showIntermediaryModal, setShowIntermediaryModal] = useState<boolean>(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
  const order = useStore($order);

  useEffect(() => {
    if (id) {
      initTaskPage(id);
    }
  }, [id]);

  useEffect(() => {
    if (task?.task?.order?.id) {
      initOrderPage(String(task?.task?.order?.id))
    }
  }, [task])

  const suppData = {
    id: task?.supplier_pavilion?.supplier?.id,
    name: task?.supplier_pavilion?.supplier?.name,
    phone: task?.supplier_pavilion?.supplier?.phone,
    pavilion: task?.supplier_pavilion?.pavilion?.name,
  };

  const handleTasksQr = () => {
    if (id) {
      submitTasksQrReqData(id);
    }
  };

  const handleTransferTask = (values: TaskTransfer) => {
    if (id) {
      submitTransTaskData({ id, data: values });
    }
  };

  const handleRefuseItems = (value: string) => {
    if (id) {
      submitTransTaskData({ id, data: { product_count: -1 * +value } });
    }
  };

  const handleRefuseTask = () => {
    if (id) {
      submitRefuseTaskData(id);
    }
  };

  const handleCount = (isActive: boolean) => setIsCountActive(isActive);

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }
  
  if (!task || !order) {
    return null;
  }

  return (
    <>
      <section className={styles.task}>
        <div className={styles.buttons}>{prevPath ? <BackArrow /> : null}</div>
        <div className={styles.content}>
          <div className={styles.column}>
            <ProductInfoCard task={task} />
            <TaskStatus task={task.task} setShowChangeStatusModal={setShowChangeStatusModal} />
            <TransferHistory data={task?.history} />
          </div>
          <div className={styles.column}>
            {task?.task.status && mainShowStatuses.includes(task?.task.status) ? (
              <div onClick={() => handleCount(true)} onBlur={() => handleCount(false)} role="presentation">
                <TaskCount
                  successHandler={handleTransferTask}
                  code={task?.task.code}
                  cancelHandler={handleRefuseItems}
                  showCancel={!isStorekeeper}
                  isCountActive={isCountActive}
                />
              </div>
            ) : null}
            <InfoCard title="Клиент" data={task?.client} type="client" comment={task?.task?.comment} />
            <InfoCard
              title="Посредник"
              data={task?.intermediary}
              takenToWorkDate={task?.task?.take_in_work_date}
              type="intermediary"
              action={availableCancelIntermediary.includes(order.order.status) && task.intermediary?.id
                ? <Button
                    type="default"
                    onClick={() => setShowIntermediaryModal(true)}>
                    Снять посредника
                  </Button>
                : null
              }
            />
            <InfoCard title="Поставщик" data={suppData} source={task?.post?.source} type="supplier" />
            {task?.chat?.id ? (
              <Card>
                <h2 className={styles.title}>Переписка по заданию</h2>
                <Link to="/chat" state={{ id: task.chat.id }}>
                  <Button>Открыть</Button>
                </Link>
              </Card>
            ) : null}
            <QrCode qrCodeHandler={handleTasksQr} qrCode={qrCode} clearData={clearQrData} />
            {!isStorekeeper &&
            task?.task.status === taskStatus.inStock &&
            task.task.order.status === orderStatus.redeemed ? (
              <Card>
                <Title>Товары не соответствуют описанию?</Title>
                <Space align="end" direction="vertical" style={{ width: '100%' }}>
                  <Button type="primary" onClick={handleRefuseTask}>
                    Отклонить
                  </Button>
                </Space>
              </Card>
            ) : null}
          </div>
          {task?.task?.status && mainShowStatuses.includes(task.task.status) ? (
            <div className={styles.column}>
              <ScanCode isCountActive={isCountActive} />
            </div>
          ) : null}
        </div>
      </section>
      {showIntermediaryModal
        && <CancelIntermedaryModal
          isOpen={showIntermediaryModal}
          setIsOpen={setShowIntermediaryModal}
          confirmHandler={() => submitCancelIntermedary(task.task.id)}
          item={{
            data: order.order.items.find(item => item.code === task.task.code),
            task: order.tasks.find(item => Number(item.task.id) === Number(task.task.id)),
          }}
        />
      }
      {showChangeStatusModal
        && <ChangeStatusModal
          isOpen={showChangeStatusModal}
          setIsOpen={setShowChangeStatusModal}
          confirmHandler={() => submitChangeStatus(task.task.id)}
          item={{
            data: order.order.items.find(item => item.code === task.task.code),
            task: order.tasks.find(item => Number(item.task.id) === Number(task.task.id)),
          }}
        />
      }
      {/* http://localhost:3000/orders?code=5728&page=1
      http://localhost:3000/task/1173 */}
      
    </>
  );
};
