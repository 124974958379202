export interface DataType {
  id: string;
  error: string;
  date: Date;
  status: number;
  product_id: number;
}

export const PER_PAGE = 10;

const logStatuses = {
  0: 'Успешно',
  1: 'Ошибка',
  2: 'Подготовка',
  3: 'Подготовка к деактивации',
  4: 'Деактивирован',
};

export const statusOptions = Object.entries(logStatuses).map(([key, value]) => ({
  label: value,
  value: key,
}));
