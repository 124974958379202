export const getErrorFieldName = (data: string, value?: string) => {
  const fieldNames = {
    full_name: 'legal_entity.full_name',
    name: 'legal_entity.name',
    billing_descriptor: 'legal_entity.billing_descriptor',
    inn: 'legal_entity.inn',
    kpp: 'legal_entity.kpp',
    smz: 'legal_entity.smz',
    ogrn: 'legal_entity.ogrn',
    first_name: 'legal_entity.ceo.first_name',
    last_name: 'legal_entity.ceo.last_name',
    middle_name: 'legal_entity.ceo.middle_name',
    birth_date: 'legal_entity.ceo.birth_date',
    country: 'legal_entity.ceo.country',
    account: 'legal_entity.bank_account.account',
    bank_name: 'legal_entity.bank_account.bank_name',
    bik: 'legal_entity.bank_account.bik',
    details: 'legal_entity.bank_account.details',
    tax: 'legal_entity.bank_account.tax',
    phone: 'legal_entity.ceo.phone',
    email: 'legal_entity.email',
    site_url: 'legal_entity.site_url',
    [`zip-${value}`]: `addresses.${value}.zip`,
    [`city-${value}`]: `addresses.${value}.city`,
    [`street-${value}`]: `addresses.${value}.street`,
    [`country-${value}`]: `addresses.${value}.country`,
  };

  return fieldNames?.[data] ? fieldNames[data] : data;
};
