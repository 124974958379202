import { Tag } from 'antd';
import { statusColors } from 'src/shared/config';

export const renderStatus = (status: number) => {
  switch (status) {
    case 0:
      return <Tag style={statusColors.green}>Успешно</Tag>;
    case 1:
      return <Tag style={statusColors.red}>Ошибка</Tag>;
    case 2:
      return <Tag style={statusColors.blue}>Подготовка</Tag>;
    case 3:
      return <Tag style={statusColors.brown}>Подготовка к деактивации</Tag>;
    case 4:
      return <Tag style={statusColors.gray}>Деактивирован</Tag>;
    default:
      return '-';
  }
};
