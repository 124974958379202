import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import styles from './NameProfilesCard.module.less';

interface INameProfilesCardProps {
  data?: {
    avatar_url: string;
    name: string;
  };
  show?: boolean;
}

export const NameProfilesCard = ({ data, show = true }: INameProfilesCardProps) => (
  <div className={styles.card}>
    <Avatar shape="circle" size="large" icon={<PictureOutlined />} src={data!.avatar_url} />
    <p className={styles.name}>{data!.name}</p>
  </div>
);
