export const trimNullsFromString = (data: string): string => {
  if (!data) return '';

  if (Number(data) === 10) return data;

  const decimalSymbols = ['.', ','];

  const dataArray = data.trim().split('');
  const startIndex = dataArray.findIndex(element => element !== '0');
  const endIndex = dataArray.findLastIndex(element => element !== '0');

  const isStartIndex = startIndex === -1;
  const isEndIndex = endIndex === -1;

  if (isStartIndex && isEndIndex) return '0';

  const slicedData = dataArray.slice(isStartIndex ? 0 : startIndex, isEndIndex ? dataArray.length : endIndex + 1);
  const decimalData =
    decimalSymbols.includes(slicedData[slicedData.length - 1]) && decimalSymbols.includes(slicedData[0])
      ? ['0']
      : slicedData;
  const decimalStartData = decimalSymbols.includes(decimalData[0]) ? ['0', ...decimalData] : decimalData;
  const resultData = decimalSymbols.includes(decimalStartData[decimalStartData.length - 1])
    ? decimalStartData.slice(0, decimalStartData.length - 1)
    : decimalStartData;

  return resultData.join('');
};
