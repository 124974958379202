import { useStore } from 'effector-react';
import Echo from 'laravel-echo';
import { useEffect } from 'react';
import { $echoClient, $echoTestClients, $elIndex } from 'src/shared/api/model';
import { Message } from 'src/shared/types';
import { getWSMessagesEvent } from 'src/pages/chat/ui/chatMessages/model';
import { getTestWSMessagesEvent } from 'src/pages/settings/chatTest/model';
import { $activeChat } from 'src/shared/api/model';

/**
 * Подписка на новые сообщения
 */
export const useTestEchoNewMessage = (chats: { id: number; access_token: string }[]) => {
  const echoClient = useStore($echoTestClients);
  const activeChat = useStore($activeChat);
  const idx = useStore($elIndex);

  useEffect(() => {
    // chats.forEach(({ id: chatId, access_token: accessToken }) => {
    if (!echoClient || !activeChat.id) return () => {};

    const channelChatId = `.${activeChat.id}`;
    const eventNewMessageChat = 'chat.message.created';

    const listenEvent = async <T>({
      echo,
      channel,
      event,
      callback,
    }: {
      echo: Echo;
      channel: string;
      event: string;
      callback: (data: T) => void;
    }) => {
      echo.join(channel).listen(event, () => {});

      echo.listen(`private-client${channel}`, `.${event}`, (data: T) => {
        callback(data);
      });
    };

    listenEvent<Message>({
      echo: echoClient,
      channel: channelChatId,
      event: eventNewMessageChat,
      callback: (data: Message) => getTestWSMessagesEvent(data),
    });

    return () => {
      echoClient.leaveChannel(`private-client${channelChatId}`);
      // resetPrevWSMessages();
    };
    // });
  }, [activeChat?.id, chats, echoClient, idx]);
};
