import { useEffect, useState } from 'react';
import { Button, Card, Image } from 'antd';
import { MainModal } from 'src/shared/ui';
import { TaskQr } from 'src/pages/task/types';
import styles from './QrCode.module.less';

interface QrCodeProps {
  qrCodeHandler: () => void;
  clearData: () => void;
  qrCode: TaskQr | null;
}

export const QrCode = ({ qrCodeHandler, clearData, qrCode }: QrCodeProps) => {
  const [qrState, setQrState] = useState<'show' | 'print' | null>(null);

  useEffect(() => {
    if (qrState === 'print' && qrCode?.url) {
      const WinPrint = window.open('');
      WinPrint?.document.write(`<html><head><script>function printImg(){window.print()}
      </script></head><body onload='printImg()'>
      <img src="${qrCode.url}" /></body></html>`);
      WinPrint?.document.close();
      setQrState(null);
      clearData();
    }
  }, [qrCode, qrState, clearData]);

  const handleReqQr = (type: 'show' | 'print') => {
    qrCodeHandler();
    setQrState(type);
  };

  const handleCloseQr = () => {
    setQrState(null);
    clearData();
  };

  return (
    <>
      <Card className={styles.card}>
        <h2 className={styles.title}>QR-код</h2>
        <div className={styles.buttons}>
          <Button onClick={() => handleReqQr('show')} className={styles.button}>
            Открыть
          </Button>
          <Button onClick={() => handleReqQr('print')} className={styles.button}>
            Напечатать
          </Button>
        </div>
      </Card>

      {qrCode?.url && (
        <MainModal isOpen={qrState === 'show'} closeHandler={handleCloseQr}>
          <Image preview={false} src={qrCode.url} />
        </MainModal>
      )}
    </>
  );
};
