import { Input, Dropdown, Switch } from 'antd';
import { ControlOutlined, MailOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import { useEffect, useRef, useState } from 'react';
import { Hooks } from 'src/shared/lib';
import { Loader } from 'src/shared/ui';

import { ChatItem } from '../chatItem';
import styles from './ModerChatList.module.less';
import { $chatList, getChatListEvent, getChatsFx, getChatListByScrollEvent } from './model';
import { initChatRequestState, PAGE_SIZE } from './config';

export const ModerChatList = () => {
  const chatItems = useStore($chatList);
  const isLoading = useStore(getChatsFx.pending);

  const listRef = useRef<HTMLUListElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    getChatListEvent(initChatRequestState);
  }, []);

  const handleScrollLoadData = (pageNumber: number) => {
    if (searchValue) {
      getChatListByScrollEvent({
        ...initChatRequestState,
        search: searchValue,
        pagination: { per_page: PAGE_SIZE, page: pageNumber },
      });
    } else {
      getChatListByScrollEvent({ ...initChatRequestState, pagination: { per_page: PAGE_SIZE, page: pageNumber } });
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (value) {
      getChatListEvent({
        ...initChatRequestState,
        search: value,
      });
    } else {
      getChatListEvent(initChatRequestState);
    }
  };

  const { handleScroll } = Hooks.useInfinitePagination({
    fetcher: handleScrollLoadData,
    listRef,
    wrapperRef,
    lastPage: chatItems?.pagination.last_page ?? 1,
  });

  return (
    <div className={styles.moderChatList}>
      <div className={styles.settings}>
        <Input.Search allowClear placeholder="Имя собеседника" onSearch={handleSearch} loading={isLoading} />
        {/* TODO добавить в следующем релизе */}
        {/* <Dropdown
          overlayClassName={styles.menu}
          placement="bottom"
          menu={{
            items: [
              {
                key: 'clients',
                label: (
                  <div className={styles['control-row']}>
                    <p>Клиенты</p>
                    <Switch size="small" />
                  </div>
                ),
              },
              {
                key: 'intermediaries',
                label: (
                  <div className={styles['control-row']}>
                    <p>Посредники</p>
                    <Switch size="small" />
                  </div>
                ),
              },
            ],
          }}>
          <ControlOutlined />
        </Dropdown> */}
      </div>

      <div ref={wrapperRef} className={styles.listWrapper} onScroll={handleScroll}>
        {isLoading ? <Loader /> : null}
        {chatItems?.items?.length ? (
          <ul ref={listRef}>
            {chatItems.items.map(el => (
              <li key={el.id}>
                <ChatItem item={el} />
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles.noMessagesWrapper}>
            <MailOutlined className={styles.noMessageIcon} />
            <span className={styles.noMessageTitle}>Нет чатов</span>
            <p className={styles.noMessageText}>
              Смените фильтрацию
              <br />
              или сообщите разработчикам
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
