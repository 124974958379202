import api from '../api';

export interface IntermedRequestData {
  name?: string;
  email?: string;
  phone?: string;
  created_at?: string;
  created_at_from?: string;
  created_at_to?: string;
  statuses?: string[];
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface IntermedStatusReqData {
  id: string | number;
  type: string;
}

export interface IntermediaryChangeData {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  avatar_url?: string;
  pass_number?: string;
  status?: string;
  pass_photo?: string;
  pass_valid_until?: string;
}

export const getIntermedList = (data: IntermedRequestData) =>
  api.post('/intermediaries', data).then(response => response.data);

export const getIntermediary = (id: string) => api.get(`/intermediary/${id}`).then(response => response.data);

export const setIntermedStatus = (data: IntermedStatusReqData) =>
  api.post(`/intermediary/${data.id}/${data.type}`).then(response => response.data);

export const editIntermediary = (data: IntermediaryChangeData) =>
  api.put(`/intermediary/${data.id}`, data).then(response => response.data);

export const getIntermediaryBalance = (id: string) =>
  api.get(`/transaction/${id}/balance`).then(response => response.data);

export const payIntermediaryBalance = (id: string) =>
  api.post(`/transaction/${id}/resolve`).then(response => response.data);

export const deleteIntermediary = (id: string) =>
  api.delete(`/intermediary/${id}/soft`).then(response => response.data);

export const forceDeleteIntermediary = (id: string) =>
  api.delete(`/intermediary/${id}/force`).then(response => response.data);
