import { ChatMessagesResponseData } from 'shared/api/queries/chatMessagesApi';

export const PAGE_SIZE = 10;

export const initMessagesState: ChatMessagesResponseData = {
  items: [],
  pagination: {
    per_page: PAGE_SIZE,
    current_page: 1,
    total: 0,
    last_page: 0,
  },
};
