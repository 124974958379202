import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Search {
  [k: string]: string;
}

interface IncomeSearch {
  [k: string]: string | number | null | string[];
}

const useSearchParamsObject = (): [Search, (s: IncomeSearch) => void] => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);

  const transformAndSetSearch = (search: IncomeSearch) => {
    const data = Object.keys(search).reduce(
      (prev, curr) => (search[curr] ? { ...prev, [curr]: search[curr]?.toString() } : prev),
      {},
    );
    setSearch(data);
  };

  return [searchAsObject, transformAndSetSearch];
};

export { useSearchParamsObject };
