import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import styles from './BackArrow.module.less';

export const BackArrow = ({ prevPath }: { prevPath?: string }) => {
  const navigate = useNavigate();

  const routeChange = () => {
    if (prevPath) {
      navigate(prevPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <button className={styles['back-button']} type="button" onClick={routeChange}>
      <ArrowLeftOutlined className={styles.icon} />
      <span>Назад</span>
    </button>
  );
};
