import { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import styles from './useColumn.module.less';
import { ColumnTitle } from 'antd/lib/table/interface';
import dayjs from 'dayjs';

function useColumnSearchDate<DataType>(
  searchHandler: (value: { date_after: string; date_to: string }, fieldType: string) => void,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  fieldType?: string,
) {
  const [form] = Form.useForm();
  const searchInput = useRef<InputRef>(null);
  const [dateAfterValue, setDateAfterValue] = useState('');
  const [dateToValue, setDateToValue] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setDateAfterValue('');
      setDateToValue('');
      setIsActive(false);
      setIsActiveFilter(false);
      form.resetFields();
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const handleSearch = () => {
    searchHandler({ date_after: dateAfterValue, date_to: dateToValue }, fieldType || '');
    setIsActive(true);
  };

  const handleReset = (clearFilters: () => void) => {
    searchHandler({ date_after: '', date_to: '' }, fieldType || '');
    setDateAfterValue('');
    setDateToValue('');
    clearFilters();
    setIsActive(false);
    form.resetFields();
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ clearFilters }) => (
      <div className={styles.useColumnDate}>
        <Form form={form} layout="horizontal" onFinish={handleSearch} autoComplete="off">
          <div className={styles.item}>
            <p>От</p>
            <Form.Item
              name="date_after"
              dependencies={['date_to']}
              rules={[
                ({ getFieldValue }) => ({
                  message: '',
                  validator(_, value) {
                    const dateAfter = dayjs(value);
                    const dateTo = dayjs(getFieldValue('date_to') || 0);
                    const datesDiff = dateAfter.diff(dateTo);
                    if (datesDiff <= 0 || !getFieldValue('date_to')) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}>
              <Input
                type="date"
                ref={searchInput}
                placeholder={`Search ${String(dataIndex)}`}
                value={dateAfterValue}
                onChange={e => {
                  setDateAfterValue(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className={styles.item}>
            <p>До</p>
            <Form.Item
              name="date_to"
              dependencies={['date_after']}
              rules={[
                ({ getFieldValue }) => ({
                  message: '',
                  validator(_, value) {
                    const dateAfter = dayjs(getFieldValue('date_after') || 0);
                    const dateTo = dayjs(value);
                    const datesDiff = dateTo.diff(dateAfter);
                    if (datesDiff >= 0 || !getFieldValue('date_after')) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}>
              <Input
                type="date"
                ref={searchInput}
                placeholder={`Search ${String(dataIndex)}`}
                value={dateToValue}
                onChange={e => {
                  setDateToValue(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <Space>
            <Button htmlType="submit" type="primary" icon={<SearchOutlined />} size="small">
              Поиск
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
              Сбросить
            </Button>
          </Space>
        </Form>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnSearchDate };
