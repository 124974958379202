import { ESupplierStatus } from 'pages/profiles/suppliers/types';
import api from '../api';
import { SupplierProfile } from 'src/pages/profiles/suppliersProfile/types';

export interface SuppRequestData {
  name?: string;
  email?: string;
  phone?: string;
  legal_entity_id?: string | number | null;
  pavilion_id?: number;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface EditSupplierParams {
  id: string;
  data: SupplierProfile;
}

export interface SupplierStatusReqData {
  id: string;
  type: string;
}

export interface AddSupplierPavReqData {
  suppId: string;
  pavId: string;
}

export interface ChangeSupplierLegalReqData {
  legal_entity_id: number | string;
  supplier_ids: number[] | string[];
}

export interface IUpdateSupplierStatusRequest {
  id: string;
  data: {
    status?: ESupplierStatus;
    is_own_legal_entity?: boolean;
    is_registered?: boolean;
  }
}

export interface IChangeTopStatusRequest {
  activate: boolean;
  supplier_id: number;
}

export interface IGetSupplierStatusesResponse {
  items: {
    status: ESupplierStatus;
    status_name: string;
  }[];
}

export const getSuppList = (data: SuppRequestData) => api.post('/suppliers', data).then(response => response.data);

export const getSupplier = (id: string) => api.get(`/supplier/${id}`).then(response => response.data);

export const editSupplier = ({ id, data }: EditSupplierParams) =>
  api.put(`/supplier/${id}`, data).then(response => response.data);

export const updateSupplierStatus = ({ id, data }: IUpdateSupplierStatusRequest) =>
  api.patch(`/supplier/${id}`, data).then(response => response.data);

export const changeTopStatus = (data: IChangeTopStatusRequest) =>
  api.post(`/supplier/change-top-status`, data).then(response => response.data);

export const change10PercentCommission = (data: IChangeTopStatusRequest) =>
  api.post(`/supplier/change-10-percent-commission`, data).then(response => response.data);

export const getSupplierStatuses = () => api.get(`/supplier/statuses`).then(response => response.data);

export const createSupplier = (data: SupplierProfile) => api.post('/supplier', data).then(response => response.data);

export const editingSupplier = (data: SupplierProfile) =>
  api.put(`/supplier/${data.id}`, data).then(response => response.data);

export const setSupplierStatus = (data: SupplierStatusReqData) =>
  api.post(`/supplier/${data.id}/${data.type}`).then(response => response.data);

export const addSupplierPavilion = ({ suppId, pavId }: AddSupplierPavReqData) =>
  api.put(`/supplier/${suppId}/pavilion/${pavId}`).then(response => response.data);

export const changeSupplierLegalEntity = (data: ChangeSupplierLegalReqData) =>
  api.patch('suppliers/legal-entity', data).then(response => response.data);
