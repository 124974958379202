import { Col, Row } from 'antd';

import {
  AnalyticsTodayStatistics,
  AnalyticsTopProviderTable,
  AnalyticsTopMediatorTable,
  AnalyticsByDaysWeek,
  AnalyticsByTime,
  AnalyticsRevenue
} from 'src/entities/analytics';

import styles from './Analytics.module.less';

export const AnalyticsPage = () => (
  <section className={styles.AnalyticsPage}>
    <h2 className={styles.AnalyticsPageTitle}>Сводка информации</h2>

    <Row>
      <Col span={24}>
        <div>
          <AnalyticsTodayStatistics />
        </div>
      </Col>
    </Row>

    <Row>
      <Col span={24}>
        <div>
          <AnalyticsRevenue />
        </div>
      </Col>
    </Row>

    <Row>
      <Col span={14}>
        <div>
          <AnalyticsByTime />
        </div>
      </Col>
      <Col span={10}>
        <div>
          <AnalyticsByDaysWeek />
        </div>
      </Col>
    </Row>

    <Row>
      <Col span={12}>
        <div>
          <AnalyticsTopMediatorTable />
        </div>
      </Col>
      <Col span={12}>
        <div>
          <AnalyticsTopProviderTable />
        </div>
      </Col>
    </Row>

  </section>
);


