import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { InputTag } from 'src/shared/ui/inputTag';
import { Naming } from 'src/features/namings/types';
import { columnWidth, namingsTooltipText } from './config';
import { ConfirmForm } from 'src/shared/ui';
import { defaultSelectValue } from 'src/shared/config';
import styles from './EditNaming.module.less';

interface EditNamingProps {
  categoryId?: number;
  categoryName?: string;
  naming?: Naming | null;
  successHandler: (value: { name: string; masks: string[] }) => void;
  closeHandler: () => void;
}

export const EditNaming = ({ naming, categoryId, categoryName, closeHandler, successHandler }: EditNamingProps) => {
  const [form] = Form.useForm();
  const [masks, setMasks] = useState<string[]>();
  const [isCancel, setIsCancel] = useState(false);
  const title = naming ? 'Редактирование' : 'Добавление';

  useEffect(() => {
    form.resetFields();
    setMasks(naming?.masks || []);
  }, [form, naming, categoryId]);

  const formSubmitHandler = (values: { name: string }) => {
    const resultMasks = masks || [];
    successHandler({ name: values.name, masks: resultMasks });
    closeHandler();
    form.resetFields();
    setMasks([]);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
    setIsCancel(false);
  };

  const handleMasks = (values: string[]) => setMasks(values);

  return (
    <div className={styles.editNaming}>
      <h2 className={styles.title}>{title} наименования</h2>
      <Form
        form={form}
        name="basic"
        labelCol={columnWidth}
        wrapperCol={columnWidth}
        initialValues={naming || defaultSelectValue}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название',
            },
          ]}
          tooltip={namingsTooltipText}>
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.namings}
          label="Маски"
          name="masks"
          tooltip={`Укажите слова и словосочетания относящиеся к категории "${categoryName}" для поиска в тексте поста. Пример: шапк*, головной убор`}>
          <InputTag masks={naming?.masks || []} handleMasks={handleMasks} />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Сохранить
            </Button>
          </Form.Item>

          <Button onClick={() => setIsCancel(true)} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>

      <ConfirmForm
        isOpen={isCancel}
        title="Вы уверены, что хотите закрыть форму? Несохранённые данные будут потеряны"
        closeHandler={() => setIsCancel(false)}
        confirmHandler={handleCancelClose}
      />
    </div>
  );
};
