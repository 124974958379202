import styles from './AddProductRequirements.module.less';

export const AddProductRequirements = () => (
  <article className={styles.AddProductRequirements}>
    <h3>Требования к изображениям</h3>

    <p>Добавьте хотя бы одно изображение товара, а лучше несколько. Изображения должны соответствовать требованиям.</p>
    <p>
      <strong>Принимаем:</strong>
    </p>
    <ul>
      <li>Формат: jpeg, jpg, png;</li>
      <li>Разрешение: от 200px на 200px до 4320px на 7680px;</li>
      <li>Размер: не превышает 10МБ;</li>
      <li>
        Фон: белый или светлый. Для белых или прозрачных товаров допустим черный фон. Для категории товаров Одежда,
        обувь и аксессуары фон серый (#f2f3f5);
      </li>
    </ul>
  </article>
);
