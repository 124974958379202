import { Card, Tabs } from 'antd';
import { ModerChatList } from './ui/moderChatList';
import styles from './ChatList.module.less';

export const ChatList = () => (
  <div className={styles.chatList}>
    <Card>
      <Tabs defaultActiveKey="moderation">
        {/* Todo: add in next release */}
        {/* <Tabs.TabPane tab="Поддержка" key="support">
            <div>Поддержка</div>
          </Tabs.TabPane> */}

        <Tabs.TabPane tab="Модерация" key="moderation">
          <ModerChatList />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  </div>
);
