import { ProductsListRequestData } from 'shared/api/queries/productsApi';

export const PER_PAGE = 10;

export const initState: ProductsListRequestData = {
  status: 'active',
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};
