import { Button, Form, Input } from 'antd';
import { setError } from 'src/features/notifications';
import { useEffect, useState } from 'react';
import { Image } from 'src/shared/lib';
import { DragAndDrop, MainModal, Title } from 'src/shared/ui';
import { IntermediaryProfile, ProfilePass } from '../../types';
import { getFileFx } from '../../model';

import styles from './PassEditModal.module.less';

interface ModalProps {
  isOpen: boolean;
  pass?: IntermediaryProfile | null;
  closeHandler: () => void;
  submitHandler: (data: ProfilePass) => void;
}

export const PassEditModal = ({ isOpen, pass, closeHandler, submitHandler }: ModalProps) => {
  const [fileList, setFileList] = useState<File[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(pass);
  }, [form.setFieldsValue, pass]);

  useEffect(() => {
    if (pass?.pass_photo_url) {
      getInitialFile(pass.pass_photo_url);
    }
  }, [pass?.pass_photo_url]);

  const confirm = async (values: ProfilePass) => {
    try {
      const image = fileList.length > 0 ? await Image.toBase64(fileList[0]) : '';
      submitHandler({
        ...values,
        pass_photo: image ? `${image}` : '',
        pass_valid_until: values.pass_valid_until ? new Date(values.pass_valid_until).toISOString() : '',
      });
      form.resetFields();
    } catch (err) {
      setError('Ошибка загрузки изображения');
    }
  };

  const getInitialFile = async (url: string) => {
    const file = await getFileFx(url);
    setFileList([file]);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <MainModal isOpen={isOpen} closeHandler={closeHandler}>
      <div className={styles.passEditModal}>
        <Title>Изменение категории</Title>
        <Form
          form={form}
          name="basic"
          onFinish={confirm}
          autoComplete="off"
          layout="vertical"
          initialValues={pass || {}}>
          <Form.Item label="Номер пропуска" name="pass_number">
            <Input />
          </Form.Item>
          <Form.Item label="Действителен до" name="pass_valid_until">
            <Input type="date" />
          </Form.Item>
          <Form.Item label="Загрузить фотографию пропуска" name="pass_photo_url">
            <DragAndDrop
              name="pass_photo_url"
              multiple={false}
              fileList={fileList}
              setFileList={setFileList}
              maxCount={1}
            />
          </Form.Item>

          <div className={styles.buttons}>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Готово
              </Button>
            </Form.Item>

            <Button onClick={handleCancelClose}>Отмена</Button>
          </div>
        </Form>
      </div>
    </MainModal>
  );
};
