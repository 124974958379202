import { AxiosError } from 'axios';
import { createEvent, createEffect, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { pavilionsApi, buildingsApi, floorsApi, linesApi } from 'src/shared/api';
import { Buildings, ActiveData } from './types';
import { Pavilion } from 'src/shared/types';
import { v4 as uuid } from 'uuid';

export const $buildings = createStore<Buildings | null>(null);
export const $buildingsTreeData = createStore<ActiveData[]>([]);

export const initPavilionsPage = createEvent();
export const submitAddBuildingReqData = createEvent<buildingsApi.AddBuildingReqData>();
export const submitAddFloorReqData = createEvent<floorsApi.AddFloorReqData>();
export const submitAddLineReqData = createEvent<linesApi.AddLineReqData>();
export const submitAddPavilionReqData = createEvent<pavilionsApi.AddPavilionReqData>();
export const submitEditBuildingReqData = createEvent<buildingsApi.UpdateBuildingReqData>();
export const submitEditFloorReqData = createEvent<floorsApi.UpdateFloorReqData>();
export const submitEditLineReqData = createEvent<linesApi.UpdateLineReqData>();
export const submitEditPavilionReqData = createEvent<pavilionsApi.UpdatePavilionReqData>();
export const submitRemoveBuildingReqData = createEvent<number>();
export const submitRemoveFloorReqData = createEvent<number>();
export const submitRemoveLineReqData = createEvent<number>();
export const submitRemovePavilionReqData = createEvent<number>();

export const getBuildingsFx = createEffect(buildingsApi.getBuildings);
export const addBuildingFx = createEffect<buildingsApi.AddBuildingReqData, Buildings, AxiosError<{ message: string }>>(
  buildingsApi.addBuilding,
);
export const addFloorFx = createEffect<floorsApi.AddFloorReqData, Buildings, AxiosError<{ message: string }>>(
  floorsApi.addFloor,
);
export const addLineFx = createEffect<linesApi.AddLineReqData, Buildings, AxiosError<{ message: string }>>(
  linesApi.addLine,
);
export const addPavilionFx = createEffect<
  pavilionsApi.AddPavilionReqData,
  { items: Pavilion[] },
  AxiosError<{ message: string }>
>(pavilionsApi.addPavilion);
export const editBuildingFx = createEffect<
  buildingsApi.UpdateBuildingReqData,
  Buildings,
  AxiosError<{ message: string }>
>(buildingsApi.updateBuilding);
export const editFloorFx = createEffect<floorsApi.UpdateFloorReqData, Buildings, AxiosError<{ message: string }>>(
  floorsApi.updateFloor,
);
export const editLineFx = createEffect<linesApi.UpdateLineReqData, Buildings, AxiosError<{ message: string }>>(
  linesApi.updateLine,
);
export const editPavilionFx = createEffect<
  pavilionsApi.UpdatePavilionReqData,
  { items: Pavilion[] },
  AxiosError<{ message: string }>
>(pavilionsApi.updatePavilion);
export const removeBuildingFx = createEffect<number, Buildings, AxiosError<{ message: string }>>(
  buildingsApi.removeBuilding,
);
export const removeFloorFx = createEffect<number, Buildings, AxiosError<{ message: string }>>(floorsApi.removeFloor);
export const removeLineFx = createEffect<number, Buildings, AxiosError<{ message: string }>>(linesApi.removeLine);
export const removePavilionFx = createEffect<number, { items: Pavilion[] }, AxiosError<{ message: string }>>(
  pavilionsApi.removePavilion,
);

$buildings.on(getBuildingsFx.doneData, (_, data) => data);
$buildingsTreeData.on(getBuildingsFx.doneData, (_, data: Buildings) => {
  const buildingsTreeData: ActiveData[] = (data?.items || []).map(building => ({
    type: 'building',
    id: building.id,
    buildingId: building.id,
    parentId: 0,
    title: building.name,
    key: uuid(),
    children:
      building?.floors && building.floors.length
        ? building.floors.map(floor => ({
            type: 'floor',
            id: floor.id,
            buildingId: building.id,
            parentId: building.id,
            title: `Этаж ${floor.name}`,
            key: uuid(),
            children: floor?.lines.map(line => ({
              type: 'line',
              id: line.id,
              buildingId: building.id,
              parentId: floor.id,
              title: `Линия ${line.name}`,
              key: uuid(),
              children: line.pavilions.map(pavilion => ({
                type: 'pavilion',
                id: pavilion.id,
                buildingId: building.id,
                parentId: line.id,
                title: `Павильон ${pavilion.name}`,
                key: uuid(),
              })),
            })),
          }))
        : building.lines.map(line => ({
            type: 'line',
            buildingId: building.id,
            id: line.id,
            title: `Линия ${line.name}`,
            key: uuid(),
            children: line.pavilions.map(pavilion => ({
              type: 'pavilion',
              id: pavilion.id,
              buildingId: building.id,
              parentId: line.id,
              title: `Павильон ${pavilion.name}`,
              key: uuid(),
            })),
          })),
  }));

  return buildingsTreeData;
});

sample({
  clock: initPavilionsPage,
  target: getBuildingsFx,
});

sample({
  clock: submitAddBuildingReqData,
  target: addBuildingFx,
});

sample({
  clock: submitAddLineReqData,
  target: addLineFx,
});

sample({
  clock: submitAddFloorReqData,
  target: addFloorFx,
});

sample({
  clock: submitAddPavilionReqData,
  target: addPavilionFx,
});

sample({
  clock: submitEditBuildingReqData,
  target: editBuildingFx,
});

sample({
  clock: submitEditFloorReqData,
  target: editFloorFx,
});

sample({
  clock: submitEditLineReqData,
  target: editLineFx,
});

sample({
  clock: submitEditPavilionReqData,
  target: editPavilionFx,
});

sample({
  clock: submitRemoveBuildingReqData,
  target: removeBuildingFx,
});

sample({
  clock: submitRemoveFloorReqData,
  target: removeFloorFx,
});

sample({
  clock: submitRemoveLineReqData,
  target: removeLineFx,
});

sample({
  clock: submitRemovePavilionReqData,
  target: removePavilionFx,
});

sample({
  clock: [
    addBuildingFx.doneData,
    editBuildingFx.doneData,
    removeBuildingFx.doneData,
    addFloorFx.doneData,
    editFloorFx.doneData,
    removeFloorFx.doneData,
    addLineFx.doneData,
    editLineFx.doneData,
    removeLineFx.doneData,
    addPavilionFx.doneData,
    editPavilionFx.doneData,
    removePavilionFx.doneData,
  ],
  source: {},
  target: getBuildingsFx,
});

sample({
  clock: addBuildingFx.doneData,
  fn: () => 'ТЦ успешно добавлен',
  target: setSuccess,
});

sample({
  clock: addBuildingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении ТЦ',
  target: setError,
});

sample({
  clock: addFloorFx.doneData,
  fn: () => 'Этаж успешно добавлен',
  target: setSuccess,
});

sample({
  clock: addFloorFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении этажа',
  target: setError,
});

sample({
  clock: addLineFx.doneData,
  fn: () => 'Линия успешно добавлена',
  target: setSuccess,
});

sample({
  clock: addLineFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении линии',
  target: setError,
});

sample({
  clock: addPavilionFx.doneData,
  fn: () => 'Павильон успешно добавлен',
  target: setSuccess,
});

sample({
  clock: addPavilionFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении павильона',
  target: setError,
});

sample({
  clock: editBuildingFx.doneData,
  fn: () => 'ТЦ успешно изменен',
  target: setSuccess,
});

sample({
  clock: editBuildingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении ТЦ',
  target: setError,
});

sample({
  clock: editFloorFx.doneData,
  fn: () => 'Этаж успешно изменен',
  target: setSuccess,
});

sample({
  clock: editFloorFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении этажа',
  target: setError,
});

sample({
  clock: editLineFx.doneData,
  fn: () => 'Линия успешно изменена',
  target: setSuccess,
});

sample({
  clock: editLineFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении линии',
  target: setError,
});

sample({
  clock: editPavilionFx.doneData,
  fn: () => 'Павильон успешно изменен',
  target: setSuccess,
});

sample({
  clock: editPavilionFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении павильона',
  target: setError,
});

sample({
  clock: removeBuildingFx.doneData,
  fn: () => 'ТЦ успешно удален',
  target: setSuccess,
});

sample({
  clock: removeBuildingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении ТЦ',
  target: setError,
});

sample({
  clock: removeFloorFx.doneData,
  fn: () => 'Этаж успешно удален',
  target: setSuccess,
});

sample({
  clock: removeFloorFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении этажа',
  target: setError,
});

sample({
  clock: removeLineFx.doneData,
  fn: () => 'Линия успешно удалена',
  target: setSuccess,
});

sample({
  clock: removeLineFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении линии',
  target: setError,
});

sample({
  clock: removePavilionFx.doneData,
  fn: () => 'Павильон успешно удален',
  target: setSuccess,
});

sample({
  clock: removePavilionFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении павильона',
  target: setError,
});
