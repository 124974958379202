import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Input, Spin, Empty, Button } from 'antd';
import { AddSynonym } from 'src/entities/addSynonym';
import { MainModal, ConfirmForm } from 'src/shared/ui';
import {
  $requestData,
  $synonymsList,
  changeRequestData,
  getSynonymsListFx,
  getSynonymsList,
  postSynonym,
  updateSynonym,
  removeSynonym,
} from './model';
import { columns, initState, menuItems, PER_PAGE, synonymInitState } from './config';
import { Synonym } from './types';
import styles from './Synonyms.module.less';

export const Synonyms = () => {
  const [modalState, setModalState] = useState<{ isAdd: boolean; isUpdate: boolean; isRemove: boolean }>({
    isAdd: false,
    isUpdate: false,
    isRemove: false,
  });
  const [nameValue, setNameValue] = useState('');
  const [updatedSynonym, setUpdatedSynonym] = useState<Synonym>(synonymInitState);
  const requestData = useStore($requestData);
  const isLoading = useStore(getSynonymsListFx.pending);
  const synonyms = useStore($synonymsList);

  useEffect(() => {
    getSynonymsList(initState);
  }, []);

  const handleChangePage = (value: number, size: number) => {
    changeRequestData({ ...requestData, pagination: { page: value, per_page: size } });
  };

  const searchNameHandler = () => changeRequestData({ ...requestData, search: nameValue });

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && nameValue) {
      changeRequestData(initState);
    }
    setNameValue(e.target.value);
  };

  const handleCloseAdd = () => setModalState({ ...modalState, isAdd: false });

  const handleCloseUpdate = () => setModalState({ ...modalState, isUpdate: false });

  const handleCloseRemove = () => setModalState({ ...modalState, isRemove: false });

  const handleAddSynonym = (value: { word: string; synonym: string }) => {
    setUpdatedSynonym(synonymInitState);
    postSynonym(value);
  };

  const handleUpdateSynonym = (value: { word: string; synonym: string }) => {
    updateSynonym({ id: updatedSynonym.id, data: value });
  };

  const handleRemoveSynonym = () => {
    removeSynonym({ id: updatedSynonym.id });
    setModalState({ ...modalState, isRemove: false });
  };

  const addSynonymHandler = () => {
    setUpdatedSynonym({ id: 0, word: '', synonym: '' });
    setModalState({ ...modalState, isAdd: true });
  };

  const handleDropdownMenu = (eventType: string, id: number) => {
    const selectedSynonym = synonyms?.items.find(item => item.id === id) || synonymInitState;
    setUpdatedSynonym(selectedSynonym);

    if (eventType === 'edit') {
      setModalState({ ...modalState, isUpdate: true });
    } else {
      setModalState({ ...modalState, isRemove: true });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.synonyms}>
      <Card className={styles.card}>
        <h2 className={styles.title}>Синонимы</h2>

        {synonyms ? (
          <>
            <div className={styles.wrapper}>
              <Input.Search
                value={nameValue}
                className={styles.search}
                placeholder="Поиск по названию"
                onChange={handleSearchName}
                onSearch={searchNameHandler}
                allowClear
              />

              <div className={styles.button}>
                <Button type="primary" onClick={addSynonymHandler}>
                  Добавить синоним
                </Button>
              </div>
            </div>
            <Table
              className={styles.table}
              rowKey={record => record.id}
              bordered
              columns={[
                ...columns,
                {
                  title: '',
                  key: 'settings',
                  render: (_, { id }) => (
                    <Dropdown
                      overlayClassName={styles.menu}
                      placement="bottom"
                      menu={{ items: menuItems, onClick: e => handleDropdownMenu(e.key, id) }}>
                      <MoreOutlined />
                    </Dropdown>
                  ),
                  width: '3%',
                },
              ]}
              dataSource={synonyms.items}
              pagination={{
                onChange: handleChangePage,
                current: synonyms?.pagination?.current_page || 1,
                pageSize: synonyms?.pagination?.per_page || PER_PAGE,
                total: synonyms?.pagination?.total || 0,
                showSizeChanger: true,
              }}
              locale={{ emptyText: <Empty description="Нет данных" /> }}
            />
          </>
        ) : (
          <Empty description="Синонимы не найдены" />
        )}
      </Card>

      <MainModal isOpen={modalState.isAdd} closeHandler={handleCloseAdd}>
        <AddSynonym title="Добавление синонима" successHandler={handleAddSynonym} closeHandler={handleCloseAdd} />
      </MainModal>

      <MainModal isOpen={modalState.isUpdate} closeHandler={handleCloseUpdate}>
        <AddSynonym
          title="Редактирование синонима"
          updatedSynonym={updatedSynonym}
          successHandler={handleUpdateSynonym}
          closeHandler={handleCloseUpdate}
        />
      </MainModal>
      <ConfirmForm
        isOpen={modalState.isRemove}
        title="Вы уверены, что хотите удалить синоним?"
        closeHandler={handleCloseRemove}
        confirmHandler={handleRemoveSynonym}
      />
    </div>
  );
};
