import { Button } from 'antd';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { dataMock } from './config';

import styles from './AnalyticsByDaysWeek.module.less';

export const AnalyticsByDaysWeek = () => (
  <article className={styles.AnalyticsByDaysWeek}>
    <div className={styles.AnalyticsByDaysWeekHeader}>
      <h3>По дням недели</h3>
      <Button onClick={() => { }}>Сформировать отчет</Button>
    </div>

    <ResponsiveContainer
      width='100%'
      aspect={4.0 / 1.5}
    >
      <BarChart
        data={dataMock}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" fill="#C7D3DC" />
      </BarChart>
    </ResponsiveContainer>
  </article>
);