import api from '../api';

interface CreatingCategory {
  name: string;
  image?: string;
  parent_id?: number;
}

interface EditingCategory extends CreatingCategory {
  id: number;
}

export interface EditCategoryChar {
  id: number;
  code: string;
  data: {
    values: string[];
  };
}

export const getCategories = () => api.get('/categories-tree').then(response => response.data);
export const deleteCategory = (id: number) => api.delete(`/category/${id}`).then(response => response.data);
export const createCategory = (data: CreatingCategory) => api.post(`/category`, data).then(response => response.data);
export const editCategory = (data: EditingCategory) =>
  api.put(`/category/${data.id}`, data).then(response => response.data);

export const editCategoryChar = (data: EditCategoryChar) =>
  api.put(`/category/${data.id}/characteristic/${data.code}/values`, data.data).then(response => response.data);
