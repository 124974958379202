import React from 'react';

import styles from './Title.module.less';

interface PropsType {
  children: React.ReactNode;
  className?: string;
}

export const Title = ({ children, className }: PropsType) => <h2 className={`${styles.title} ${className}`}>{children}</h2>;
