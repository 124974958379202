import { Badge, Menu } from 'antd';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

import styles from './Products.module.less';
import { Posts } from './posts';
import { ForModeration } from './ui/forModeration';
import { OnMarket } from './ui/onMarket';
import { NotActive } from './ui/notActive';
import { useStore } from 'effector-react';
import { $moderationProducts } from './model';

export const ProductsPage = () => {
  const location = useLocation();
  const moderationProducts = useStore($moderationProducts);

  return (
    <section className={styles.products}>
      <h2 className={styles.title}>Список товаров</h2>

      <Menu mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
        <Menu.Item key="/products">
          <Link to="/products" className={styles['link-margin']}>
            На модерацию
          </Link>
          {moderationProducts?.pagination?.total ? (
            <Badge count={moderationProducts.pagination.total} style={{ backgroundColor: '#597393' }} />
          ) : null}
        </Menu.Item>
        <Menu.Item key="/products/on_market">
          <Link to="/products/on_market">В продаже</Link>
        </Menu.Item>
        <Menu.Item key="/products/not_active">
          <Link to="/products/not_active">Не активные товары</Link>
        </Menu.Item>
        <Menu.Item key="/products/posts">
          <Link to="/products/posts">Посты</Link>
        </Menu.Item>
      </Menu>

      <div className={styles.body}>
        <Routes>
          <Route path="/" element={<ForModeration products={moderationProducts} />} />
          <Route path="/on_market" element={<OnMarket />} />
          <Route path="/not_active" element={<NotActive />} />
          <Route path="/posts" element={<Posts />} />
        </Routes>
      </div>
    </section>
  );
};
