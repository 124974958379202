import api from '../api';

export interface SynonymsRequestData {
  search?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface SynonymPostData {
  word: string;
  synonym: string;
}

export interface SynonymUpdateData {
  id: number;
  data: { word: string; synonym: string };
}

export interface SynonymRemoveData {
  id: number;
}

export const getSynonyms = (data: SynonymsRequestData) => api.post('/synonyms', data).then(response => response.data);

export const postSynonym = (data: SynonymPostData) => api.post('/synonym', data).then(response => response.data);

export const updateSynonym = (data: SynonymUpdateData) =>
  api.put(`/synonym/${data.id}`, data.data).then(response => response.data);

export const removeSynonym = (data: SynonymRemoveData) =>
  api.delete(`/synonym/${data.id}`).then(response => response.data);
