import api from '../api';

export interface AdminsLogsReqData {
  user_id?: number | string;
  action?: string;
  entity?: string;
  date_after?: string;
  date_to?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

export const getLogs = (data: AdminsLogsReqData) => api.get('/logs', { params: data }).then(response => response.data);
