import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { $categories, initAddProductInfo, getCategoriesFx, submitEditProdData } from './model';
import { Col, Row, Input, Button, Form, Image, TreeSelect, Select } from 'antd';
import { Image as Img } from 'src/shared/lib';
import { DragAndDrop } from 'src/shared/ui';
import { ConfirmForm } from 'src/shared/ui';
import { treeSelectFieldNames, productStatusOptions } from 'src/shared/config';
import { Product } from 'src/pages/products/types';
import styles from './AddProductInfo.module.less';

export interface FormSuccess {
  name: string;
  category: number;
}

interface AddProductInfoProps {
  id?: number | string;
  next: () => void;
  product?: Product | null;
}

export const AddProductInfo = ({ id, next, product }: AddProductInfoProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<{ isEdit: boolean; isCancel: boolean }>({
    isEdit: false,
    isCancel: false,
  });
  const [values, setValues] = useState<{ name: string; category_ids: number[] } | null>(null);
  const [fileList, setFileList] = useState<File[]>([]);
  const categories = useStore($categories);
  const isLoading = useStore(getCategoriesFx.pending);

  useEffect(() => {
    initAddProductInfo();
    form.setFieldsValue({
      name: product?.name,
      description: product?.description,
      category: product?.categories[0]?.id,
      status: product?.status ? product.status.toUpperCase() : '',
    });
  }, [form, product]);

  const handleOpen = (type: string) => setModalState({ ...modalState, [type]: true });

  const handleClose = (type: string) => setModalState({ ...modalState, [type]: false });

  const handleCancel = () => navigate(-1);

  const formSubmitHandler = async (values: FormSuccess) => {
    const images = fileList.length > 0 ? await Promise.all(fileList.map(async file => Img.toBase64(file))) : '';

    setValues({ name: values.name, category_ids: [values.category] });
  };

  const handleSubmitData = () => {
    if (id && values) {
      submitEditProdData({ id, data: values });
      navigate(-1);
    }
    setModalState({ ...modalState, isEdit: false });
  };

  return (
    <article className={styles.AddProductInfo}>
      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
        <div>
          <h3>Информация о товаре</h3>
          <Row>
            <Col span={24}>
              <div>{product && <Image src={product?.images[0]} placeholder />}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div>
                <Form.Item
                  name="name"
                  label="Наименование товара"
                  required={!id}
                  tooltip={id ? '' : 'Обязательное поле'}>
                  <Input />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div>
                <Form.Item
                  name="category"
                  label="Категория товара"
                  required={!id}
                  tooltip={id ? '' : 'Обязательное поле'}>
                  <TreeSelect
                    placement="topRight"
                    disabled={isLoading}
                    style={{
                      width: '100%',
                    }}
                    fieldNames={treeSelectFieldNames}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                    }}
                    treeData={categories?.items}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          {product ? (
            <Row>
              <Col span={24}>
                <div>
                  <Form.Item name="status" label="Статус товара">
                    <Select className={styles.select} disabled>
                      {Object.entries(productStatusOptions)?.map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ) : null}
          {product ? (
            <>
              <Row>
                <Col span={24}>
                  <div>
                    <Form.Item name="description" label="Описание товара">
                      <Input disabled />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {/* Todo: add when analytics will be done */}
              {/* <Row>
                <Col span={24}>
                  <div>
                    <Form.Item name="image">
                      <DragAndDrop name="image" multiple fileList={fileList} setFileList={setFileList} isDisabled />
                    </Form.Item>
                  </div>
                </Col>
              </Row> */}
            </>
          ) : null}
          {!product ? (
            <Row>
              <Col span={24}>
                <div>
                  <Form.Item name="article" label="Артикул" required tooltip="Обязательное поле">
                    <Input />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ) : null}

          {!product ? (
            <Row>
              <Col span={12}>
                <div>
                  <Form.Item name="price" label="Цена" required tooltip="Обязательное поле">
                    <Input />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Form.Item name="discount" label="Цена со скидкой" tooltip="Обязательное поле">
                    <Input />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ) : null}
        </div>

        {!product ? (
          <>
            <div>
              <h3>Вес и габариты с упаковкой</h3>
              <Row>
                <Col span={12}>
                  <div>
                    <Form.Item name="width" label="Ширина, см" required tooltip="Обязательное поле">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      name="weight"
                      label="Вес, кг"
                      required
                      tooltip="Укажите вес единицы товара вместе с упаковкой в граммах. Введите только число">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div>
                    <Form.Item name="height" label="Высота, см" required tooltip="Обязательное поле">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item name="length" label="Длина, см" required tooltip="Обязательное поле">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h3>Дополнительная информация</h3>
              <Row>
                <Col span={12}>
                  <div>
                    <Form.Item name="expiration" label="Срок годности" tooltip="Обязательное поле">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item name="age" label="С какого возраста пользоваться" tooltip="Обязательное поле">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : null}

        {!product ? (
          <div className={styles.button}>
            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" onClick={next}>
                  Далее
                </Button>
              )}
            </Form.Item>
          </div>
        ) : (
          <div className={styles.buttons}>
            <Form.Item shouldUpdate>
              {() => (
                <Button htmlType="submit" className={styles.btn} type="primary" onClick={() => handleOpen('isEdit')}>
                  Сохранить
                </Button>
              )}
            </Form.Item>
            <Button className={styles.btn} onClick={() => handleOpen('isCancel')}>
              Отменить
            </Button>
          </div>
        )}
      </Form>

      <ConfirmForm
        isOpen={modalState.isEdit}
        title="На странице были внесены изменения. Применить?"
        closeHandler={() => handleClose('isEdit')}
        confirmHandler={handleSubmitData}
      />

      <ConfirmForm
        isOpen={modalState.isCancel}
        title="При закрытии страницы изменения не будут сохранены."
        closeHandler={() => handleClose('isCancel')}
        confirmHandler={handleCancel}
      />
    </article>
  );
};
