import { ControlOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Dropdown, Switch, MenuProps } from 'antd';
import { useState } from 'react';

import styles from './TableSettings.module.less';

interface TableSettingsProps {
  columns: ColumnsType<any>;
  disabledColumns?: string[];
  visibleColumnsKeys: Record<string, boolean>;
  setVisibleColumnsKeys: (value: Record<string, boolean>) => void;
}

export const TableSettings = ({
  columns,
  disabledColumns,
  visibleColumnsKeys,
  setVisibleColumnsKeys,
}: TableSettingsProps) => {
  const [visibleMenu, setVisibleMenu] = useState(false);

  const changeVisibleColumn: MenuProps['onClick'] = e => {
    const currentValue = !visibleColumnsKeys[e.key];
    const currentVisibleColumnsKeys = { ...visibleColumnsKeys, [e.key]: currentValue };

    setVisibleColumnsKeys(currentVisibleColumnsKeys);
  };

  return (
    <Dropdown
      open={visibleMenu}
      onOpenChange={setVisibleMenu}
      menu={{
        items: columns
          .filter(col => col.title)
          .map(column => {
            const disabled = !!disabledColumns?.find(item => item === column.key);
            return {
              disabled,
              key: column.key?.toString() || '',
              label: (
                <div className={styles['control-row']}>
                  <p>{column.title?.toString()}</p>
                  <Switch
                    size="small"
                    checked={Boolean(column.key && visibleColumnsKeys[column.key])}
                    disabled={disabled}
                  />
                </div>
              ),
              onClick: changeVisibleColumn,
            };
          }),
      }}>
      <div className={styles.control}>
        <ControlOutlined />
      </div>
    </Dropdown>
  );
};
