import { AxiosResponse } from 'axios';
import { Message } from 'shared/types';

import api from '../api';

export interface ChatRequest {
  chatId: number | null;
  data: ChatMessagesRequestData;
}

export interface ChatMessagesRequestData {
  last_id?: number; // id последнего сообщения
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface ChatMessagesResponseData {
  items: Message[];
  pagination: {
    per_page: number;

    current_page: number;
    total: number;

    last_page: number;
  };
}

export const getOldMessages = ({ chatId, data }: ChatRequest) =>
  api.post(`/chat/${chatId}/messages`, data).then((response: AxiosResponse<ChatMessagesResponseData>) => response.data);
