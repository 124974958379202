import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Collapse, Button, Tree, Table, Spin, Alert, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Title } from 'src/shared/ui';
import { Key } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import { MainModal } from 'src/shared/ui';
import { Namings } from 'src/features/namings';
import { EditSizes } from 'src/features/editSizes';
import { columns, initTotal } from './config';
import { ProductParsePost, ParentCategory } from '../../types';
import { Category } from 'src/pages/settings/categories/types';
import { DetailedCategory } from 'src/entities/updateSource/types';
import { treeFieldNames } from 'src/shared/config';
import { defaultSelectValue } from 'src/shared/config';
import styles from './ProductParser.module.less';

interface ProductParserProps {
  productId: string | number | null;
  data: ProductParsePost | null;
  description: string[];
  categories: DetailedCategory[] | null;
  handleParsePost: () => void;
  handleActualPost: () => void;
  isLoading?: boolean;
  closeHandler?: (value: boolean) => void;
  isParseLoading?: boolean;
  isPosts?: boolean;
}

export const ProductParser = ({
  productId,
  data,
  description,
  categories,
  handleParsePost,
  handleActualPost,
  isLoading,
  closeHandler,
  isParseLoading,
  isPosts,
}: ProductParserProps) => {
  const [activeText, setActiveText] = useState<string[] | null>(null);
  const [activeSizeLine, setActiveSizeLine] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ParentCategory | Category | null>(null);
  const [isModal, setIsModal] = useState<{ naming: boolean; categories: boolean; sizes: boolean }>({
    naming: false,
    categories: false,
    sizes: false,
  });
  const [activeCategies, setActiveCategories] = useState<Category[]>([]);
  const pathName = useLocation().pathname;

  useEffect(() => {
    setActiveCategories([]);
  }, [productId]);

  const handleModal = (type: 'naming' | 'categories' | 'sizes', value: boolean) =>
    setIsModal({ ...isModal, [type]: value });

  const handleCategory = (value: Category | null) => {
    if (value) {
      setActiveCategories([...activeCategies, value]);
    }
  };

  const handleSelectCategory = (value: Key[], data: { selectedNodes: DetailedCategory[] }) => {
    if (data.selectedNodes.length) {
      setSelectedCategory(data.selectedNodes[0]);
      setIsModal({ naming: true, categories: false, sizes: false });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  if (!data && closeHandler) {
    closeHandler(false);
    return null;
  }

  return (
    <div className={styles.productParser}>
      <div className={styles.productWrapper}>
        <Title>Парсер</Title>
        <div className={styles.title}>
          <div className={styles.source}>
            <p className={styles.sourceItem}>
              <strong>Источник: </strong>
              {data?.post?.source?.supplier_id ? (
                <Link to={`/profile/supplier/${data.post.source.supplier_id}`} state={{ prevPath: true }}>
                  {data?.post?.source?.name || '-'}
                </Link>
              ) : (
                data?.post?.source?.name || '-'
              )}
            </p>
            {data?.post?.error ? (
              <Alert className={styles.sourceItem} message={data.post.error} type="error" showIcon />
            ) : null}
            {!data?.post?.error && data?.post?.product_id && isPosts ? (
              <Link
                className={`${styles.sourceItem} ${styles.sourceButton}`}
                to={`/product/${data.post.product_id}`}
                state={{ prevPath: pathName }}>
                <Button>Товар</Button>
              </Link>
            ) : null}
          </div>
          <div className={styles.sourceLink}>
            <p className={styles.sourceItem}>
              <strong>Ссылка на источник: </strong>
              {data?.post?.source?.url ? (
                <a href={data.post.source.url} target="_blank" rel="noreferrer">
                  {data.post.source.url}
                </a>
              ) : (
                '-'
              )}
            </p>
            <p className={styles.sourceItem}>
              <strong>Ссылка на пост: </strong>
              {data?.post?.url ? (
                <a href={data.post.url} target="_blank" rel="noreferrer">
                  {data.post.url}
                </a>
              ) : (
                '-'
              )}
            </p>
          </div>
        </div>
        <Tabs defaultActiveKey="tab1">
          <Tabs.TabPane tab="Название и категории" key="tab1">
            <div className={styles.wrapper}>
              <div className={styles.info}>
                <Highlighter
                  className={styles.description}
                  highlightClassName={styles.highlight}
                  searchWords={activeText || ['']}
                  autoEscape
                  textToHighlight={data?.post?.description || '-'}
                />
                <Button
                  loading={isParseLoading}
                  onClick={handleParsePost}
                  className={`${styles.button} ${styles.addButton}`}
                  type="primary">
                  Применить к товару
                </Button>
              </div>
              <div className={`${styles.info} ${styles.infoCategories}`}>
                <div className={styles.itemWrapper}>
                  {data?.post?.categories?.map(category => {
                    const synonyms = (category?.namings || []).reduce((acc, item) => {
                      const resultData = item.synonyms.map(element => element?.synonym?.replace('*', '') || '');
                      return [...acc, ...resultData];
                    }, [] as string[]);
                    const namings = (category?.namings || []).map(item => item?.naming?.name || '-');

                    return (
                      <div className={styles.item} key={category.id}>
                        <div
                          onBlur={() => setActiveText(null)}
                          onMouseOut={() => setActiveText(null)}
                          onFocus={() => setActiveText(synonyms)}
                          onMouseOver={() => setActiveText(synonyms)}>
                          <Collapse accordion>
                            <Collapse.Panel
                              header={
                                <div className={styles.parentCategory}>
                                  <div>
                                    {category?.is_selected ? (
                                      <p>
                                        <Typography.Text className={styles.category} type="success">
                                          {category.name}
                                        </Typography.Text>
                                      </p>
                                    ) : (
                                      <p className={styles.category}>{category.name}</p>
                                    )}
                                    {data?.categories?.[category?.id]?.breadcrumbs?.map(parentCategory => (
                                      <p className={styles.category} key={parentCategory?.id}>
                                        {parentCategory?.name || '-'}
                                      </p>
                                    ))}
                                  </div>
                                  <Button
                                    className={styles.button}
                                    onClick={e => {
                                      e.stopPropagation();
                                      setSelectedCategory(category || null);
                                      handleModal('naming', true);
                                    }}>
                                    Наименования <EditOutlined />
                                  </Button>

                                  <Button
                                    className={styles.button}
                                    onClick={e => {
                                      e.stopPropagation();
                                      setSelectedCategory(category || null);
                                      handleModal('sizes', true);
                                    }}>
                                    Размеры <EditOutlined />
                                  </Button>
                                </div>
                              }
                              key={category?.id}>
                              <div className={styles.expand}>
                                <div className={styles.expandTitle}>
                                  <p>
                                    <strong>{namings.length <= 1 ? 'Наименование' : 'Наименования'}:</strong>{' '}
                                    {namings.length ? namings.join(', ') : '-'}
                                  </p>
                                </div>
                                <Table
                                  rowKey={record => record.reason}
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={[
                                    ...(category?.history || []),
                                    { ...initTotal, weight: category?.weight || 0 },
                                  ]}
                                  bordered
                                  pagination={false}
                                />
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                  <div className={styles.activeCategories}>
                    {activeCategies.map(category => {
                      const result = data?.categories
                        ? Object.values(data.categories).filter(
                            item => item?.parent_id === category?.id || item?.id === category?.id,
                          )
                        : [];

                      return !result?.length ? (
                        <div className={styles.active} key={category?.id}>
                          <span className={styles.category}>{category?.name || '-'}</span>
                          <Button
                            className={`${styles.button} ${styles.buttonCategory}`}
                            onClick={() => {
                              setSelectedCategory(category);
                              handleModal('naming', true);
                            }}>
                            Наименования <EditOutlined />
                          </Button>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
                <Button
                  loading={!categories}
                  className={`${styles.button} ${styles.addButton}`}
                  type="primary"
                  onClick={() => handleModal('categories', true)}>
                  Добавить наименование в произвольную категорию
                </Button>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Размеры" key="tab2">
            <div className={styles.wrapper}>
              <div className={styles.info}>
                {description?.map((item, index) => (
                  <span className={index === activeSizeLine ? styles.activeLine : ''} key={item}>
                    {item}
                  </span>
                ))}
              </div>
              <div className={`${styles.info} ${styles.infoCategories}`}>
                <div className={styles.itemWrapper}>
                  {data?.post?.sizes?.map(size => {
                    const sizeValue = size?.value;

                    return (
                      <div className={styles.item} key={size.id}>
                        <div
                          onBlur={() => setActiveSizeLine(null)}
                          onMouseOut={() => setActiveSizeLine(null)}
                          onFocus={() => setActiveSizeLine(size.y)}
                          onMouseOver={() => setActiveSizeLine(size.y)}>
                          <Collapse accordion>
                            <Collapse.Panel
                              header={
                                <div className={styles.parentCategory}>
                                  <div>
                                    {size?.is_selected ? (
                                      <p>
                                        <Typography.Text className={styles.category} type="success">
                                          {sizeValue}
                                        </Typography.Text>
                                      </p>
                                    ) : (
                                      <p className={styles.category}>{sizeValue}</p>
                                    )}
                                  </div>
                                </div>
                              }
                              key={size.id}>
                              <div className={styles.expand}>
                                <Table
                                  rowKey={record => record.reason}
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={[...(size?.history || []), { ...initTotal, weight: size?.weight || 0 }]}
                                  bordered
                                  pagination={false}
                                />
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>

        <MainModal isOpen={isModal.naming} closeHandler={() => handleModal('naming', false)} width="90%">
          <Namings
            handleParserModal={handleModal}
            handleCategory={handleCategory}
            category={selectedCategory}
            handleActualPost={handleActualPost}
          />
        </MainModal>

        <MainModal isOpen={isModal.categories} closeHandler={() => handleModal('categories', false)} width="40%">
          <Title>Категории</Title>
          <Tree
            style={{ width: '80%' }}
            fieldNames={treeFieldNames}
            selectedKeys={defaultSelectValue}
            treeData={categories || defaultSelectValue}
            onSelect={handleSelectCategory}
          />
        </MainModal>

        <MainModal isOpen={isModal.sizes} closeHandler={() => handleModal('sizes', false)} width="40%">
          <EditSizes
            category={selectedCategory}
            closeHandler={() => handleModal('sizes', false)}
            handleActualPost={handleActualPost}
          />
        </MainModal>
      </div>
    </div>
  );
};
