import { Link } from 'react-router-dom';
import Pagination from 'antd/es/pagination';
import { Title } from 'src/shared/ui';
import { Utils } from 'src/shared/lib';
import { ProductComplaint } from 'src/pages/products/types';
import { complaintsOptions } from '../../config';
import { PER_PAGE } from '../../config';

import styles from './ProductComplaints.module.less';

interface ProductComplaintsProps {
  productComplaints: ProductComplaint;
  handleChangePage: (value: number, size: number) => void;
}

export const ProductComplaints = ({ productComplaints, handleChangePage }: ProductComplaintsProps) => (
  <div className={styles.productComplaints}>
    <Title>Жалобы</Title>
    <div className={styles.wrapper}>
      {productComplaints.items.map(({ id, type, client_id: clientId, comment, created_at: createdAt }) => (
        <div key={id}>
          <p>
            <strong>Тип:</strong> {complaintsOptions[type as keyof typeof complaintsOptions]}
          </p>
          <p>
            <strong>Автор: </strong>
            <Link to={`/profile/client/${productComplaints.clients[clientId].id}`} state={{ prevPath: true }}>
              {productComplaints.clients[clientId].name}
            </Link>
          </p>
          <p>
            <strong>Комментарий:</strong> {comment || '-'}
          </p>
          <p>
            <strong>Создана:</strong> {Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm')}
          </p>
        </div>
      ))}
    </div>
    <Pagination
      className={styles.pagination}
      showSizeChanger
      onChange={handleChangePage}
      current={productComplaints?.pagination?.current_page || 1}
      pageSize={productComplaints?.pagination?.per_page || PER_PAGE}
      total={productComplaints?.pagination?.total || 0}
    />
  </div>
);
