import React, { useEffect, useState } from 'react';
import { ConfirmForm } from 'src/shared/ui';
import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { OrdersProductsInfoProps } from '../OrdersProductsInfo';
import { tasksApi } from 'src/shared/api';
import styles from '../OrdersProductsInfo.module.less';
import { OrderData } from 'pages/orders/order/types';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  confirmHandler: () => void;
  item: {
    data?: OrderData['items'][number]
    task?: OrdersProductsInfoProps['task']
  };
}

export const ChangeStatusModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  confirmHandler,
  item
}) => {
  const { data, task } = item;
  const [newStatus, setNewStatus] = useState<{
    status: number;
    status_name: string;
  }>();

  useEffect(() => {
    const request = async () => {
      if (task?.task?.id && isOpen) {
        const response = await tasksApi.getTask(task.task.id);
        /* eslint-disable-next-line no-unsafe-optional-chaining */
        const findHistory = response?.history?.findIndex(item => Number(item.status) === response.task.status) + 1;

        if (response?.history[findHistory]) {
          setNewStatus({
            status: response?.history[findHistory].status,
            status_name: response?.history[findHistory].status_name
          })
        }
      }
    }
    request();
  }, [task?.task.id, isOpen]);

  if (!newStatus?.status) {
    return null;
  }

  if (!task || !data) {
    return null;
  }
  
  return (
    <ConfirmForm
      isOpen={isOpen && Boolean(newStatus?.status)}
      title="Смена статуса"
      closeHandler={() => setIsOpen(false)}
      confirmHandler={confirmHandler}
      buttonsPosition='left'
      titlePosition='left'
    >
      <div className={styles.changeStatus}>
        <div className={styles.flex}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size={100}
            icon={<PictureOutlined />}
            src={data?.image || ''}
          />
          <div>
            <strong>Задание {data.code || '-'}</strong>
            <div>{data.status_name || '-'}</div>
          </div>
        </div>
        <div>
          <div className={styles.footer}>
            <div className={styles.item}>
              <div>Новый статус</div>
              <div>{newStatus?.status_name || '-'}</div>
            </div>
            <div className={styles.item}>
              <div>Посредник</div>
              <div>{task.intermediary?.name || '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmForm>
  )
}