export const dataMock = [
  {
    name: '01',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '02',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '03',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '04',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '05',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '06',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '07',
    pv: 4300,
    amt: 2100,
  },
  {
    name: '08',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '09',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '10',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '11',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '12',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '13',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '14',
    pv: 4300,
    amt: 2100,
  },
];
