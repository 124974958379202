import { useEffect, useRef, useState } from 'react';
import { Button, Space, Select } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import styles from './useColumn.module.less';
import { ColumnTitle } from 'antd/lib/table/interface';

function useColumnSelect<DataType>(
  selectHandler: (value: string, type: string) => void,
  options: [string, string][],
  placeholder: string,
  type: string,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  defaultValue?: string | number,
) {
  const searchInput = useRef<InputRef>(null);
  const [value, setValue] = useState(defaultValue?.toString());
  const [isActive, setIsActive] = useState(!!defaultValue);

  useEffect(() => {
    setValue(defaultValue?.toString());
    setIsActive(!!defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setValue('');
      setIsActive(false);
      setIsActiveFilter(false);
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const handleSelect = (value: string) => {
    setValue(value);
    selectHandler(value, type);
    setIsActive(true);
  };

  const handleReset = (clearFilters: () => void) => {
    setValue('');
    selectHandler('', type);
    clearFilters();
    setIsActive(false);
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className={styles.useColumnAutoselect}>
        <Select onChange={handleSelect} value={value || null} placeholder={placeholder}>
          {options?.map(([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="middle">
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnSelect };
