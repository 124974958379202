import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { suppliersApi, legalEntitiesApi } from 'src/shared/api';
import { SupplierProfile, OptionType } from 'src/pages/profiles/suppliersProfile/types';
import { LegalEntity } from 'src/pages/profiles/legalEntities/types';
import { Paginated } from 'src/shared/types';
import { disabledLegalOption, emptyLegalOption } from './config';
import { PER_PAGE_LEGAL } from 'src/shared/config';

export const $legalEntitiesOptions = createStore<OptionType[]>([]);

export const initPage = createEvent<legalEntitiesApi.LegalEntitiesReqData>();
export const submitChangeSuppLegalData = createEvent<suppliersApi.ChangeSupplierLegalReqData>();

export const changeSuppLegalFx = createEffect<
  suppliersApi.ChangeSupplierLegalReqData,
  SupplierProfile,
  AxiosError<{ message: string }>
>(suppliersApi.changeSupplierLegalEntity);
export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);

$legalEntitiesOptions.on(getLegalEntitiesFx.doneData, (_, data: Paginated<LegalEntity>) => {
  const legalEntitiesOptions =
    data?.items
      .map(({ id, full_name: name }) => ({ key: id, label: name, value: name }))
      .slice(0, PER_PAGE_LEGAL - 1) || [];
  const intermedLegalOptions =
    data?.items?.length > PER_PAGE_LEGAL - 1 ? [...legalEntitiesOptions, disabledLegalOption] : legalEntitiesOptions;
  const resultLegalOptions = intermedLegalOptions?.length ? intermedLegalOptions : [emptyLegalOption];

  return resultLegalOptions;
});

sample({
  clock: initPage,
  target: getLegalEntitiesFx,
});

sample({
  clock: submitChangeSuppLegalData,
  target: changeSuppLegalFx,
});

sample({
  clock: changeSuppLegalFx.doneData,
  fn: () => 'Юридическое лицо успешно изменено',
  target: setSuccess,
});

sample({
  source: changeSuppLegalFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении юридического лица',
  target: setError,
});
