import { Tag } from 'antd';
import { statusColors } from 'src/shared/config';

export const renderTaskStatus = (status: string) => {
  const colors = {
    Отменено: 'gray',
    'Не оплачено': 'red',
    'Готово к работе': 'blueShadow',
    'В работе': 'blue',
    Выкуплено: 'green',
    Приемка: 'brown',
    'На складе': 'brown',
    'Нет в наличии': 'lightGray',
    Выполнено: 'green',
  };

  const resultColor = colors?.[status as keyof typeof colors] || '';
  const resultStyle = resultColor ? statusColors[resultColor as keyof typeof statusColors] : statusColors.default;

  return status ? <Tag style={resultStyle}>{status}</Tag> : '-';
};
