import { DetailedOrder } from '../order/types';

export const transformForPrintTable = (data: DetailedOrder[]) =>
  `<style>
  table, th, td {
    border: 1px solid black;
    padding: 4px;
    border-collapse: collapse;
  }
  img {
    max-width: 100px;
    max-height: 150px;
  }
  </style>
  <div>
    ${data.map(
      order =>
        `<div>
        <h4>Заказ ${order.order.code}</h4>
        <h>
          Клиент: ${order?.client?.name || ''}
        </h>
        <br />
        <h>Адрес: ${order.order.delivery.address}</h>
        <table>
        <tr>
          <th>Номер задания</th>
          <th>Название товара</th>
          <th>Количество товаров</th>
          <th>Изображение</th>
        </tr>
        ${order.order.items.map(
          item =>
            `<tr>
            <td>${item.code}</td>
            <td>${item.name}</td>
            <td>${item.quantity}</td>
            <td>
              <image src="${item.image}" alt="image" />
              
            </td>
          </tr>`,
        )}
        </table> 
        </div>`,
    )}
    </div>
`;
