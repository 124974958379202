import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $legalEntitiesOptions, initPage, submitChangeSuppLegalData } from './model';
import { Button, Form, Spin, AutoComplete } from 'antd';
import { Title } from 'src/shared/ui';
import { initStateLegal } from 'src/shared/config';
import { LegalEntityDetailed } from 'src/pages/profiles/legalEntitiesProfile/types';
import styles from './ChangeLegalEntity.module.less';

interface ChangeLegalEntityProps {
  selectedSuppIds: string[] | number[];
  legalProfile: LegalEntityDetailed;
  closeHandler: () => void;
}

export const ChangeLegalEntity = ({ selectedSuppIds, legalProfile, closeHandler }: ChangeLegalEntityProps) => {
  const [form] = Form.useForm();
  const legalEntitiesOptions = useStore($legalEntitiesOptions);
  const [selectedLegalId, setSelectedLegalId] = useState<string>('');
  const [initialLegalValue, setInitialLegalValue] = useState<string>('');
  const [legalValue, setLegalValue] = useState<string>('');

  useEffect(() => {
    initPage(initStateLegal);
    setInitialLegalValue(legalProfile?.full_name || '');
  }, []);

  const formSubmitHandler = () => {
    submitChangeSuppLegalData({ legal_entity_id: selectedLegalId, supplier_ids: selectedSuppIds });
    closeHandler();
    form.setFieldsValue({
      legal_entity_id: legalProfile?.full_name || '',
    });
  };

  const handleSearchLegal = (value: string) => {
    const legalData = value ? { ...initStateLegal, full_name: value } : initStateLegal;
    initPage(legalData);
    setLegalValue(value);
  };

  const handleSelectLegal = (value: string, options: { label: string }) => {
    setSelectedLegalId(value);
    setInitialLegalValue(options.label);
    setLegalValue(options.label);
    form.setFieldsValue({
      legal_entity_id: options.label,
    });
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
    const initialLegalId = legalProfile?.id ? legalProfile.id.toString() : '';
    const legalValue = legalProfile?.full_name || '';
    setSelectedLegalId(initialLegalId);
    setInitialLegalValue(legalValue);
    setLegalValue(legalValue);
  };

  return (
    <Spin size="large" spinning={false}>
      <Title>Смена юридического лица</Title>
      <Form
        form={form}
        className={styles.changeLegal}
        name="basic"
        onFinish={formSubmitHandler}
        initialValues={{ legal_entity_id: legalProfile?.full_name || '' }}
        layout="vertical"
        autoComplete="off">
        <div className={styles.select}>
          <Form.Item
            required
            rules={[
              {
                message: 'Юр. лицо заполнено некорректно. Выберете юр. лицо из выпадающего списка.',
                validator(_, value: string) {
                  if (!value || initialLegalValue !== legalValue) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                validateTrigger: 'onChange',
              },
            ]}
            label="Юридическое лицо"
            name="legal_entity_id">
            <AutoComplete
              value={selectedLegalId}
              onChange={handleSearchLegal}
              onSelect={handleSelectLegal}
              placeholder="Поиск по юридическим лицам">
              {legalEntitiesOptions?.map(({ value, key, label, disabled }) => (
                <AutoComplete.Option key={key} label={label} disabled={disabled}>
                  {value}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
          <Button onClick={handleCancelClose}>Отмена</Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
