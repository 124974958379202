import api from '../api';

export interface LogsRequestData {
  source_id?: string;
  statuses?: string[];
  pagination: {
    per_page?: number;
    page: number;
  };
}

export const getLogs = (data: LogsRequestData) =>
  api.post('/product-adding-infos', data).then(response => response.data);
export const getLogDetails = (id: string) => api.get(`/product-adding-info/${id}`).then(response => response.data);
export const retryParser = (id: string) => api.post(`/product-adding-info/${id}/retry`).then(response => response.data);
