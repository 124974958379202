import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Hooks } from 'src/shared/lib';
import { ProductsTable } from 'src/features/products';

import {
  submitChangeComplaintData,
  submitActivateData,
  getComplaintProductsFx,
  changePageSize,
  $pageSize,
} from '../../model';
import { initState } from './config';
import { Product, Paginated, Clients } from '../../types';

interface ForModerationProps {
  products: Paginated<Product, Clients> | null;
}

export const ForModeration = ({ products }: ForModerationProps) => {
  const isLoading = useStore(getComplaintProductsFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    const { page, ...filters } = searchParams;
    const currPage = page ? +page : 1;

    submitChangeComplaintData({ ...initState, ...filters, pagination: { page: currPage, per_page: pageSize } });
  }, [pageSize, searchParams]);

  const handleActivateProduct = (ids: string[]) => {
    submitActivateData({ ids });
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSearch = (obj: { [key: string]: string | string[] }, type?: string) => {
    if (type === 'name' && !obj.name) {
      const { name, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    } else {
      setSearchParams({ ...searchParams, ...obj, page: 1 });
    }
  };

  const resetSearch = (types: string[]) => {
    const filters = JSON.parse(JSON.stringify(searchParams));
    types.forEach(type => delete filters[type]);
    setSearchParams({ ...filters, page: 1 });
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
  };

  return (
    <ProductsTable
      isLoading={isLoading}
      data={products?.items}
      clients={products?.clients}
      filters={searchParams}
      handleChangePage={handleChangePage}
      handleChangePageSize={handleChangePageSize}
      pagination={products?.pagination}
      handleSearch={handleSearch}
      resetSearch={resetSearch}
      resetFilters={handleResetFilters}
      handleActivity={handleActivateProduct}
      type="forModeration"
    />
  );
};
