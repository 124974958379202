import { Tag } from 'antd';
import { statusColors } from 'src/shared/config';

export const statusOptions = {
  active: 'Активный',
  ban: 'Бан',
};

export const renderClientsStatus = (status: string) => {
  switch (status) {
    case 'NOT_REGISTERED':
      return <Tag style={statusColors.green}>Не зарегистрирован</Tag>;
    case 'ACTIVE':
      return <Tag style={statusColors.blue}>Активный</Tag>;
    case 'BANNED':
      return <Tag style={statusColors.red}>Бан</Tag>;
    case 'DELETED':
      return <Tag style={statusColors.gray}>Удален</Tag>;
    case 'FULL_DELETED':
      return <Tag style={statusColors.lightGray}>Полностью удален</Tag>;
    default:
      return '';
  }
};
