import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { selectChatId } from './ui/chatMessages/model';
import { ChatList } from './ui/chatList';
import { ChatMessages } from './ui/chatMessages';
import { ChatInfo } from './ui/chatInfo';
import styles from './Chat.module.less';

export const Chat = () => {
  const chatId = useLocation().state as { id: number } | null;

  useEffect(() => {
    if (chatId?.id) selectChatId(chatId.id);
  }, [chatId]);

  return (
    <div className={styles.chat}>
      <Row className={styles.info}>
        <Col span={6}>
          <ChatList />
        </Col>
        <Col span={10}>
          <ChatMessages />
        </Col>
        <Col span={8}>
          <Card>
            <ChatInfo />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
