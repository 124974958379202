export const sourceDataInitState = {
  id: 0,
  data: {
    url: '',
    pavilion_id: 0,
    supplier_id: 0,
    category_id: 0,
    is_active: false,
  },
};

export const pavIdInitState = { data: { supplier_pavilion_id: 0 } };
