import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { ProductsTable } from 'src/features/products';
import { Hooks } from 'src/shared/lib';

import {
  $deactivatedProducts,
  submitChangeRequestData,
  submitActivateData,
  getProductsFx,
  $pageSize,
  changePageSize,
} from './model';
import { initState } from './config';

export const NotActive = () => {
  const products = useStore($deactivatedProducts);
  const isLoading = useStore(getProductsFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    const { page, ...filters } = searchParams;
    const currPage = page ? +page : 1;

    submitChangeRequestData({ ...initState, ...filters, pagination: { page: currPage, per_page: pageSize } });
  }, [pageSize, searchParams]);

  const handleActivateProduct = (ids: string[]) => {
    submitActivateData({ ids });
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSearch = (obj: { [key: string]: string | string[] }, type?: string) => {
    if (type === 'name' && !obj.name) {
      const { name, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    } else {
      setSearchParams({ ...searchParams, ...obj, page: 1 });
    }
  };

  const resetSearch = (types: string[]) => {
    const filters = JSON.parse(JSON.stringify(searchParams));
    types.forEach(type => delete filters[type]);
    setSearchParams({ ...filters, page: 1 });
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
  };

  return (
    <ProductsTable
      isLoading={isLoading}
      filters={searchParams}
      data={products?.items}
      handleChangePageSize={handleChangePageSize}
      handleChangePage={handleChangePage}
      handleSearch={handleSearch}
      resetSearch={resetSearch}
      resetFilters={handleResetFilters}
      pagination={products?.pagination}
      handleActivity={handleActivateProduct}
      type="notActive"
    />
  );
};
