import { Card } from 'antd';
import { Title } from 'src/shared/ui/title';
import { userHistoryOptions } from 'src/pages/task/config';
import { Utils } from 'src/shared/lib';
import { TasksHistory } from 'src/pages/task/types';

import styles from './TransferHistory.module.less';

interface TransferHistoryProps {
  data?: TasksHistory[];
}

export const TransferHistory = ({ data }: TransferHistoryProps) => (
  <section>
    <Title>История статусов</Title>
    <div className={styles.list}>
      {data?.map(({ status_name: status, created_at: createdAt, user }) => (
        <Card key={createdAt.toString()}>
          <div className={styles.wrapper}>
            <div className={styles.info}>
              <p className={styles.status}>{status || '-'}</p>
              <p className={styles.date}>
                <span>{user?.name || '-'}</span>
                <span>{user?.type ? userHistoryOptions[user.type as keyof typeof userHistoryOptions] : '-'}</span>
                <span>{createdAt ? Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm') : '-'}</span>
              </p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  </section>
);
