import { ColumnsType } from 'antd/lib/table';

export interface DataType {
  id: string;
  name: string;
  quantity: number;
  status: string;
}

export const columns: ColumnsType<DataType> = [
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Кол-во заказов',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
  },
];

export const dataMock: DataType[] = [
  {
    id: '0',
    name: 'Сулейманов Заур Захир Оглы',
    quantity: 325,
    status: 'Официальный',
  },
  {
    id: '1',
    name: 'Сулейманов Заур Захир Оглы',
    quantity: 325,
    status: 'Официальный',
  },
  {
    id: '2',
    name: 'Сулейманов Заур Захир Оглы',
    quantity: 325,
    status: 'Официальный',
  },
];
