import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample } from 'effector';
import { categoriesApi, characteristicsApi } from 'src/shared/api';
import { setError, setSuccess } from 'src/features/notifications';

import { initState } from './config';
import { Sizes, Size, Categories } from './types';

export const $characteristics = createStore<Sizes | null>(null);
export const $requestData = createStore<characteristicsApi.GetCharacteristicsReqData>(initState);
export const $categories = createStore<Categories | null>(null);

export const getCharOnPageInit = createEvent<characteristicsApi.GetCharacteristicsReqData>();
export const changeRequestData = createEvent<characteristicsApi.GetCharacteristicsReqData>();
export const submitCreationForm = createEvent<characteristicsApi.CharAddReqData>();
export const submitEditingForm = createEvent<characteristicsApi.CharEditReqData>();
export const submitRemovalForm = createEvent<number>();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const getCharacteristicsFx = createEffect(characteristicsApi.getCharacteristics);
export const addCharacteristicFx = createEffect<
  characteristicsApi.CharAddReqData,
  Size,
  AxiosError<{ message: string }>
>(characteristicsApi.addCharacteristic);
export const editCharacteristicFx = createEffect<
  characteristicsApi.CharEditReqData,
  Size,
  AxiosError<{ message: string }>
>(characteristicsApi.editCharacteristic);
export const removeCharacteristicFx = createEffect<number, Size, AxiosError<{ message: string }>>(
  characteristicsApi.removeCharacteristic,
);

$characteristics.on(getCharacteristicsFx.doneData, (_, data) => data);
$categories.on(getCategoriesFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);

sample({
  clock: getCharOnPageInit,
  target: [getCharacteristicsFx, getCategoriesFx],
});

sample({
  clock: $requestData,
  target: getCharacteristicsFx,
});

sample({
  clock: submitCreationForm,
  target: addCharacteristicFx,
});

sample({
  clock: submitEditingForm,
  target: editCharacteristicFx,
});


sample({
  clock: submitRemovalForm,
  target: removeCharacteristicFx,
});

sample({
  clock: [addCharacteristicFx.doneData, removeCharacteristicFx.doneData, editCharacteristicFx.doneData],
  fn: () => initState,
  target: getCharacteristicsFx,
});

sample({
  clock: [addCharacteristicFx.failData, removeCharacteristicFx.failData, editCharacteristicFx.failData],
  fn: data => data.response?.data?.message || 'Ошибка',
  target: setError,
});

sample({
  clock: addCharacteristicFx.doneData,
  fn: () => 'Размер успешно добавлен',
  target: setSuccess,
});

sample({
  clock: removeCharacteristicFx.doneData,
  fn: () => 'Размер успешно удален',
  target: setSuccess,
});

sample({
  clock: editCharacteristicFx.doneData,
  fn: () => 'Размер успешно изменен',
  target: setSuccess,
});
