import { Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Utils } from 'src/shared/lib';
import { Message as IMessage, MessageTypeEnum } from 'src/shared/types';
import { FIRST_ELEMENT_INDEX } from 'src/shared/config';
import classNames from 'classnames';

import styles from './Message.module.less';

interface MessageType {
  message: IMessage;
  prevMessage?: IMessage;
  nextMessage?: IMessage;
}

export const Message = ({ message, prevMessage, nextMessage }: MessageType) => {
  const { created_at: createdAt, author, text, id } = message;

  const isTextMessage = message.type === MessageTypeEnum.TEXT;
  const nextDate = nextMessage?.created_at ?? '';
  const isSameDay = Utils.isSameDay(createdAt, nextDate);
  const isSameAuthorTime =
    Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm') ===
      (prevMessage?.created_at && Utils.getFormatDate(prevMessage.created_at, 'DD.MM.YYYY HH:mm')) &&
    author.user_id === prevMessage?.author.user_id;

  const stylesByType = classNames(
    { [styles.messageInter]: author.user_type === 'INTERMEDIARY' },
    { [styles.messageLast]: author?.user_type === 'INTERMEDIARY' && prevMessage?.author?.user_type !== 'INTERMEDIARY' },
    { [styles.messageClient]: author?.user_type === 'CLIENT' && prevMessage?.author?.user_type !== 'CLIENT' },
  );

  return (
    <>
      {isTextMessage ? (
        <div key={id} className={classNames(styles.message, stylesByType)}>
          <div className={styles.info}>
            <p className={styles.author}>{author.name}</p>
            <p className={styles.text}>{text}</p>
          </div>
          {!isSameAuthorTime ? <p className={styles.created}>{Utils.getFormatDate(createdAt, 'HH:mm')}</p> : null}
        </div>
      ) : (
        message.attachments.map(({ id, url }, index) => (
          <div key={id} className={classNames(styles.message, styles.messageImage, stylesByType)}>
            <div className={styles.infoImage}>
              <p className={styles.authorImage}>{author.name}</p>
              <div className={styles.imageWrapper} style={{ backgroundImage: `url(${url})` }}>
                <div className={styles.imageBackground} />
                <Image
                  preview={{
                    mask: (
                      <>
                        <EyeOutlined /> <span>Предпросмотр</span>
                      </>
                    ),
                  }}
                  key={id}
                  src={url}
                  placeholder
                />
              </div>
            </div>
            {!isSameAuthorTime && index === FIRST_ELEMENT_INDEX ? (
              <p className={styles.created}>{Utils.getFormatDate(createdAt, 'HH:mm')}</p>
            ) : null}
          </div>
        ))
      )}
      {!isSameDay ? <div className={styles.date}>{Utils.dateFormatChat(createdAt)}</div> : ''}
    </>
  );
};
