import { Form, Input, Button } from 'antd';
import { Title } from 'src/shared/ui';
import styles from './ReturnOrderForm.module.less';

interface FormSuccess {
  description: string;
}

interface ReturnOrderFormProps {
  successHandler: (value: string) => void;
  closeHandler: () => void;
}

export const ReturnOrderForm = ({ closeHandler, successHandler }: ReturnOrderFormProps) => {
  const formSubmitHandler = (values: FormSuccess) => {
    successHandler(values.description);
  };

  return (
    <div className={styles.returnOrder}>
      <Title>Опишите причину возврата</Title>
      <Form name="basic" layout="vertical" onFinish={formSubmitHandler} autoComplete="off">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите причину возврата!',
            },
          ]}
          label="Причина возврата"
          name="description">
          <Input />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Подтвердить
            </Button>
          </Form.Item>
          <Button onClick={closeHandler} className={styles.button}>
            Отменить
          </Button>
        </div>
      </Form>
    </div>
  );
};
