import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Hooks } from 'src/shared/lib';
import {
  $users,
  $visibleColumns,
  changeVisibleColumns,
  getUsersFx,
  updateUser,
  addUser,
  $isEmployeeError,
  changeRequestData,
  resetEmployeeError,
  $pageSize,
  changePageSize,
} from './model';
import { MoreOutlined, ClearOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Input, Spin, Button, Tooltip, Empty } from 'antd';
import { TableSettings } from 'src/entities/tableSettings';
import { getNumbers } from 'src/shared/lib/getNumbers';
import { MainModal } from 'src/shared/ui/mainModal';
import { UpdateUser } from 'src/entities/updateUser';
import { AddUser } from 'src/entities/addUser';
import { ColumnsType } from 'antd/lib/table/interface';
import { columns, PER_PAGE, roleOptions, mainColumnName } from './config';
import { User, UpdatedUserData, ModalStateData } from './types';
import { NumberTypeEnum } from 'src/shared/types';
import { AddUserRequestData } from 'src/shared/api/queries/usersApi';

import styles from './Employees.module.less';

export const EmployeesPage = () => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [modalState, setModalState] = useState<ModalStateData>({
    isAdd: false,
    isUpdate: false,
  });
  const [nameValue, setNameValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [updatedUser, setUpdatedUser] = useState<User>({} as User);
  const users = useStore($users);
  const isLoading = useStore(getUsersFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const isEmployeeError = useStore($isEmployeeError);

  useEffect(() => {
    const { page, ...filters } = searchParams;
    const currPage = page ? +page : 1;

    changeRequestData({ ...filters, pagination: { page: currPage, per_page: pageSize } });
  }, [searchParams, pageSize]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<User> = columns.filter(
      column => column.key && (!(column.key in visibleColumnsKeys) || visibleColumnsKeys[column.key]),
    );
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const handleCancelClose = (type: 'isUpdate' | 'isAdd') => {
    resetEmployeeError();
    setModalState({ ...modalState, [type]: false });
  };

  const handleCloseUpdate = () => setModalState({ ...modalState, isUpdate: false });

  const handleCloseAdd = () => setModalState({ ...modalState, isAdd: false });

  const handleOpenAdd = () => setModalState({ ...modalState, isAdd: true });

  const searchNameHandler = () => setSearchParams({ ...searchParams, name: nameValue, page: 1 });

  const handleChangePage = (value: number) => setSearchParams({ ...searchParams, page: value.toString() });

  const handleChangePageSize = (currentSize: number, size: number) => changePageSize(size);

  const searchFieldHandler = (value: string, type: string) => {
    const resultValue = type === 'phone' ? getNumbers(value) : value;
    if (value) {
      setSearchParams({ ...searchParams, [type]: resultValue, page: 1 });
    } else {
      setSearchParams({ ...searchParams, [type]: null, page: 1 });
    }
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && nameValue) {
      const { name, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    }
    setNameValue(e.target.value);
  };

  const handleUpdateUser = (data: UpdatedUserData) => {
    const filteredData: UpdatedUserData = {};
    Object.keys(data).forEach(item => {
      const key = item as keyof typeof data;
      if (data[key]) filteredData[key] = data[key];
    });
    updateUser({ id: updatedUser.id, data: filteredData });
  };

  const handleAddUser = (data: AddUserRequestData) => {
    addUser(data);
  };

  const handleDropdownMenu = (data: User) => {
    setUpdatedUser(data);
    setModalState({ ...modalState, isUpdate: true });
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const getEmailSearch = Hooks.useColumnSearch<User>(
    searchFieldHandler,
    'email',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.email,
  );
  const getPhoneSearch = Hooks.useColumnSearch<User>(
    searchFieldHandler,
    NumberTypeEnum.PHONE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.phone,
  );
  const getRoleSearch = Hooks.useColumnSelect<User>(
    searchFieldHandler,
    Object.entries(roleOptions),
    'Выбрать роль',
    'role',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.role,
  );

  const filteredColumns: ColumnsType<User> = visibleColumns.map(column => {
    switch (column.key) {
      case 'email':
        return { ...column, ...getEmailSearch(column.key, column.title) };
      case 'phone':
        return { ...column, ...getPhoneSearch(column.key, column.title) };
      case 'role':
        return { ...column, ...getRoleSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const resultColumns = [
    ...filteredColumns,
    {
      title: '',
      key: 'settings',
      render: (data: User) => (
        <Dropdown
          overlayClassName={styles.menu}
          placement="bottom"
          menu={{
            items: [
              {
                key: 'edit',
                label: 'Редактировать',
                onClick: () => handleDropdownMenu(data),
              },
            ],
          }}>
          <MoreOutlined />
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.employees}>
      <h2 className={styles.title}>Сотрудники и их роли</h2>

      <Card className={styles.card}>
        <>
          <div className={styles.searchSettings}>
            <Input.Search
              value={nameValue}
              className={styles.search}
              placeholder="Поиск по ФИО"
              onChange={handleSearchName}
              onSearch={searchNameHandler}
              allowClear
            />
            <div className={styles.wrapper}>
              <Button type="primary" onClick={handleOpenAdd}>
                Добавить сотрудника
              </Button>
              <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
                <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
              </Tooltip>
              <TableSettings
                disabledColumns={[mainColumnName]}
                columns={columns}
                visibleColumnsKeys={visibleColumnsKeys}
                setVisibleColumnsKeys={changeVisibleColumns}
              />
            </div>
          </div>

          <Table
            dataSource={users?.items || []}
            className={styles.table}
            rowKey={record => record.id}
            bordered
            columns={resultColumns}
            pagination={{
              onChange: handleChangePage,
              onShowSizeChange: handleChangePageSize,
              current: users?.pagination?.current_page || 1,
              pageSize: users?.pagination?.per_page || PER_PAGE,
              total: users?.pagination?.total || 0,
              showSizeChanger: true,
            }}
            scroll={{ x: 900 }}
            locale={{ emptyText: <Empty description="Нет данных" /> }}
          />
        </>
      </Card>

      <MainModal isOpen={modalState.isUpdate} closeHandler={handleCloseUpdate}>
        <UpdateUser
          data={updatedUser}
          successHandler={handleUpdateUser}
          closeHandler={() => handleCancelClose('isUpdate')}
          isEmployeeError={isEmployeeError}
        />
      </MainModal>

      <MainModal isOpen={modalState.isAdd} closeHandler={handleCloseAdd}>
        <AddUser
          successHandler={handleAddUser}
          closeHandler={() => handleCancelClose('isAdd')}
          isEmployeeError={isEmployeeError}
        />
      </MainModal>
    </div>
  );
};
