import { Card } from 'antd';
import styles from './TaskStatus.module.less';
import { DetailedTask } from 'pages/task/types';
import dayjs from 'dayjs';
import { availableChangeStatus } from 'src/utils';
import { EditOutlined } from '@ant-design/icons';

interface TaskStatusProps {
  readonly task: DetailedTask['task'];
  readonly setShowChangeStatusModal: (value: boolean) => void;
}

export const TaskStatus = ({ task, setShowChangeStatusModal }: TaskStatusProps) => (
  <Card>
    <div className={styles.status}>
      <strong>Статус</strong>
      <b>
        {task?.status_name || '-'}
        {availableChangeStatus.includes(task.status)
          && <EditOutlined onClick={() => setShowChangeStatusModal(true)} />
        }
      </b>
    </div>
    <div className={styles.status}>
      Создано: {dayjs(task?.created_at).format('DD.MM.YYYY HH:mm') || '-'}
    </div>
  </Card>
);
