import { Link } from 'react-router-dom';
import { PictureFilled } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { Title } from 'src/shared/ui/title';
import { PostInfo } from 'src/entities/postInfo';
import { CharacteristicsType, DetailedTask } from 'src/pages/task/types';
import { getFinalPrice } from 'src/shared/lib';
import { Utils } from 'src/shared/lib';

import styles from './ProductInfoCard.module.less';

interface ProductInfoCardProps {
  task: DetailedTask | null;
}

export const ProductInfoCard = ({ task }: ProductInfoCardProps) => {
  const size = task?.offer?.characteristics.find(item => item.code === CharacteristicsType.size);
  const picture = task?.offer?.characteristics.find(item => item.code === CharacteristicsType.picture);

  return (
    <Card className={styles.wrapper}>
      <div className={styles.title}>
        <Title>Задание {task?.task?.code}</Title>
        <div>
          <strong>
            {task?.task.received_count || task?.task.received_count === 0 ? task?.task.received_count : '-'}
          </strong>
          /{task?.task?.count || task?.task.count === 0 ? task?.task.count : '-'}
        </div>
      </div>
      <div className={styles['main-info']}>
        <div className={styles['info-wrapper']}>
          <Avatar
            shape="square"
            size={100}
            icon={<PictureFilled />}
            src={picture?.value || task?.offer.product.images[0]}
          />
          <div className={styles.info}>
            <strong className={styles.name}>{task?.offer.product.name}</strong>
            <span>размер: {size?.value || '-'}</span>
          </div>
        </div>
      </div>
      <div className={styles.order}>
        {task?.task?.order?.id ? (
          <Link to={`/order/${task.task.order.id}`} state={{ prevPath: true }}>
            Заказ {task.task.order.code}
          </Link>
        ) : null}
      </div>
      <div className={styles['info-block']}>
        <p>
          <strong>Создано</strong>
          {task?.task?.created_at ? Utils.getFormatDate(task?.task?.created_at, 'DD.MM.YYYY HH:mm') : '-'}
        </p>
        <p>
          <strong>Цена за ед.</strong> {task?.offer?.price ? getFinalPrice(task.offer.price) : '-'}
        </p>
        <p>
          <strong>Сумма задания</strong> {task?.task.amount ? getFinalPrice(task.task.amount) : '-'}
        </p>
      </div>

      {task?.post ? <PostInfo post={task.post} /> : null}
    </Card>
  );
};
