import { createEffect, createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { setError, setSuccess } from 'src/features/notifications';
import { exceptionsApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { initState } from './config';
import { Exception } from './types';

export const $exceptions = createStore<Paginated<Exception> | null>(null);
export const $requestData = createStore<exceptionsApi.ExceptionsRequestData>(initState);

const getExceptionsFx = createEffect(exceptionsApi.getExceptions);
const createExceptionFx = createEffect(exceptionsApi.createException);
const editExceptionFx = createEffect(exceptionsApi.updateException);
const deleteExceptionFx = createEffect(exceptionsApi.deleteException);

export const initExceptionsPage = createEvent();
export const changeRequestData = createEvent<exceptionsApi.ExceptionsRequestData>();
export const submitCreationForm = createEvent<string>();
export const submitEditionForm = createEvent<Exception>();
export const submitDeleteException = createEvent<number>();

sample({
  clock: [initExceptionsPage, createExceptionFx.done, editExceptionFx.done, deleteExceptionFx.done],
  source: $requestData,
  target: getExceptionsFx,
});

sample({
  source: $requestData,
  target: getExceptionsFx,
});

sample({
  clock: changeRequestData,
  target: $requestData,
});

sample({
  source: getExceptionsFx.doneData,
  target: $exceptions,
});

sample({
  source: submitCreationForm,
  target: createExceptionFx,
});

sample({
  source: submitEditionForm,
  target: editExceptionFx,
});

sample({
  source: submitDeleteException,
  target: deleteExceptionFx,
});

sample({
  clock: createExceptionFx.done,
  fn: () => 'Исключение создано',
  target: setSuccess,
});

sample({
  clock: createExceptionFx.fail,
  fn: () => 'Ошибка создания',
  target: setError,
});

sample({
  clock: editExceptionFx.done,
  fn: () => 'Исключение изменено',
  target: setSuccess,
});

sample({
  clock: editExceptionFx.fail,
  fn: () => 'Ошибка изменения',
  target: setError,
});

sample({
  clock: deleteExceptionFx.done,
  fn: () => 'Исключение удалено',
  target: setSuccess,
});

sample({
  clock: deleteExceptionFx.fail,
  fn: () => 'Ошибка удаления',
  target: setError,
});

export const useLoading = () => useStore(getExceptionsFx.pending);
