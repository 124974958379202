import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Button, Card, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import { setError } from 'src/features/notifications';
import { TaskTransfer } from 'src/pages/task/types';
import type { InputRef } from 'antd';

import styles from './TaskCount.module.less';

interface TaskCountProps {
  code?: string;
  showCancel?: boolean;
  cancelHandler: (data: string) => void;
  successHandler: (data: TaskTransfer) => void;
  isCountActive?: boolean;
}

export const TaskCount = ({ code, showCancel, successHandler, cancelHandler, isCountActive }: TaskCountProps) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef?.current && isCountActive) {
      inputRef.current.focus();
    }
  }, [isCountActive]);

  const formSubmitHandler = (values: TaskTransfer) => {
    successHandler(values);
    form.resetFields();
  };

  const formCancel = () => {
    const fields = form.getFieldsValue();
    if (fields.product_count) {
      cancelHandler(fields.product_count);
      form.resetFields();
    } else {
      setError('Введите количество для отмены');
    }
  };

  return (
    <div className={styles.taskCount}>
      <Card>
        <Form
          form={form}
          className={styles.form}
          id="countForm"
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={formSubmitHandler}
          autoComplete="off">
          <Form.Item
            label="Количество"
            name="product_count"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите количество!',
              },
            ]}>
            <Input ref={inputRef} type="number" min={1} />
          </Form.Item>
          <p className={styles.cell}>
            <strong>Ячейка</strong>
          </p>
          <p className={styles.cell}>{code}</p>

          <Form.Item className={styles.mainButton}>
            <Button htmlType="submit">Добавить</Button>
          </Form.Item>
        </Form>
        {showCancel ? (
          <>
            <p className={styles.title}>
              Принесенный товар не соответствует описанию задания?
              <Tooltip
                overlayStyle={{ position: 'fixed' }}
                title="Пожалуйста, введите количество несоответствующих товаров в поле выше и нажмите кнопку Отклонить">
                <QuestionCircleOutlined className={styles.icon} />
              </Tooltip>
            </p>

            <div className={styles.button}>
              <Button onClick={formCancel}>Отклонить</Button>
            </div>
          </>
        ) : null}
      </Card>
    </div>
  );
};
