import { useEffect } from 'react';
import { useStore } from 'effector-react';
import {
  $chatInfo,
  $resultImagesData,
  initChatInfo,
  initAttach,
  submitChangeClientStatus,
  submitChangeIntermedStatus,
  getChatInfoFx,
  getAttachCountFx,
} from './model';
import { $currentChatId } from '../chatMessages/model';
import { Tabs, Spin } from 'antd';
import { TaskInfo } from './ui/taskInfo';
import { ChatMemberInfo } from './ui/chatMemberInfo';
import { MediaInfo } from './ui/mediaInfo';
import { clientStatusOpts, intermedStatusOpts, initImagesReqData, PER_PAGE } from './config';
import { resultImagesData, chatUsers } from './types';
import styles from './ChatInfo.module.less';

export const ChatInfo = () => {
  const id = useStore($currentChatId);
  const chatInfo = useStore($chatInfo);
  const isChatInfoLoading = useStore(getChatInfoFx.pending);
  const isAttachCountLoading = useStore(getAttachCountFx.pending);
  const imagesData = useStore<resultImagesData | null>($resultImagesData);

  useEffect(() => {
    if (id) {
      initChatInfo(id);
      initAttach({ id, data: { type: 'image', ...initImagesReqData } });
    }
  }, [id]);

  const handleChangeStatus = (profileType: chatUsers, profileId: number, value: string) => {
    if (profileType === 'client') {
      submitChangeClientStatus({ id: profileId, type: value });
    } else {
      submitChangeIntermedStatus({ id: profileId, type: value });
    }
  };

  const handleChangePage = () => {
    if (id && imagesData?.currentPage && imagesData.currentPage + 1 <= imagesData?.totalPages) {
      initAttach({ id, data: { type: 'image', pagination: { per_page: PER_PAGE, page: imagesData.currentPage + 1 } } });
    }
  };

  return (
    <div className={styles.chatInfo}>
      {id ? (
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Инфо" key="info">
            {isChatInfoLoading ? (
              <div className={styles.center}>
                <Spin size="large" />
              </div>
            ) : (
              <div className={styles.detailedInfo}>
                <div className={styles.wrapper}>
                  {chatInfo?.task ? <TaskInfo task={chatInfo.task} characteristic={chatInfo.characteristic} /> : null}
                  {chatInfo && chatInfo?.clients?.length
                    ? chatInfo.clients.map(client => (
                        <ChatMemberInfo
                          key={client.id}
                          data={client}
                          statusOptions={clientStatusOpts}
                          memberType="client"
                          handleChangeStatus={handleChangeStatus}
                        />
                      ))
                    : null}
                  {chatInfo && chatInfo?.intermediaries?.length
                    ? chatInfo.intermediaries.map(intermediary => (
                        <ChatMemberInfo
                          key={intermediary.id}
                          data={intermediary}
                          statusOptions={intermedStatusOpts}
                          memberType="intermediary"
                          handleChangeStatus={handleChangeStatus}
                        />
                      ))
                    : null}
                </div>
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Медиа" key="media">
            {isAttachCountLoading ? (
              <div className={styles.center}>
                <Spin size="large" />
              </div>
            ) : (
              <div>
                {imagesData && imagesData?.images ? (
                  <MediaInfo imagesData={imagesData} handleChangePage={handleChangePage} />
                ) : null}
              </div>
            )}
          </Tabs.TabPane>
        </Tabs>
      ) : null}
    </div>
  );
};
