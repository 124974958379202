import api from '../api';
import {  CaptchaSettingsKeys } from 'src/pages/settings/captcha/types';
import { CommissionSettingsKeys } from 'pages/settings/commission/types';

export interface UpdateSettingsReqData {
  settings: {
    key: CommissionSettingsKeys | CaptchaSettingsKeys;
    value: string | number;
  }[];
}

export const getSettings = () => api.post('/settings').then(response => response.data);

export const updateSettings = (settings: UpdateSettingsReqData) =>
  api.put('/settings', settings).then(response => response.data);
