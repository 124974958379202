import api from '../api';
import { Login } from '../types';

export const login = (data: Login) => api.post('/authentication/login', data).then(response => response.data);

export const refreshToken = (refreshToken: string) =>
  api
    .post('/authentication/refresh', {
      refresh_token: refreshToken,
    })
    .then(response => response.data);
