import { Menu } from 'antd';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { PaymentsList } from './paymentList';
import styles from './Payments.module.less';

export const Payments = () => {
  const location = useLocation();

  return (
    <section className={styles.orders}>
      <h2 className={styles.title}>Платежи</h2>

      <Menu mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
        <Menu.Item key="/payments">
          <Link to="/payments">Сплит</Link>
        </Menu.Item>
        <Menu.Item key="/payments/clientsPayments">
          <Link to="/payments/clientsPayments">Без сплита</Link>
        </Menu.Item>
      </Menu>

      <div className={styles.body}>
        <Routes>
          <Route path="/" element={<PaymentsList paymentsType="legal-entities" />} />
          <Route path="/clientsPayments" element={<PaymentsList paymentsType="clients" />} />
        </Routes>
      </div>
    </section>
  );
};
