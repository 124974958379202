import { useStore } from 'effector-react';
import Echo from 'laravel-echo';
import { useEffect } from 'react';
import { $echoClient } from 'src/shared/api/model';
import { Message } from 'src/shared/types';
import { getWSMessagesEvent } from 'src/pages/chat/ui/chatMessages/model';

/**
 * Подписка на новые сообщения
 */
export const useEchoNewMessage = ({ chatId }: { chatId: number | null }) => {
  const echoClient = useStore($echoClient);

  useEffect(() => {
    if (!echoClient || !chatId) return () => {};

    const channelChatId = `chat.${chatId}`;
    const eventNewMessageChat = 'chat.message.created';

    const listenEvent = async <T>({
      echo,
      channel,
      event,
      callback,
    }: {
      echo: Echo;
      channel: string;
      event: string;
      callback: (data: T) => void;
    }) => {
      echo.join(channel).listen(event, () => {});

      echo.listen(`presence-${channel}`, `.${event}`, (data: T) => {
        callback(data);
      });
    };

    listenEvent<Message>({
      echo: echoClient,
      channel: channelChatId,
      event: eventNewMessageChat,
      callback: (data: Message) => getWSMessagesEvent(data),
    });

    return () => {
      echoClient.leaveChannel(`presence-${channelChatId}`);
      // resetPrevWSMessages();
    };
  }, [chatId, echoClient]);
};
