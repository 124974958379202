export const initFloorOpts = {
  value: '',
  building_id: 0,
};

export const initLineOpts = {
  value: '',
  floorValue: 0,
  building_id: 0,
  floor_id: 0,
  isUnnamedBuilding: false,
};

export const initPavilOpts = {
  value: '',
  floorValue: 0,
  lineValue: 0,
  building_id: 0,
  floor_id: 0,
  line_id: 0,
  isUnnamedBuilding: false,
  isUnnamedFloor: false,
  unnamedLines: [],
};

export const mainOptions = [
  { value: 'building', name: 'ТЦ' },
  { value: 'floor', name: 'Этаж' },
  { value: 'line', name: 'Линия' },
  { value: 'pavilion', name: 'Павильон' },
];
