import type { ColumnsType } from 'antd/es/table';

import { DetailedCategory, Size } from './types';

export const initState = { code: 'size' };

export const dropdownStyle = {
  maxHeight: 400,
  overflow: 'auto',
};

export const columns: ColumnsType<Size> = [
  {
    title: 'Размер',
    dataIndex: 'value',
    key: 'value',
    width: '15%',
  },
  {
    title: 'Категории',
    dataIndex: 'categories',
    key: 'categories',
    render: (categories: DetailedCategory[]) => categories.map(item => item.name).join(', '),
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
];
