import { Button, Card, Form, Input, Spin } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { Title } from 'src/shared/ui';
import { $setting, getSettingsFx, initParserTimePage, submitChanges } from './model';

import styles from './ParserTime.module.less';

export const ParserTime = () => {
  const settingValue = useStore($setting);
  const isLoading = useStore(getSettingsFx.pending);

  useEffect(() => {
    initParserTimePage();
  }, []);

  const submitForm = (values: { mins: string }) => {
    submitChanges(values.mins);
  };

  return (
    <Card className={styles.wrapper}>
      <Title>Настройки парсера</Title>
      {isLoading ? (
        <div className={styles.center}>
          <Spin size="large" />
        </div>
      ) : (
        <Form
          className={styles.form}
          name="basic"
          id="commissionForm"
          layout="vertical"
          onFinish={submitForm}
          autoComplete="off">
          <Form.Item
            label="Периодичность проверки новых постов (в минутах)"
            name="mins"
            initialValue={settingValue}
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите значение!',
              },
            ]}>
            <Input type="number" min={5} />
          </Form.Item>
          <Form.Item className={styles.button}>
            <Button htmlType="submit" type="primary">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};
