import { ChatListRequestData } from 'shared/api/queries/chatsApi';

export const PAGE_SIZE = 10;

export const initChatRequestState: ChatListRequestData = {
  type: 'task',
  pagination: {
    per_page: PAGE_SIZE,
    page: 1,
  },
};
