import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Card, Dropdown, Table, Spin, Empty } from 'antd';
import { MainButton, MainModal } from 'src/shared/ui';
import {
  $documents,
  $editingDocument,
  $isLoading,
  $treeData,
  clearEditingDoc,
  initDocumentsSettings,
  openDocument,
  submitCreationDocument,
  submitEditDocument,
} from './model';
import { columns, MenuTableEnum } from './config';
import { MoreOutlined } from '@ant-design/icons';
import { Document, DocumentForm } from './types';
import { ExtendedRows } from './extendedRows';
import { DocumentModal } from './ui';

import styles from './Documents.module.less';

export const Documents = () => {
  const [isOpen, setIsOpen] = useState(false);
  const documents = useStore($documents);
  const editingDoc = useStore($editingDocument);
  const treeData = useStore($treeData);
  const isLoading = useStore($isLoading);

  const expandedRowRender = (record: Document) => (
    <ExtendedRows key={record.id} record={record} setDocumentForEdit={setDocumentForEdit} />
  );

  useEffect(() => {
    initDocumentsSettings();
  }, []);

  const addDocument = () => {
    clearEditingDoc();
    setIsOpen(true);
  };

  const setDocumentForEdit = (data: number) => {
    openDocument(data);
    setIsOpen(true);
  };

  const docHandler = (data: DocumentForm) => {
    if (editingDoc) {
      submitEditDocument({ ...data, id: editingDoc.id });
    } else {
      submitCreationDocument(data);
    }
    setIsOpen(false);
  };

  const closeHandler = () => setIsOpen(false);

  const settingsColumn = [
    {
      title: '',
      key: 'settings',
      render: (data: Document) => (
        <Dropdown
          overlayClassName={styles.menu}
          placement="bottom"
          menu={{
            items: [
              {
                key: MenuTableEnum.EDIT,
                label: 'Изменить',
                onClick: () => setDocumentForEdit(data.id),
              },
            ],
          }}>
          <MoreOutlined />
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Card>
        <div className={styles.button}>
          <MainButton type="primary" onClick={addDocument}>
            Добавить
          </MainButton>
        </div>
        <Table
          rowKey={record => record.id}
          columns={[...columns, ...settingsColumn]}
          dataSource={documents}
          expandable={{
            expandedRowRender,
            rowExpandable: (data: Document) => data.has_children,
          }}
          bordered
          pagination={false}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      </Card>

      <MainModal isOpen={isOpen} closeHandler={closeHandler}>
        <DocumentModal treeData={treeData} closeHandler={closeHandler} submitHandler={docHandler} />
      </MainModal>
    </div>
  );
};
