import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import {
  $legalEntity,
  $validationErrors,
  initEditLegalEntity,
  resetLegalEntity,
  submitCreateForm,
  submitEditForm,
  submitRemoveErrorField,
  resetValidationErrors,
  getLegalEntityFx,
  createLegalEntityFx,
  editLegalEntityFx,
} from './model';
import { v4 as uuid } from 'uuid';
import { Button, Card, Form, Input, Tabs, Spin, Select } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { LegalTooltip } from './ui/legalTooltip';
import { BackArrow } from 'src/entities/backArrow';
import { getNumbers } from 'src/shared/lib';
import { Address, AddressBlock } from './ui';
import { ConfirmForm } from 'src/shared/ui';
import { PhoneInput } from 'src/entities/phoneInput';
import {
  legalEntityFormRules,
  legalEntityFormTooltips,
  smzSelectOptions,
  ruleOnlyNumbers,
  legalEntityFieldLength,
  defaultValidateStatus,
} from './config';
import dayjs from 'dayjs';
import { DatePicker } from 'src/shared/ui';
import locale from 'antd/es/date-picker/locale/ru_RU';
import styles from './LegalEntityCreation.module.less';

interface FormSuccess {
  account: string;
  bank_name: string;
  bik: string;
  billing_descriptor: string;
  birth_date: string;
  country: string;
  details: string;
  email: string;
  first_name: string;
  full_name: string;
  inn: string;
  kpp: string;
  last_name: string;
  middle_name: string;
  name: string;
  phone: string;
  site_url: string;
  tax: string;
  ogrn: string;
  smz: boolean;
}

const initAddress = { key: uuid(), type: null, zip: '', country: 'RUS', city: '', street: '' };

export const LegalEntityCreation = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const legalEntity = useStore($legalEntity);
  const validationErrors = useStore($validationErrors);
  const isLegalEntityLoading = useStore(getLegalEntityFx.pending);
  const isCreateLoading = useStore(createLegalEntityFx.pending);
  const isEditLoading = useStore(editLegalEntityFx.pending);
  const [tabItemsValues, setTabItemsValues] = useState<Address[]>([]);
  const [isSmzChecked, setIsSmzChecked] = useState(false);
  const [ogrnValue, setOgrnValue] = useState('');
  const [activeAddress, setActiveAddress] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    resetLegalEntity();
    if (id) {
      initEditLegalEntity(id);
    }
  }, [id]);

  useEffect(() => {
    const newAddressValues = id
      ? legalEntity?.legalEntity.addresses?.map(address => ({ ...address, key: uuid() })) || []
      : [...tabItemsValues, initAddress];

    setTabItemsValues(newAddressValues);
    setIsSmzChecked(legalEntity?.legalEntity.smz || false);
    setOgrnValue(legalEntity?.legalEntity.ogrn || '');

    const fieldValues = {
      account: legalEntity?.legalEntity.bank_account?.account,
      bank_name: legalEntity?.legalEntity.bank_account?.bank_name,
      bik: legalEntity?.legalEntity.bank_account?.bik,
      billing_descriptor: legalEntity?.legalEntity.billing_descriptor,
      birth_date: legalEntity?.legalEntity.ceo?.birth_date ? dayjs(legalEntity?.legalEntity.ceo.birth_date.toString()) : '',
      country: legalEntity?.legalEntity.ceo?.country || 'RUS',
      details: legalEntity?.legalEntity.bank_account?.details,
      email: legalEntity?.legalEntity.email,
      first_name: legalEntity?.legalEntity.ceo?.first_name,
      full_name: legalEntity?.legalEntity.full_name,
      inn: legalEntity?.legalEntity.inn,
      kpp: legalEntity?.legalEntity.kpp,
      last_name: legalEntity?.legalEntity.ceo?.last_name,
      middle_name: legalEntity?.legalEntity.ceo?.middle_name,
      name: legalEntity?.legalEntity.name,
      phone: legalEntity?.legalEntity.ceo?.phone,
      site_url: legalEntity?.legalEntity.site_url || 'https://',
      tax: legalEntity?.legalEntity.bank_account?.tax || 0,
      ogrn: legalEntity?.legalEntity.ogrn,
      smz: legalEntity?.legalEntity.smz,
    };
    form.setFieldsValue(fieldValues);
  }, [form, legalEntity]);

  const formSubmitHandler = async (values: FormSuccess) => {
    const reqData = {
      legal_entity: {
        billing_descriptor: values.billing_descriptor,
        full_name: values.full_name,
        name: values.name,
        inn: values.inn,
        kpp: values.kpp,
        ogrn: !values.smz ? values.ogrn : null,
        smz: values.smz || false,
        email: values.email,
        site_url: values.site_url,
        ceo: {
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          birth_date: new Date(values.birth_date).toISOString(),
          phone: getNumbers(values.phone),
          country: values.country,
        },
        bank_account: {
          account: values.account,
          bank_name: values.bank_name,
          bik: values.bik,
          details: values.details,
          tax: Number(values.tax),
        },
      },
      addresses: tabItemsValues,
    };

    if (id) {
      submitEditForm({ id, data: reqData });
    } else {
      submitCreateForm(reqData);
    }
    resetValidationErrors();
  };

  const handleChangeValues = (index: number, value: string, name: string) => {
    const newValues = tabItemsValues.map((val, i) => (i === index ? { ...val, [name]: value } : val));
    setTabItemsValues(newValues);
  };

  const addItem = () => {
    if (tabItemsValues.length < 4) {
      const newItem = { ...initAddress, key: uuid() };
      setTabItemsValues([...tabItemsValues, newItem]);
    }
  };

  const handleAddress = (value: string) => {
    const activeIndex = tabItemsValues.findIndex(({ key }) => key === value);
    setActiveAddress(activeIndex);
  };

  const handleRemoveAddress = () => {
    const filteredTabValues = tabItemsValues.filter((_, index) => index !== activeAddress);
    setTabItemsValues(filteredTabValues);
    setIsModalOpen(false);
  };

  const handleSelectSmz = (value: boolean) => {
    setIsSmzChecked(value);
    form.setFields([
      {
        name: 'ogrn',
        errors: value ? [''] : ['Пожалуйста, введите ОГРН'],
      },
      {
        name: 'smz',
        errors: [''],
      },
    ]);
  };

  const handleOgrn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOgrnValue(e.target.value);
    form.setFields([
      {
        name: 'ogrn',
        errors: e.target.value ? [''] : ['Пожалуйста, введите ОГРН'],
      },
      {
        name: 'smz',
        errors: [''],
      },
    ]);
  };

  const handleModal = (value: boolean) => setIsModalOpen(value);

  const handleChangeForm = (e: React.ChangeEvent<HTMLFormElement>) => submitRemoveErrorField(e.target.id);

  if (isLegalEntityLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <section className={styles.legalCreation}>
      <BackArrow />
      <Form form={form} layout="horizontal" onFinish={formSubmitHandler} autoComplete="off" onChange={handleChangeForm}>
        <div className={styles.headBlock}>
          <p className={styles.blockTitle}>Заполните все поля</p>
          <Button
            type="primary"
            htmlType="submit"
            loading={id ? isEditLoading : isCreateLoading}
            disabled={id ? isEditLoading : isCreateLoading}>
            Сохранить
          </Button>
        </div>
        <div className={styles.row}>
          <Card className={styles.card}>
            <p className={styles.blockTitle}>Организация</p>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.fullName}
                label="Полное название"
                name="full_name"
                validateStatus={validationErrors?.['legal_entity.full_name']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.full_name']?.length &&
                  validationErrors['legal_entity.full_name'].join(', ')
                }>
                <Input.TextArea autoSize />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.fullName} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.name}
                label="Сокращенное название"
                name="name"
                validateStatus={validationErrors?.['legal_entity.name']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.name']?.length && validationErrors['legal_entity.name'].join(', ')
                }>
                <Input />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.name} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.billingDescriptor}
                label="На иностранном"
                name="billing_descriptor"
                validateStatus={
                  validationErrors?.['legal_entity.billing_descriptor']?.length ? 'error' : defaultValidateStatus
                }
                help={
                  validationErrors?.['legal_entity.billing_descriptor']?.length &&
                  validationErrors['legal_entity.billing_descriptor'].join(', ')
                }>
                <Input />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.billingDescriptor} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.inn}
                label="ИНН"
                name="inn"
                validateStatus={validationErrors?.['legal_entity.inn']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.inn']?.length && validationErrors['legal_entity.inn'].join(', ')
                }>
                <Input maxLength={legalEntityFieldLength.inn} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.inn} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.kpp}
                label="КПП"
                name="kpp"
                validateStatus={validationErrors?.['legal_entity.kpp']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.kpp']?.length && validationErrors['legal_entity.kpp'].join(', ')
                }>
                <Input maxLength={legalEntityFieldLength.kpp} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.kpp} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={[
                  {
                    required: !isSmzChecked && !ogrnValue,
                    message: 'Пожалуйста, подтвердите самозанятость',
                  },
                ]}
                label="Самозанятый"
                name="smz"
                validateStatus={validationErrors?.['legal_entity.smz']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.smz']?.length && validationErrors['legal_entity.smz'].join(', ')
                }>
                <Select options={smzSelectOptions} disabled={!!ogrnValue} onChange={handleSelectSmz} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.smz} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={[
                  {
                    required: !isSmzChecked,
                    message: 'Пожалуйста, введите ОГРН',
                  },
                  ruleOnlyNumbers,
                ]}
                label="ОГРН"
                name="ogrn"
                validateStatus={validationErrors?.['legal_entity.ogrn']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.ogrn']?.length && validationErrors['legal_entity.ogrn'].join(', ')
                }>
                <Input onChange={handleOgrn} disabled={isSmzChecked} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.ogrn} />
            </div>
          </Card>
          <Card className={styles.card}>
            <p className={styles.blockTitle}>Руководитель</p>
            <Form.Item
              rules={legalEntityFormRules.firstName}
              label="Имя"
              name="first_name"
              validateStatus={
                validationErrors?.['legal_entity.ceo.first_name']?.length ? 'error' : defaultValidateStatus
              }
              help={
                validationErrors?.['legal_entity.ceo.first_name']?.length &&
                validationErrors['legal_entity.ceo.first_name'].join(', ')
              }>
              <Input />
            </Form.Item>
            <Form.Item
              rules={legalEntityFormRules.lastName}
              label="Фамилия"
              name="last_name"
              validateStatus={
                validationErrors?.['legal_entity.ceo.last_name']?.length ? 'error' : defaultValidateStatus
              }
              help={
                validationErrors?.['legal_entity.ceo.last_name']?.length &&
                validationErrors['legal_entity.ceo.last_name'].join(', ')
              }>
              <Input />
            </Form.Item>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.middleName}
                label="Отчество"
                name="middle_name"
                validateStatus={
                  validationErrors?.['legal_entity.ceo.middle_name']?.length ? 'error' : defaultValidateStatus
                }
                help={
                  validationErrors?.['legal_entity.ceo.middle_name']?.length &&
                  validationErrors['legal_entity.ceo.middle_name'].join(', ')
                }>
                <Input />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.middleName} />
            </div>
            <Form.Item
              rules={legalEntityFormRules.birthDate}
              label="Дата рождения"
              name="birth_date"
              validateStatus={
                validationErrors?.['legal_entity.ceo.birth_date']?.length ? 'error' : defaultValidateStatus
              }
              help={
                validationErrors?.['legal_entity.ceo.birth_date']?.length &&
                validationErrors['legal_entity.ceo.birth_date'].join(', ')
              }>
              <DatePicker format="DD.MM.YYYY" locale={locale} />
            </Form.Item>
            <Form.Item
              rules={legalEntityFormRules.country}
              label="Гражданство"
              name="country"
              validateStatus={validationErrors?.['legal_entity.ceo.country']?.length ? 'error' : defaultValidateStatus}
              help={
                validationErrors?.['legal_entity.ceo.country']?.length &&
                validationErrors['legal_entity.ceo.country'].join(', ')
              }>
              <Input maxLength={legalEntityFieldLength.country} />
            </Form.Item>
          </Card>
          <Card className={styles.card}>
            <p className={styles.blockTitle}>Счет</p>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.account}
                label="Реквизиты счета"
                name="account"
                validateStatus={
                  validationErrors?.['legal_entity.bank_account.account']?.length ? 'error' : defaultValidateStatus
                }
                help={
                  validationErrors?.['legal_entity.bank_account.account']?.length &&
                  validationErrors['legal_entity.bank_account.account'].join(', ')
                }>
                <Input maxLength={legalEntityFieldLength.account} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.account} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.bankName}
                label="Банк"
                name="bank_name"
                validateStatus={
                  validationErrors?.['legal_entity.bank_account.bank_name']?.length ? 'error' : defaultValidateStatus
                }
                help={
                  validationErrors?.['legal_entity.bank_account.bank_name']?.length &&
                  validationErrors['legal_entity.bank_account.bank_name'].join(', ')
                }>
                <Input />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.bankName} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.bik}
                label="БИК"
                name="bik"
                validateStatus={
                  validationErrors?.['legal_entity.bank_account.bik']?.length ? 'error' : defaultValidateStatus
                }
                help={
                  validationErrors?.['legal_entity.bank_account.bik']?.length &&
                  validationErrors['legal_entity.bank_account.bik'].join(', ')
                }>
                <Input maxLength={legalEntityFieldLength.bik} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.bik} />
            </div>
            <Form.Item
              rules={legalEntityFormRules.details}
              label="Назначение платежа"
              name="details"
              validateStatus={
                validationErrors?.['legal_entity.bank_account.details']?.length ? 'error' : defaultValidateStatus
              }
              help={
                validationErrors?.['legal_entity.bank_account.details']?.length &&
                validationErrors['legal_entity.bank_account.details'].join(', ')
              }>
              <Input />
            </Form.Item>
            <Form.Item
              rules={legalEntityFormRules.tax}
              label="Комиссия маркетплейсу"
              name="tax"
              validateStatus={
                validationErrors?.['legal_entity.bank_account.tax']?.length ? 'error' : defaultValidateStatus
              }
              help={
                validationErrors?.['legal_entity.bank_account.tax']?.length &&
                validationErrors['legal_entity.bank_account.tax'].join(', ')
              }>
              <Input type="number" step="any" />
            </Form.Item>
          </Card>
        </div>
        <div className={styles.row}>
          <Card className={styles.card}>
            <p className={styles.blockTitle}>Контакты</p>
            <PhoneInput
              initialValue={id ? legalEntity?.legalEntity.ceo?.phone || '' : ''}
              valStatus={validationErrors?.['legal_entity.ceo.phone']?.length ? 'error' : defaultValidateStatus}
              valHelp={
                validationErrors?.['legal_entity.ceo.phone']?.length
                  ? validationErrors['legal_entity.ceo.phone']
                  : defaultValidateStatus
              }
            />
            <div className={styles.item}>
              <Form.Item
                rules={[...legalEntityFormRules.email, { type: 'email', message: 'Не валидный email' }]}
                label="Email"
                name="email"
                validateStatus={validationErrors?.['legal_entity.email']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.email']?.length && validationErrors['legal_entity.email'].join(', ')
                }>
                <Input maxLength={legalEntityFieldLength.email} />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.email} />
            </div>
            <div className={styles.item}>
              <Form.Item
                rules={legalEntityFormRules.siteUrl}
                label="Веб-сайт"
                name="site_url"
                validateStatus={validationErrors?.['legal_entity.site_url']?.length ? 'error' : defaultValidateStatus}
                help={
                  validationErrors?.['legal_entity.site_url']?.length &&
                  validationErrors['legal_entity.site_url'].join(', ')
                }>
                <Input />
              </Form.Item>
              <LegalTooltip title={legalEntityFormTooltips.siteUrl} />
            </div>
          </Card>
          <Card className={styles.card}>
            <Tabs
              onChange={handleAddress}
              tabBarExtraContent={{
                right: (
                  <>
                    {tabItemsValues?.length > 1 ? (
                      <MinusOutlined className={styles.iconMinus} onClick={() => handleModal(true)} />
                    ) : null}
                    <PlusOutlined className={styles.icon} onClick={addItem} />
                  </>
                ),
                left: <p className={styles.addressTitle}>Адрес</p>,
              }}>
              {tabItemsValues?.map((item, index) => (
                <Tabs.TabPane id={item.key} key={item.key} tab={index + 1}>
                  <AddressBlock
                    values={tabItemsValues[index]}
                    handleChange={handleChangeValues}
                    index={index}
                    form={form}
                    validationErrors={validationErrors}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Card>
        </div>
      </Form>
      <ConfirmForm
        confirmHandler={handleRemoveAddress}
        isOpen={isModalOpen}
        closeHandler={() => handleModal(false)}
        title="Вы уверены, что хотите удалить адрес?"
      />
    </section>
  );
};
