import { Tag } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { getFormattedPhone } from 'src/shared/lib';
import { ESupplierStatus, ESupplierStatusName, Supplier, SuppliersSource } from './types';
import { NameProfilesCard } from './ui';
import { v4 as uuid } from 'uuid';
import { statusColors } from 'src/shared/config';
import { Pavilion } from '../suppliersProfile/ui/suppParser/SuppParser';
import { Space } from 'antd';
import { useSupplierStatuses } from './hooks';
import { useStore } from 'effector-react';
import { $supplierStatuses } from './model';

enum statusesEnum {
  ACTIVE = 'Активен',
  BANNED = 'Неактивный',
}

export const statusOptions = Object.entries(statusesEnum).map(([key, value]) => ({
  label: value,
  value: key,
}));

export enum MenuTableEnum {
  EDIT = 'edit',
}

export const defaultRowSelectValue = undefined;
export const nullLegalId = '0';
export const mainColumnName = 'name';
export const hiddenColumn = 'email';
export const disabledLegalOption = { id: uuid(), key: uuid(), full_name: '...', value: '...', disabled: true };
export const emptyLegalOption = {
  id: uuid(),
  key: uuid(),
  full_name: 'Ничего не найдено',
  value: 'Ничего не найдено',
  disabled: true,
};
export const nullLegalOption = { id: nullLegalId, full_name: 'Без юрлица' };

export const renderSuppStatus = (status: ESupplierStatus) => {
  switch (status) {
    case ESupplierStatus.ACTIVE:
      return <Tag style={statusColors.blue}>
        {ESupplierStatusName[status]}
      </Tag>;
    case ESupplierStatus.BANNED:
    case ESupplierStatus.NOT_CONFIRMED:
      return <Tag style={statusColors.red}>
        {ESupplierStatusName[status]}
      </Tag>;
    case ESupplierStatus.AWAITING_CONFIRMATION:
    case ESupplierStatus.UNDER_CONSIDERATION:
      return <Tag style={statusColors.gray}>
        {ESupplierStatusName[status]}
      </Tag>;
    default:
      return '-';
  }
};

export const columns: ColumnsType<Supplier> = [
  {
    title: 'Название',
    key: 'name',
    render: (_, record) => (
      <Link to={`/profile/supplier/${record.id}`} state={{ prevPath: true }}>
        <NameProfilesCard data={record} />
      </Link>
    ),
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
    render: data => data || '-',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: data => getFormattedPhone(data) || '-',
    width: '15%',
  },
  {
    title: 'Юридическое лицо',
    dataIndex: 'legalEntity',
    key: 'legalEntity',
    render: data => {
      if (data?.id) {
        const path = generatePath('/profile/:entity/:id', {
          id: data.id,
          entity: 'legalEntity',
        });

        return (
          <Link to={path} state={{ prevPath: true }}>
            {data?.full_name}
          </Link>
        );
      }
      return '-';
    },
    width: '15%',
  },
  {
    title: 'Источники',
    dataIndex: 'sources',
    key: 'sources',
    render: (data: SuppliersSource[]) => {
      if (!data || !data.length) {
        return '-';
      }

      const sources = data.map((source, index) => {
        const sourceTitle = source?.name || source?.url || '-';
        if (source?.url) {
          return (
            <Space key={source.id}>
              <a href={source.url} target="_blank" rel="noreferrer">
                {data.length - 1 !== index ? `${sourceTitle},` : sourceTitle}
              </a>{' '}
            </Space>
          );
        }
        return <Space key={source.id}>{data.length - 1 !== index ? `${sourceTitle},` : sourceTitle} </Space>;
      });
      return sources;
    },
    width: '20%',
  },
  {
    title: 'Павильоны',
    dataIndex: 'pavilions',
    key: 'pavilions',
    render: (data: Pavilion[]) => {
      if (!data || !data.length) {
        return '-';
      }
      const pavailonsNames = data.map(({ name }) => name);
      return pavailonsNames.join(', ');
    },
    width: '20%',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: status => (status ? renderSuppStatus(status) : '-'),
  },
  // TODO: добавить в следующей итерации
  // {
  //   title: '',
  //   key: 'settings',
  //   render: () => (
  //     <Dropdown overlay={menu}>
  //       <a href="#0">
  //         <MoreOutlined />
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key && curr.key !== hiddenColumn) {
    prev[curr.key] = true;
  } else if (curr.key) {
    prev[curr.key] = false;
  }
  return prev;
}, {} as Record<string, boolean>);
