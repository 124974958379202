export const userHistoryOptions = {
  CLIENT: 'Клиент',
  USER: 'Админ',
  INTERMEDIARY: 'Посредник',
  SUPPLIER: 'Поставщик',
  SELLER: 'Продавец',
};

export const mainShowStatuses = [4, 5, 12, 15];

export const initReqData = '0';

export const intermedCard = 'intermediary';
