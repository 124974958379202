import api from '../api';

export interface AddBuildingReqData {
  name: string;
}

export interface UpdateBuildingReqData {
  id: number;
  data: { name: string };
}

export const getBuildings = () => api.get('/buildings').then(response => response.data);

export const addBuilding = (data: AddBuildingReqData) => api.post('/building', data).then(response => response.data);

export const updateBuilding = (data: UpdateBuildingReqData) =>
  api.put(`/building/${data.id}`, data.data).then(response => response.data);

export const removeBuilding = (id: number) => api.delete(`/building/${id}`).then(response => response.data);
