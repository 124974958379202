import { DateType } from 'src/shared/ui/datePicker';

type ProductStatuses = 'active' | 'deactivated' | 'in_review';

export interface Paginated<T, R> {
  items: T[];
  clients: R[];
  pagination: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

export interface ProductCategory {
  id: string;
  name: string;
  breadcrumbs: { id: number; name: string }[];
}

export interface Product {
  categories: ProductCategory[];
  complaint: {
    comment: string;
    client_id: number;
  };
  created_at: DateType;
  description: string;
  id: string;
  images: string[];
  name: string;
  price: number;
  status: ProductStatuses;
  supplier: {
    supplier: {
      id: number;
      name: string;
      phone: string;
    };
    pavilion: {
      name: string;
    };
  };
  clientId?: number | string;
  clientName?: string;
}

enum ComplaintType {
  incorrect_product_description = 'incorrect_product_description',
  photo_name_or_description_does_not_match = 'service_comission',
  product_contains_inappropriate_content = 'frequency_of_checking_new_posts',
}

export interface ProductComplaint {
  items: [
    {
      id: number;
      type: ComplaintType;
      comment: string;
      created_at: DateType;
      client_id: number;
    },
  ];
  clients: Clients[];
  pagination: {
    per_page: number;
    current_page: number;
    total: number;
    last_page: number;
  };
}

export interface Clients {
  id: number;
  name: string;
}
