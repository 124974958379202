import type { ColumnsType } from 'antd/es/table';
import { Synonym } from './types';

export const synonymInitState = { id: 0, word: '', synonym: '' };

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export enum MenuTableEnum {
  EDIT = 'edit',
  REMOVE = 'remove',
}

export const menuItems = [
  { key: MenuTableEnum.EDIT, label: 'Изменить' },
  { key: MenuTableEnum.REMOVE, label: 'Удалить' },
];

export const columns: ColumnsType<Synonym> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '3%',
  },
  {
    title: 'Слово',
    dataIndex: 'word',
    key: 'word',
  },
  {
    title: 'Синоним',
    dataIndex: 'synonym',
    key: 'synonym',
  },
];
