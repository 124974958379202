import { createEffect, createEvent, createStore, sample } from 'effector';
import { Product, Paginated, Clients } from 'src/pages/products';
import { Storage } from 'src/shared/lib';
import { productsApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { initState } from './ui/forModeration/config';

const perPage = 10;

export const $moderationProducts = createStore<Paginated<Product, Clients> | null>(null);
export const $requestData = createStore<productsApi.ProductsListRequestData>(initState);
export const $pageSize = createStore<number>(perPage);
Storage.persist.entity($pageSize, { slice: 'products', key: 'pageSize' });

export const submitChangeComplaintData = createEvent<productsApi.ProductsListRequestData>();
export const submitActivateData = createEvent<productsApi.ActivityProductReqData>();
export const changePageSize = createEvent<number>();

export const getProductsFx = createEffect(productsApi.getProductsList);
export const getComplaintProductsFx = createEffect(productsApi.getComplaintsProductsList);
export const activateProductFx = createEffect(productsApi.activateProduct);

$requestData.on(submitChangeComplaintData, (_, data) => data);
$moderationProducts.on(getComplaintProductsFx.doneData, (_, data: Paginated<Product, Clients>) => {
  const resultData: Product[] = data?.items?.length
    ? data.items.map(item => {
        const clientData = data?.clients?.[item?.complaint?.client_id];
        return { ...item, clientId: clientData?.id || '', clientName: clientData?.name || '' };
      })
    : [];

  return { ...data, items: resultData };
});

sample({
  source: $requestData,
  target: getProductsFx,
});

sample({
  clock: submitChangeComplaintData,
  target: getComplaintProductsFx,
});

sample({
  clock: submitActivateData,
  target: activateProductFx,
});

sample({
  clock: activateProductFx.doneData,
  source: $requestData,
  target: getComplaintProductsFx,
});

sample({
  clock: activateProductFx.failData,
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: activateProductFx.doneData,
  fn: () => 'Товар успешно активирован',
  target: setSuccess,
});

// change page size

sample({
  clock: changePageSize,
  target: $pageSize,
});
